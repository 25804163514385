<template>
  <div>
    <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary>
    <template v-slot:body>
      <v-form ref="form">
        <custom-text-field
          v-model="dropList.lecture_ar"
          icon="subtitles-outline"
          :rules="[$required, $max_length(100)]"
          :label="$t('globals.lectur_name')"       
        />
          <custom-text-field
          v-model="dropList.lecture_en"
          icon="alpha-e"
          :label="$t('globals.lectur_name_en')"
          :rules="[$en_letters_only, $max_length(100)]"
        
        />
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click.stop="resetForm(), (drawer = false)" />
    </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :perm="{}"
    exports
    :create="() => drawer =true"
  />
  </div>
</template>
<script>
export default {
  props: {    
    fk_subject_lecture_4b: Number,
  },
  created() {
   },
  data() {
    return {
      items: [],      
      pagination: undefined,
      dropList: {},
      updated_id: undefined,
      drawer: false,
    
      headers: [
        { title: this.$t('globals.lectur_name'), key: 'lecture_ar' },
        { title: this.$t('globals.lectur_name_en'), key: 'lecture_en' },
        
        { title: this.$t('globals.actions'), key: 'actions', sortable: false },
      ],
    }
  },
  methods: { 
    resetForm() {
      this.$refs.form.reset()
      this.dropList = {}
      this.update_id = undefined
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('academic-affairs/lecture-name/filter-paginate/', {
        params: {
          field:'fk_subject_lecture_4b',
          value: this.fk_subject_lecture_4b,
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
        },
      })
        .then((response) => {
          this.items = response.data.results
          console.log(this.items);
          this.pagination = response.data.pagination
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async editData(data) {
      this.resetForm()
      this.update_id = data.id
      this.dropList = { ...data }
      this.drawer = true

    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('academic-affairs/lecture-name/' + delete_id + '/')
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid)        
        await this.$axios
          .post('academic-affairs/lecture-name/', {
            ...this.dropList,
            fk_subject_lecture_4b:this.fk_subject_lecture_4b
            })
          .then(() => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.getData()
            this.drawer = false
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })      
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid)       
        await this.$axios
          .put(`academic-affairs/lecture-name/${this.dropList.id}/`, this.dropList)
          .then(() => {
            this.$emit('alert', 'update')
            this.resetForm()
            this.drawer = false
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })      
    },
   
  },
}
</script>
