<template>

  <custom-auto-complete
    clearable
    :items="items"
    item-title="name_ar"
    item-value="id"
    :label="$t('semster_subject.grading_system')"
    icon="chart-line"
    :rules="rules"
    density="compact"
     />
</template>
<script>
// import {getSubjects} from '@/plugins/methods/global-methods'

export default {
  props: {
    rules: {
      type: Object,
    },
  },
  name: 'SubjectTypeChoice',
  data() {
    return {
      items: [],
    }
  },
  async created() {

  await this.$axios("system-management/grading-system-data/",{
}).then((e)=>   this.items = e.data.results);
}
}
</script>