<template>
  <custom-auto-complete
     :items="typeofgrade"
    item-title="name_ar"
    item-value="id"
    :label="$t('TypesOfDegrees.TypeOfDegree')"
    icon="counter"
    col="3"
     />
</template>
<script>
export default {
  data() {
    return {
      typeofgrade: [],
    }
  },
  created() {
      this.$axios('system-management/type-of-grade-data/').then((e)=>{
        this.typeofgrade =e.data.results;
    })
  },
}
</script>