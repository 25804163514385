<template>
  <span>
    <v-icon  :color="color">{{ icon }}</v-icon>
  </span>
</template>
<script>
export default {
  name: 'BtnClear',
  created() {
    if (this.status) {
      this.icon = 'mdi-check-circle'
      this.color = 'success';
      
    } else {
      this.icon = 'mdi-close-circle'
      this.color = 'error'
    }
  },
  data() {
    return {
      icon: {
        type: String,
      },
      color: {
        type: String,
      },
    }
  },

  props: {
    status: Boolean,
  },
  methods: {
    onClick() {
      this.click()
    },
  },
}
</script>
