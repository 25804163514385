<template>
  <custom-auto-complete
    :items="items"
    item-title="name_ar"
    item-value="id"
   :label="$t('globals.grading_system')"
    icon="numeric"
    :col="col"
    :rules="rules"
     />
</template>
<script>
import {  getGradingSystem } from '@/plugins/methods/global-methods'

export default {
  props:{
    col:{
      type:Number,
      default(){
        return 3;
      }
    },
    rules:{
      type:Object,
       
    },
 
  },
  data() {
    return {
      items: [],
 
    }
  },
  async created() {     
      this.items =await getGradingSystem() 
  },
 
}
</script>