import axios from 'axios';
export default {
  namespaced: true,
  state: {
    groups: [],
  },
  getters: {
    getGroup(state, subjects) {
      state.subjects = subjects;
    },
  },
  /* eslint-disable no-unused-vars */
  actions: {
    async getGroup({ state, commit }) {
      let groups = (
        await axios.get('api/groups/', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
      ).data;
      return commit('getGroup', groups);
    },
  },
  mutations: {},
};
