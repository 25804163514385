<template>
  <custom-auto-complete
    :items="buildings"
    item-title="name_ar"
    item-value="id"
    :label="$t('globals.buildings')"
    icon="office-building"
    col="3"
     />
</template>
<script>
export default {
  data() {
    return {
      buildings: [],
    }
  },
  created() {
      this.$axios('system-management/building-data/all/').then((e)=>{
        this.buildings =e.data;
    })
  },
 

}
</script>