<template>
  <custom-card color="cardcolor" cardstyle="mt-2">
    <template v-slot>
      <v-form ref="form">
        <v-tabs v-model="tab">
          <v-tab value="one">{{ $t("user_management.user_data") }} </v-tab>
        </v-tabs>
        <v-window v-model="tab" class="mt-3">
          <v-window-item value="one">
            <v-card>
              <v-row>
                <v-col cols="12" md="10">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        hide-details="auto"
                        v-model="user_data.first_name"
                        prepend-inner-icon="mdi-account"
                        :label="$t('user.fristname')"
                        density="compact"
                        :rules="[
                          $required,
                          $max_length(30),
                          $min_length(3),
                          $validateArabicText,
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        hide-details="auto"
                        v-model="user_data.last_name"
                        prepend-inner-icon="mdi-account"
                        :label="$t('user.secondname')"
                        density="compact"
                        :rules="[
                          $required,
                          $max_length(30),
                          $min_length(3),
                          $ar_letters_only,
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        hide-details="auto"
                        v-model="user_data.username"
                        prepend-inner-icon="mdi-account"
                        :label="$t('user.username')"
                        density="compact"
                        :rules="[
                          $required,
                          $max_length(40),
                          $min_length(2)
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" v-if="!is_update">
                      <v-text-field
                        hide-details="auto"
                        v-model="user_data.password"
                        prepend-inner-icon="mdi-lock"
                        :label="$t('user.password')"
                        density="compact"
                        type="password"
                        :rules="[
                          is_update ? null : $required,
                          $max_length(16),
                          $min_length(8),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" v-if="!is_update">
                      <v-text-field
                        hide-details="auto"
                        v-model="user_data.confirm_password"
                        prepend-inner-icon="mdi-lock"
                        :label="$t('user.confirm_password')"
                        density="compact"
                        type="password"
                        :rules="[
                          match,
                          is_update ? null : $required,
                          $max_length(16),
                          $min_length(8),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        v-model="user_data.system_type"
                        :items="listSystems"
                        item-title="name"
                        item-value="id"
                        hide-details="auto"
                        clearable
                        :label="$t('user.system_type')"
                        density="compact"
                        prepend-inner-icon="mdi-list-status"
                        multiple
                        :rules="[$required]"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        multiple
                        v-model="user_data.groups"
                        :items="listGroups"
                        item-title="name"
                        item-value="pk"
                        hide-details="auto"
                        clearable
                        :label="$t('user.group')"
                        density="compact"
                        prepend-inner-icon="mdi-account-group"
                        :rules="[$required]"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-checkbox
                        v-model="user_data.is_active"
                        color="primary"
                        :label="$t('user.active')"
                        density="compact"
                        hide-details
                      ></v-checkbox
                    ></v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-row> </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row> </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-window-item>
        </v-window>

        <v-row justify="start" class="mb-4 px-3">
          <custom-btn
            :type="is_update ? 'update' : 'save'"
            class="ml-2"
            @click="is_update ? updateUser() : saveUser()"
          ></custom-btn>
          <custom-btn
            color="white"
            text_color="black"
            icon_color="black"
            type="cancel"
            @click="resetForm"
          />
        </v-row>
      </v-form>
    </template>
  </custom-card>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      is_update: false,
      tab: null,
      user_data: {},
      listSystems: [],
      listGroups: [],
      match: (value) =>
        value == this.user_data.password ||
        this.$t("globals.confirm_password_match_error"),
    };
  },
  async created() {
    if (this.id) {
      this.is_update = true;
      await this.getUserDataById(this.id);
    }
    await this.$axios("choices/choice-data/", {
      params: {
        param: "SystemTypeChoice",
      },
    })
      .then((response) => {
        this.listSystems = response.data;
      })
      .catch(() => {});
    await this.$axios("users/groups/")
      .then((response) => {
        this.listGroups = response.data;
      })
      .catch(() => {});
  },
  methods: {
    async getUserDataById(id) {
      await this.$axios(`users/userdata/${id}/`)
        .then((response) => {
          this.user_data = response.data;
          if (response.data.system_type)
            this.user_data.system_type = response.data.system_type.map((number) =>
              parseInt(number)
            );
        })
        .catch(() => {
          this.$emit("warningAlert", this.$t("alert.failure.not_found"));
        });
    },
    resetForm() {
      this.is_update = false;
      this.user_data.is_active = false;
      this.$refs.form.reset();
      this.user_data = {};
      if (this.id != undefined) this.$router.push({ name: "UsersManageList" });
    },
    async saveUser() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.$axios
          .post(`users/userdata/`, this.user_data)
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
          })
          .catch((error) => {
            try {
              if (error.response.data.email != undefined)
                this.$emit("errorAlert", this.$t("globals.user_already_exists"));
              else if (error.response.data.username != undefined)
                this.$emit("errorAlert", this.$t("user.username_unique"));
              else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } catch {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
      }
    },
    async updateUser() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.id != undefined) {
        await this.$axios
          .put(`/users/userdata/${this.id}/`, this.user_data)
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            setTimeout(() => {
              this.$router.push({ name: "UsersManageList" });
            }, 1500);
          })
          .catch((error) => {
            try {
              if (error.response.data.email != undefined)
                this.$emit("errorAlert", this.$t("globals.user_already_exists"));
              else if (error.response.data.username != undefined)
                this.$emit("errorAlert", this.$t("user.username_unique"));
              else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } catch {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.costombtn {
  background-color: #2196f3;
  color: #fff;
}
.img {
  object-fit: cover !important;
}
</style>
