<template>
  <v-col cols="12" :md="col" :sm="col" >
    <v-file-input
      v-model="value"
      :label="label"
      show-size="true"
      :readonly="readonly"
      :disabled="disabled"
      :prepend-inner-icon="'mdi-' + icon"
      prepend-icon=""
      :rules="rules"
      density="compact"
      variant="solo"
      :hint="hint"
      persistent-hint
      hide-details="auto"
    >
      <!-- accept=".pdf, .doc, .docx" -->
      <template v-if="herf" v-slot:append>
       <a :href="herf" target="_blank">{{ open_title }}</a>
      </template>
    </v-file-input>
  </v-col>
</template>

<script>
export default {
  name: 'CustomAutoComplete',
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'رفع ملف',
    },
    col: {
      type: Number,
      default: 12,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'file-document-outline',
    },
    cclass: {
      type: String,
      default: 'pa-0',
    },
    hint: {
      type: String,
    },
    herf: {
      type: String,
    },
    open_title: {
      type: String,
      default: 'فتح النموذج'
    },
    rules: {
      type: Object,
    },
    hide_details: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    updateDate(newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },
}
</script>
