<template>
  <!-- drawer -->
  <div class="pa-4 text-center">
    <v-dialog width="auto" min-width="350" scrollable>
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn
          v-bind="activatorProps"
          icon="mdi-plus"
          variant="tonal"
          density="compact"
          @click="getSpecializatoinForBatch"
        />
      </template>
      <template v-slot:default="{ isActive }">
        <v-card
          prepend-icon="mdi-book"
          :title="$t('globals.choose_specialties')"
        >
          <v-divider />
          <v-card-text class="px-4" style="height: 350px">
            <v-label text="" class="mb-5" />
            <v-checkbox
              v-for="(item, key) in specializatoin"
              :key="key"
              v-model="item.status"
              :label="item.name_ar"
              density
            />
          </v-card-text>
          <v-card-actions>
            <custom-btn
              type="save"
              :click="
                () =>
                  saveData().then((e) => {
                    if (e == true) isActive.value = false
                  })
              "
            />
            <v-spacer />
            <custom-btn @click="isActive.value = false" />
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
  <v-container>
    <v-row class="mt-3">
      <v-col cols="3" v-for="(item, key) in items" :key="key">
        <v-card color="surface-variant" :title="item.fk_specialization">
          <template v-slot:append>
            <v-divider color="primary" />
            <v-menu location="end">
              <template v-slot:activator="{ props }">
                <v-icon icon="mdi-dots-vertical" v-bind="props"></v-icon>
              </template>

              <v-list density="compact" class="py-0 cursor-auto">
                <v-list-item
                  v-ripple
                  @click="
                    $router.push({
                      name: 'semester-for-specializatoin',
                      params: { id: item.id },
                    })
                  "
                >
                  {{ $t('globals.semester_for_specialization') }}
                </v-list-item>
                <v-list-item
                  v-ripple
                  @click=";(del_id = item.id), (dialog = true)"
                >
                  {{ $t('globals.delete') }}
                  <v-icon color="error"
                    >mdi-trash-can-outline</v-icon
                  ></v-list-item
                >
              </v-list>
            </v-menu>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-container class="text-center mt-10" v-if="items.length == 0">
      <v-label :text="$t('globals.no_data')" centered />
    </v-container>
  </v-container>
  <v-dialog v-model="dialog" scrollable :overlay="false" max-width="350px">
    <v-card>
      <v-card-title class="text-blue">
        {{ $t('globals.confirmation') }}
      </v-card-title>
      <v-card-text class="text-blue-grey-darken-4">
        {{ $t('globals.confirm_delete') }}
      </v-card-text>
      <v-card-actions>
        <v-btn class="font-weight-bold me-2" color="error" @click="delData">
          {{ $t('globals.delete') }}
        </v-btn>
        <v-btn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="dialog = false"
        >
          {{ $t('globals.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="add_dialog" class="">
    <v-card title="اضافة تخصص للدفعة" max-width="500" height="500">
      <v-card-item>
        <drop-list name="specializatoin_4B" />
      </v-card-item>

      <template v-slot:actions>
        <custom-btn type="add" />
        <custom-btn @click="add_dialog = false" />
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  async created() {
    this.id = this.$route.params?.id
    this.fk_college = this.$route.params?.fk_college
    await this.getData()
    // await this.getSpecializatoinForBatch()
  },

  data() {
    return {
      items: [],
      dropList: {},
      dialog: false,
      add_dialog: false,
      del_id: undefined,
      id: Number,
      fk_college: Number,
      specializatoin: [],
    }
  },
  methods: {
    async getData() {
      await this.$axios(
        'academic-affairs/specializatoin-4B/get_specializations/',
        {
          params: {
            batch_pk: this.id,
          },
        }
      )
        .then((response) => {
          this.items = response.data
          this.pagination = response.data.pagination
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async getSpecializatoinForBatch() {
      await this.$axios(
        'system-management/specialization/get_specializations/',
        {
          params: {
            college_pk: this.fk_college,
          },
        }
      )
        .then((response) => {
          this.specializatoin = response.data.map((item) => {
            return {
              id: item.id,
              name_ar: item.name_ar,
              status: this.items.some((d) => d.fk_specialization_id == item.id),
            }
          })
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async saveData() {
      let list = []
      let status
      this.specializatoin
        .filter((e) => e.status == true)
        .map((e) => {
          list.push({ fk_specialization: e.id })
        })
      await this.$axios
        .put('academic-affairs/specializatoin-4B/update_specializations/', {
          batch_pk: this.id,
          specialization_list: [...list],
        })
        .then(() => {
          this.$emit('alert', 'add')
          this.getData()
          status = true
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
          status = false
        })
      return status
    },

    async delData() {
      if (this.del_id != undefined) {
        await this.$axios
          .delete('academic-affairs/specializatoin-4B/' + this.del_id + '/')
          .then(() => {
            this.$emit('alert', 'del')
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
        this.dialog = false
      }
    },
  },
}
</script>
