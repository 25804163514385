<template>
<pre dir="ltr">
 </pre>
 
  <custom-card v-if="showFilter" class="mt-0 pa-0">
    <template v-slot>
      <v-form ref="form">
        <v-row>
          <drop-list
            name="batch_coordination"
            v-model="dropList.fk_differentiation_batch"
            col="3"
            :rules="[$required]"
          />
          <drop-list
            name="colleg"
            v-model="dropList.colleg"
            col="3"
            :rules="[$required]"
          />
          <v-btn
            :text="$t('next')"
            color="indigo"
            class="align-self-center ms-5"
            width="100"
            @click="nextPage"
          />
        </v-row>
      </v-form>
    </template>
  </custom-card>
  <custom-card v-if="!showFilter">
    <template v-slot>
      <v-row>
        <v-tabs   v-model="steps" v-bind="step" stacked >
          <v-tab
            v-for="(item, key) in items"
            :key="key"
            :value="key"
            density
            slider-color="black"
            @click="next(key)"
 

          >
            <v-icon v-if="item.color=='red'" icon="mdi-alert-circle" color="red"/>

            <v-avatar v-else :color="item.color" size="20">
              <h5 >{{ key + 1 }}</h5>
              </v-avatar>


            <h6 class="mt-2">{{ item.name }}</h6>
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-col>
          <v-icon
            icon="mdi-arrow-left-circle"
            @click="showFilter = true"
            class="align-self-center ma-auto"
          />
          <custom-btn
            type="save"
            class="align-self-center me-3"
            :click="saveData"
          />
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-btn text="< السابق" v-show="step > 0" class="ma-5" @click="prev" />
        <v-spacer />
        <v-btn text="التالي >" class="ma-5" @click="next" />
        <custom-btn type="save" @click="saveData" />
      </v-row> -->
      <v-window v-model="step" class="mt-5">
        <v-window-item value="1">
          <v-form ref="form_1">
            <first-window class="h-50" :dropList="dropList" />
          </v-form>
        </v-window-item>
        <v-window-item value="2">
           <v-form ref="form_2">         
            <second-window :dropList="dropList" />
           </v-form>  
        </v-window-item>
        <v-window-item value="3">
          <v-form ref="form_3">
            <third-window :dropList="dropList" />
          </v-form>
        </v-window-item>
        <v-window-item value="4">
          <v-form ref="form_4">
            <fourth-window :dropList="dropList" />
          </v-form>
        </v-window-item>
        <v-window-item value="5">
          <v-form ref="form_5">
            <fifth-window :dropList="dropList" />
          </v-form>
        </v-window-item>
        <v-window-item value="6">
          <v-form ref="form_6">
            <sixth-window :dropList="dropList" />
          </v-form>
        </v-window-item>
        <v-window-item value="7">
          <v-form ref="form_7">
            <seventh-window :dropList="dropList" />
          </v-form>
        </v-window-item>
        <v-window-item value="8">
          <v-form ref="form_8">
            <eight-window :dropList="dropList" />
          </v-form>
        </v-window-item>
      </v-window>
    </template>
  </custom-card>

  <!-- <v-stepper 
v-model="step"
:items="items"
show-actions
alt-labels

>
    
  <v-stepper-window  v-model="step"  >
    <v-stepper-window-item :value="1">555555555555555555555555555</v-stepper-window-item>
   
  </v-stepper-window>
</v-stepper>  -->
</template>
<script>
import FirstWindow from '@/views/StudentsAffairs/RegistrationAndAdmission/NewRegistration/component/FirstWindow.vue'
import SecondWindow from '@/views/StudentsAffairs/RegistrationAndAdmission/NewRegistration/component/SecondWindow.vue'
import ThirdWindow from '@/views/StudentsAffairs/RegistrationAndAdmission/NewRegistration/component/ThirdWindow.vue'
import FourthWindow from '@/views/StudentsAffairs/RegistrationAndAdmission/NewRegistration/component/FourthWindow.vue'
import FifthWindow from '@/views/StudentsAffairs/RegistrationAndAdmission/NewRegistration/component/FifthWindow.vue'
import SixthWindow from '@/views/StudentsAffairs/RegistrationAndAdmission/NewRegistration/component/SixthWindow.vue'
import SeventhWindow from '@/views/StudentsAffairs/RegistrationAndAdmission/NewRegistration/component/SeventhWindow.vue'
import EightWindow from '@/views/StudentsAffairs/RegistrationAndAdmission/NewRegistration/component/EightWindow.vue'
import _ from 'lodash'

export default {
  components: {
    FirstWindow,
    SecondWindow,
    ThirdWindow,
    FourthWindow,
    FifthWindow,
    SixthWindow,
    SeventhWindow,
    EightWindow,
  },
  data() {
    return {
      showFilter: true,
      validList: [
        'form_1',
        'form_2',
        'form_3',
        'form_4',
        'form_5',
        'form_6',
        'form_7',
        'form_8',
      ],
      step: 0,
      steps:0,
      items: [
        { name: this.$t('globals.basic_info'), 
         color: 'primary' },
        {
          name: this.$t('globals.personal_card_data'),
          color: 'primary',
        },
        {
          name: this.$t('globals.current_residence_data'),
          color: 'primary',
        },
        {
          name: this.$t('globals.grading_data'),
          color: 'primary',
        },
        {
          name: this.$t('user_management.user_data'),
          color: 'primary',
        },
        {
          name: this.$t('globals.personal_interview'),
          color: 'primary',
        },
        {
          name: this.$t('globals.qualifcations'),
          color: 'primary',
        },
        {
          name: this.$t('globals.document_verification'),
          color: 'primary',
        },
      ],
      dropList: {
        user: {},
        user_fk_ssn_archive: {},
        user_fk_place_of_brith: {},
        user_fk_address: {},
        academic_qualification: [
          {
            fk_qualification: {},
            fk_document: {},
          },
        ],
        student_data: {},
        fk_guardian: {},
        physical_condition: [],

        fk_entity: {},
        academic_data: {},
        check_document: [],
      },

  // dropList: {
  //       user: {
  //         username: 'اسم المستخدم',
  //         password: '123',
  //         first_name: 'الاسم الاول بيانات الشهادة',
  //         last_name: 'بيانات الشهادة الاسم الاخير',
  //         email: 'samer@gmail.com',
  //         date_of_birth: '2024-05-21',
  //         mobile_no: '7478888888',
  //         phone_no: '7878787897898',
  //         gender: 1,
  //         blood_type: 2,
  //       },
  //       user_fk_ssn_archive: {
  //         document_no: '1212564564',
  //         document_type: 1,
  //         issue_date: '2024-05-21',
  //         expiration_date: '2024-05-21',
  //         issue_place: 'مكان الاصدار بيانات البطاقة',
  //       },
  //       user_fk_place_of_brith: {
  //         street: 'الشارع بيانات البطاقة ',
  //         fk_region: 1,
  //       },
  //       user_fk_address: {
  //         street: 'الشارع بيانات الاقامة',
  //         fk_region: 1,
  //       },
  //       academic_qualification: [
  //         {
  //           fk_qualification: {
  //             fk_documnet_type: 1,
  //             qualification_type: 1,
  //             name_ar: 'الاسم العربي الموؤهل',
  //             educational_level: 1,
  //           },
  //           fk_document: {
  //             document_no: '121231231',
  //             document_type: 1,
  //             issue_date: '2024-05-21',
  //             expiration_date: '2024-05-21',
  //             issue_place: 'مكان الاصدار المؤهلات',
  //           },
  //           college_or_school: 'الحورش',
  //           grade_point_average: '50',
  //           seat_no: '011111111',
  //           the_year: '2024-2025',
  //         },
  //       ],
  //       student_data: {
  //         marital_status: 1,
  //         full_name_ar: 'بيانات البطاقة الشخصية الاسم',
  //         name_en: 'undefined',
  //         childern_count: '99',
  //         full_time_ratio: 5,
  //         dictating: 2,
  //         quran: 4,
  //         is_owned_housing: true,
  //         wife_count: '2',
  //         addicted: [],
  //         job: 'العمل بيانات الاقامة',
  //         job_entity: 'جهه العمل بيانات الاقامة',
  //       },
  //       fk_guardian: {
  //         name: 'من يعول الاسم',
  //         representative: 'صلة القرابة',
  //         phone_no: '777777777',
  //         healthy_condition: 1,
  //         job: 'العمل من يعول الاسرة',
  //         job_entity: 'جهه sssssssss  من يعول الاسرة',
  //         financial_condition: 1,
  //       },
  //       physical_condition: [],
  //       talent: [],
  //       fk_entity: {
  //         entity: 'الجهه المرسلة',
  //         discrption: 'الوصف',
  //       },
  //       academic_data: {
  //         accommodation_type: 1,
  //         reason_4_ask_4_housing: 'سبب اختيار السكن',
  //         discussion_committee: 'لجنة المناقشة',
  //         discussion_committee_proposal: 'مقترحات اللجنة',
  //       },
  //       check_document: [
      
  //       ],
  //       differentiation_no:1,
  //       fk_differentiation_batch:1,
  //       country: 1,
  //       gov: 1,
  //       dir: 1,
  //       country_1: 1,
  //       gov_1: 1,
  //       dir_1: 1,
    
  //     },  

  }
  },
  mounted() {},

  methods: {
    resetForm(){
      this.dropList ={
        user: {},
        user_fk_ssn_archive: {},
        user_fk_place_of_brith: {},
        user_fk_address: {},
        academic_qualification: [
          {
            fk_qualification: {},
            fk_document: {},
          },
        ],
        student_data: {},
        fk_guardian: {},
        physical_condition: [],

        fk_entity: {},
        academic_data: {},
        check_document: [],
      }
      this.showFilter = true
    },
    async nextPage() {
      const { valid } = await this.$refs.form.validate()
      if (valid) this.showFilter = false
    },
    async saveData() {
      

      let data = _.cloneDeep(this.dropList)
      let isValid = true            
      for (let i=0;i<this.validList.length;i++) {        
        try{
          const v = this.validList[i]         
          const { valid } = await this.$refs[v].validate()
          if(valid)
           this.items[i].color = 'green'
          else this.items[i].color = 'red'     
           if(i==7)
            if(this.dropList.check_document.length==0) 
              this.items[index].color = 'red'
              
              
        } catch{
            this.items[i].color = 'red'                                                  
        }                    
      }
      if(this.items.filter((e)=>e.color=='red').length>0)return
      

      if (isValid) {
        if(data.user_fk_ssn_archive.document)
        data.user_fk_ssn_archive.document =
          data.user_fk_ssn_archive.document[0]


   
   if( data.academic_qualification.length>0)
        data.academic_qualification.forEach((val, key) => {
          if( data.academic_qualification[key].fk_document.document)
          data.academic_qualification[key].fk_document.document =
            data.academic_qualification[key].fk_document.document[0]
        })

        if( data.check_document.length>0)
        data.check_document.forEach((val, key) => {
          data.check_document[key].fk_archives.document =
            data.check_document[key].fk_archives.document[0]
        })
        
       
        if( data.student_data.addicted.length>0 )
          data.student_data.addicted = data.student_data.addicted.join(',')
      
      await this.$axios
          .post(
            'student-affairs/registration-student/',
            {
              ...data,
            },
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          .then(() => {
            this.resetForm()
            this.$emit('alert', 'add')
          })
          .catch((e) => {

            if(e?.response?.data?.error.includes('الحقول document_no, document_type يجب أن تشكل مجموعة فريدة'))
              {
                this.dropList.uniqe_doc_no_type=true      
                this.items[1].color = 'red'                  
                this.step=1
                this.steps=1
                
                return

              }      
              this.$emit('alert', 'errorData')                                               
          })

      }
    },
    
     async next(key) {
 
    

      let index = this.step
      // index = this.step > 0 ? (index = this.step - 1) : this.step

      
      const data = this.validList[index]
      const { valid } = await this.$refs[data].validate()
      if (!valid) {
        this.items[index].color = 'red'        
      }
      if (valid)
        if (this.step <= 7) {
         
        

          this.items[index].color = 'green'
          if(this.step==7)
            if(this.dropList.check_document.length==0) 
              this.items[index].color = 'red'
        }
      this.step = key
    },
    prev() {
      if (this.step > 0) this.step--
    },
  },
  
 
}
</script>
