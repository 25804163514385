<template>

  <custom-auto-complete
    clearable
    :items="items"
    item-title="name_ar"
    item-value="id"
    :label="$t('semster_subject.subject')"
    icon="bookshelf"
    :rules="rules"
    density="compact"
     />
</template>
<script>
// import {getSubjects} from '@/plugins/methods/global-methods'

export default {
  props: {
    rules: {
      type: Object,
    },
  },
  name: 'ListSubjects',
  data() {
    return {
      items: [],
    }
  },
  async created() {
      await this.$axios('system-management/subjects/')
            .then((response) => {
            this.items = response.data.results
            console.log(response.data.results);

            this.pagination = response.data.pagination
            })
            .catch(() => {})
        },
  
}
</script>