<template>
  <custom-auto-complete
    :items="activitisclass"
    item-title="name_ar"
    item-value="id"
    :label="$t('list.activityclassname')"
    icon="account"
    col="3"
     />
</template>
<script>
export default {
 
  

  data() {
    return {
      activitisclass: [],
 
    }
  },
  created() {
      this.$axios('activities/activity-classification-data/all/').then((e)=>{
        this.activitisclass =e.data;
    })
  },
 

}
</script>