<template>
  <custom-auto-complete
    :items="items"
    :placeholder="$t('list.semester_placeholder')"
    item-title="name"
    item-value="id"
    :label="$t('list.semester')"
    icon="book"
    :col="col"
    :rules="rules"
  />
</template>
<script>
export default {
  props: {
    col: {
      type: Number,
      default() {
        return 12
      },
    },
    rules: {
      type: Object,
    },
    batch: {
      type: undefined,
      default: 0,
    },
  },
  data() {
    return {
      items: [],
    }
  },

  watch: {
    batch() {
      if (this.batch) {
        this.$axios
          .get('/academic-affairs/semester-4B/filter/', {
            params: {
              field: 'fk_batch',
              value: this.batch,
            },
          })
          .then((e) => {
            this.items = e.data
          })
      }
    },
  },
}
</script>
