<template>
  <v-card class="shadow-card pa-2 rounded-lg">
    <v-row class="text-center">
      <v-col cols="12">
        <h3>{{head_title}}</h3>
      </v-col>
      <v-col cols="12">
        <v-icon :icon="'mdi-'+icon" :size="size" :color="color"></v-icon>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'CustomCardIcon',
  data() {
    return {}
  },
  props: {
    head_title: {
      type: String,
    },
    icon: {
      type: String,
    },
    size: {
      type: String,
    },
    color: {
      type: String,
    },
  },
}
</script>
<style scoped>
.shadow-card{
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
}
</style>
