
import * as method from '@/plugins/methods/global-methods'


export const data =(name,param)=>{

    const list =[
        {
          'year': [
            'globals.academic_year',
            'calendar',
            method.getCollege,
            'name_ar',
            'id',
          ],
        },
        {
          'colleg': [
            'branches.college',
            'school',
            method.getCollege,
            'name_ar',
            'id',
          ],
        },
        {
          'colleg_with_out_regester_batch': [
            'branches.college',
            'school',
            ()=>param?method.getCollegeWithOutRegesterInBatch(param):[],
            'name_ar',
            'id',
          ],
        },
        {
          'colleg_with_out_regester_specializations': [
            'branches.specialization_name',
            'book',
            ()=>param?method.getCollegeWithOutRegesterInBatch(param):[],
            'name_ar',
            'id',
          ],
        },
        ///الفصول الذي بالخطة
        {
          'semester_in_plane': [
            'class.clss_name',
            'chart-pie',
            ()=>[],
            
          ],
        },
        //المراحل
        {
          'level': [
            'class.level',
            'stairs-box',
            method.getLevelChoice,
            
          ],
        },
        // المباني 
        {
          'building': [
            'globals.buildings',
            'office-building',
            ()=>param?method.getBuildings(param):[],
            'name_ar',
            'id'    
          ],
        },

// جلب القاعات حسب المبنى
        {
          'get_halls_4_building': [
            'AddmissionExaSc.halls',
            'town-hall',
            ()=>param?method.getHalls(param):[],
            'hall_no'                    

          ],
        },

// جلب اللجان حسب المبنى
        {
          'get_committees_4_building': [
            'AddmissionExaSc.committees',
            'account',
            ()=>param?method.getCommittees(param):[],
            'hall_no'                    

          ],
        },
        //////  السكن
        {
          'accommodations': [
            'accommodation.accommodations',
            'home',
            ()=>param?method.getAccommodations(param):[],      
          ],
        },
        //////     الاسرة
        {
          'bed': [
            'accommodation.bed_number',
            'bed',
            ()=>param?method.getBeds(param):[],
          ],
        },
        ///////     السرايا
        {
          'accommodation_group': [
            'accommodation.group',
            'account-group',
            method.getAccommodationGroup,
            "name_ar"
          ],
        },
        //////////    خدمات التسكين
        {
          'accommodation_service': [
            'accommodation.service',
            'room-service',
            method.getAccommodationService,
            'student_name'
          ],
        },
        //الاعوام للفصول
        {
          'year_in_semester_batch': [
            'globals.academic_year',
            'calendar',
            ()=>param?method.getYearInPlaneBatch(param):[],
            'year_h'      
          ],
        },
        //الاعوام  للفصول للتخصصات
        {
          'year_in_semester_specialization': [
            'globals.academic_year',
            'calendar',
            ()=>param?method.getYearInPlaneSpecialization(param):[],
            'year_h'      
          ],
        },
        ///نظام الدرجات
        {
          'system_degree': [
            'globals.grading_system',
            'numeric',
            method.getGradingSystem,
            'name_ar'      
          ],
        },
        ////المواد للفصل
        {
          'subject_for_semester': [
            'subject.subject',
            'book',
            ()=>param?method.getSubjectForSemester(param):[],   
                
          ],
        },
         ////المواد 
         {
          'subjects': [
            'subject.subject',
            'book',
            method.getSubjectsall,
            'name_ar' 
          ],
        },
        ///قسم المواد
        {
          'subject_section': [
            'list.subject_section',
            'chart-pie',
            method.getSubjectSection,           
          ],
        },
        //نوع المادة نظري عملي 
        {
          'subject_type': [
            'lectures_subject.lecture_type',
            'chart-pie',
            method.getSubjectType,           
          ],
        },
        //التخصصات للكلية
        {
          'specializatoin_4B': [
            'branches.specialization_name',
            'book',
            method.getSpecializatoin4B,       
            'name_ar'    
          ],
        },
        //// كلية او تخصص        
        {
          batch_or_specializatoin: [
            'globals.data_type',
            'book',
            ()=>[
              {
                id:1,
                name:"تخصص"
              },
              {
                id:2,
                name:"كلية"
              
              }
                      
            ],       
          
          ],
        },   
        //الجهة المرسلة  
        {
          'sending_party': [
            'globals.sending_party',
            'send',
            ()=>[],              
          ],
        }, 
        ///نوع السكن    
        {
          'accommodation_type': [
            'globals.accommodation_type',                           
            'office-building-outline',
            ()=>method.getChoices('AccommodationTypeChoice')               
          ],
        },  
        ///الدولة  
        {
          'country': [
            'globals.country',
            'flag-outline',
            method.getCountry,         
            'name_ar'        
          ],
        },    
        // المحافظة حسب الدولة
        {
          'gov_for_country': [
            'governorate.governoratename',
            'city',
            ()=>param?method.getGovForCountry(param):[],         
            'name_ar'        
          ],
        },   
        //ألمديرية حسب المحافظة 
        {
          'dir_for_gov': [
            'directorate.directoratename',
            'map-outline',
            ()=>param ? method.getDirForGov(param) : [],         
            'name_ar'        
          ],
        },    
          ////الجنسية
        {
          'nationalty': [
            'user.nationality',
            'map-outline',
            method.getCountry,         
            'nationality_name_ar'
          ],
        },
        //// نوع الوحدة في السكنات  
        {
          'unit_type': [
            'accommodation.unit_type',
            'calculator',
            method.getUnitType,         
                
          ],
        },  
        //// حالة الوحدة في السكنات  
        {
          'unit_status': [
            'accommodation.status',
            'list-status',
            method.getUnitStatus,         
          ],
        },  
        //// حالة التسكين  
        {
          'assigning_status': [
            'accommodation.assigning_status',
            'list-status',
            method.getAssigningStatus,         
          ],
        },  
        ///الجنس
        {
          'gendar': [
            'globals.gender',
            'gender-male-female',
           ()=> method.getChoices('GenderChoice')                        
          ],
        },   
        //نوع المستخدم 
        {
          'type_user': [
            'user.user_type',
            'account-circle',
            method.getDirForGov,         
                
          ],
        },
        ///الدفعة  
        {
          'batch': [
            'globals.batch',
            'ballot-outline',
            method.getBatch,      
            'name_ar'
          ],
        },   
        //الفصول حسب الدفع
        {
          'semester_4_batch': [
            'globals.seasons',
            'flag',
            ()=>param?method.getSemesteForBarch(param):[],
          ],
        },   
        //المجموعات حسب الفصول
        {
          'group_4_semester': [
            'ActivitiesGroupsFilter.sumester_group',
            'account-group-outline',
            ()=>param?method.getGroupBySemester(param):[], 
            'fk_grouping'        
          ],
        },  
        //الطلاب حسب المجموعة
        {
          'student_4_group': [
            'StudentData.student_name',
            'account',
            ()=>param?method.getStudent4Group(param):[], 
            'fk_student',
            'fk_student_id'       
          ],
        },  

        // بيانات المستخدمين
        {
          'user_data': [
            'list.users',
            'account',
            method.getUserData,
            'username'        
          ],
        },      
        //ألمجموعة
        {
          'group': [
            'user.group',
            'account-multiple',
            method.getGroup, 
            'name_ar'                        
          ],
        }, 
        //ألمجموعة
        {
          'group_for_semester': [
            'user.group',
            'account-multiple',
            ()=>[],
            'name_ar'                     
          ],
        }, 
        //الفتره
        {
          'period': [
            'control.period',
            'clock',
            method.getPeriod,                         
          ],
        }, 
        //الاملاء
        {
          'dictating': [
            'StudentData.dictating',
            'pencil-outline',
            ()=>method.getChoices('EstimateChoice'),                         
                        
          ],
        }, 
        //القران
        {
          'quran': [
            'StudentData.quran',
            'book-open-page-variant-outline',
            ()=>method.getChoices('EstimateChoice'),                         
          ],
        }, 
        //نسبة التفرغ
        {
          'full_time_ratio': [
            'globals.full_time_ratio',
            'alarm-snooze',
            ()=>method.getChoices('RatioChoice'),                         
                      
          ],
        }, 
        //نسبة التفرغ
        {
          'full_time_ratio': [
            'globals.full_time_ratio',
            'pencil',
            ()=>[],                         
          ],
        }, 

        //ماذا يتعاطى
        {
          'addicted': [
            'globals.addicted',
            'alert-outline',
            ()=>method.getChoices('AddictedChoice'),                         
          ],
        }, 
        //المهارات والمواهب
        {
          'talent': [
            'globals.talent_skills',
            'pencil',
            method.getTalentChoices,   
            'name_ar'                      
          ],
        }, 
        //الموؤهلات
        {
          'qualifcation': [
            'StudentData.qualifcation',
            'certificate-outline',
            ()=> method.getChoices('QualificationTypeChoice')                        

           ],
        }, 

        //حالة التحضير
        {
          'prepareChoice': [
            'StudentData.prepare_status',
            'certificate-outline',
            ()=> method.getPrepareChoice(),                     

           ],
        }, 
        
        //نوع الوثيقة
        {
          'documnet_type': [
            'employee.documnet_type',
            'file-document-alert-outline',
            method.getdocmunet,
            'name_ar'                    

           ],
        }, 
         //نوع الوثيقة بدون بيانات
         {
          'custom_documnet_type': [
            'employee.documnet_type',
            'file-document-alert-outline',
            ()=>[],
            'name_ar'                    

           ],
        }, 
        //القسم
        {
          'department': [
            'employee.department',
            'format-list-bulleted',
            ()=> method.getChoices('SecondaryDepartmentChoice')                                   
           ],
        }, 
        //الحالة الصحية
        {
          'healthy_condition': [
            'globals.father_health_condition',
            'heart-pulse',
            ()=> method.getChoices('HealthyConditionChoice')                                   
           ],
        }, 
        //الحالة المالية
        {
          'financial_condition': [
            'globals.financial_condition',
            'currency-usd',
            ()=> method.getChoices('FinancialConditionChoice')                                   
           ],
        }, 
        //مستوى التعليم
        {
          'EducationalLevelChoice': [
            'globals.educational_level',
            'book-education-outline',
            ()=> method.getChoices('EducationalLevelChoice')                                   
           ],
        }, 
       
        //نوع فصيلة الدم
        {
          'blood_type': [
            'globals.blood_type',
            'blood-bag',
            ()=>method.getChoices('BloodTypeChoice'),                         
          ],
        }, 
        //مادة الفصل للدفعة
        {
          'semester_subject_4b': [
            'globals.subject_lecture_batch',
            'book',
            ()=>[],                         
          ],
        }, 
        //المرفقات
        {
          'attachments': [
            'attatchment.title',
            'attachment',
            method.getAttachment,
            'name_ar'
            ],
        }, 
        //نوع المحاضرة
        {
          'lecture_type': [
            'list.lecture_type',
            'file-document',
            ()=>method.getChoices('SubjectTypeChoice'),                         
          ],
        }, 
        //المنطقة
        {
          'region_by_dir': [
            'buildings.region',
            'map-marker-outline',
            ()=>param?method.getRegionByDir(param):[],
            'name_ar'                       
          ],
        }, 
        //الحالة الاجتماعية
        {
          'marital_status': [
            'globals.marital_status',
            'account-group-outline',
            ()=>method.getChoices('MaritalStatusChoice'),            
          ],
        }, 
        //نوع المرض
        {
          'type_condition': [
            'globals.type_condition',
            'heart-pulse',
            ()=>method.getChoices('ConditionTypeChoice'),            
          ],
        }, 
        ///الدرجة العلمية   
        {
          'degree_choice': [
            'list.degree',                           
            'layers',
            ()=>method.getChoices('DegreeChoice')               
          ],
        }, 
        //نوع العملة
        {
          'currency': [
            'globals.currency',
            'margin',
            method.getCurrency,
            'name_ar'                    

          ],
        },
        ///الدرجة العلمية   
        {
          'degree_choice': [
            'list.degree',                           
            'layers',
            ()=>method.getChoices('DegreeChoice')               
          ],
        }, 
        //نوع العملة
        {
          'currency': [
            'globals.currency',
            'margin',
            method.getCurrency,
            'name_ar'                    

          ],
        },
        {
          'getSpecializationByBranchId': [
            'branches.specialization_name',
            'multicast',
            ()=>param?method.getSpecializationByBranchId(param):[],
            'name_ar',
            'id',
          ],
        },
                // الفروع  
                {
                  'branches': [
                    'branches.branch',
                    'office-building',
                    method.getBranches,
                    'name_ar'
                  ],
                },
                // جلب الكليات بحسب الفرع
                {
                  'collage_for_branch': [
                    'branches.college',
                    'school',
                    ()=>param?method.getCollegesByBranchId(param):[],
                    'college_name_ar',
                  ],
                },
                // جلب الكليات بحسب الفرع
                {
                  'collage_for_branch': [
                    'branches.college',
                    'school',
                    ()=>param?method.getCollegesByBranchId(param):[],
                    'college_name_ar',
                  ],
                },
                // نوع النظام
                {
                  'jop_system': [
                    'employee.jop_system',
                    'account-tie',
                    method.JopSystemChoice,
                  ],
                },
                // حالة الموظف
                {
                  'employee_status': [
                    'employee.status',
                    'account-tie',
                    method.EmployeeStatusChoice,
                  ],
                },
                // نوع البطاقة
                {
                  'document_type': [
                    'employee.document_type',
                    'card-account-details',
                    method.getDocumentType,
                    "name_ar"
                  ],
                },

        ///الية القبول 
        {
          'acceptance_mechanism_choice': [
            'list.acceptance_mechanism',                           
            'desktop-classic',
            ()=>method.getChoices('AcceptanceMechanismChoice')               
          ],
        }, 

//جلب  المستخدمين حسب النوع(موظف - مدرس)
        {
          'get_users_by_ET': [
            'AddmissionExaSc.users',
            'account',
            method.getUsersByTecherEmploot,
            'full_name'                    
          ],
        },
        //عناوين المحاضرات
        {
          'lecture_name': [
            'globals.lectur_name',
            'subtitles-outline',
            ()=>param?method.getLectureName(param):[],
            'lecture_ar'                    

          ],
        },

///دفع التنيسق والقبول  
        {
          'batch_coordination': [
            'globals.batch',
            'flag',    
            method.batchCoordination,      
            'name'
          ],
        },   

//المجموعات حسب دفع التنيسق والقبول
        {
          'group_4_batch_coordination': [
            'test_form.group',
            'flag',
            ()=>param?method.groupBatchCoordination(param):[],
            'name'
          ],
        }, 

        ///الفترات
        {
          'group_period_choice': [
            'list.period',                           
            'ab-testing',
            ()=>method.getChoices('GroupPeriodChoice')               
          ],
        },

        ///اختبارات القبول
        {
          'diff_subject': [
            'batch_accecptance_test.test_subject',                           
            'test-tube',
            ()=>method.getDiffSubject(),
            'name_ar',
            'id'        
          ],
        }, 

        ///اختبارات القبول بحسب الدفعة
        {
          'diff_subject_batch': [
            'batch_accecptance_test.test_subject',                           
            'test-tube',
            ()=>param?method.getDiffSubjectByBatch(param):method.getDiffSubjectByBatch(),       
            'fk_differentiation_subject_name',
            'fk_differentiation_subject'
          ],
        }, 
    //نوع الوثائق للتحقق من الوثائق

        {
          'get_type_docmunetFor_checkDocument': [
            'employee.documnet_type',
            'file-document-alert-outline',
            ()=>param?method.getTypeDocmunetForCheckDocument(param,0):[],
            'documnet_type_name'                    
           ],
        }, 
          ////المواد للدفعة
          {
            'subject_for_batch': [
              'subject.subject',
              'book',
              ()=>param?method.getSubjectForBatch(param):[],
              'name',
              'subject_id'                  
            ],
          },
            /// اليوم  
        {
          'day': [
            'globals.day',                           
            'calendar-today-outline',
            ()=>method.getChoices('DayChoice')
          ]
          },
          {
          'employee_for_subject': [
            'globals.teacher',
            'account',
            ()=>param?method.getTeacherForSubject(param):[],
          ],
        },
           //الفترة حسب المجموعة
           {
            'period_for_groups': [
              'control.period',
              'clock',
              ()=>param?method.getPeriodForGroups(param):[],
              'order_no'
            ],
          }, 
        
          
        //حالة الخدمة
        {
          'service_status': [
            'housing.fk_service.service_status',
            'room-service',
            method.getServiceStatus,
            'name'                    

          ],
        },
         // الطلاب
        {
          'student': [
            'reports.student',
            'account',
            method.getStudentِِAcadmic,
            'fk_student_full_name_ar'                    

          ],
        },
             // التخصصات
        {
          'specialization': [
            'branches.specialization_name',
            'multicast',
            method.getSpecialization,
            'name_ar'                    

          ],
        },
        // الموؤهلات انواع
        {
        'qualification_type': [
          'qualification.qualification_type',
          'school',
          method.getqualificationtype,
          'name'                    

        ],
      },
      // المرحلة الدراسية 
      {
        'education_level': [
          'qualification.education',
          'school',
          method.getEducationalLevel,
          'name'                    

        ],
      },
      // الفصول 
      {
        'semester': [
          'list.semester',
          'chart-pie',
          method.getSemester,
          'name'                    

        ],
      },
      {
        'branch': [
          'branches.branch',
          'chart-pie',
          method.getBranch,
          'name_ar'                    

        ],
      },      
      //تحضير الاسكان
        {
          'accommodation_group': [
            'globals.buildings',
            'office-building',
            ()=>param?method.getAccommadationGroup(param):[],
            'name_ar'
          ],

        },

         ///التصنيفات  
         {
          'get_activiteis_classficion': [
            'permissions.activityclassification',
            'flag',
            method.getActiviteisClassficion,      
            'name_ar'
          ],
        },   
        //انواع الانشطة حسب التصنيفات
        {
          'get_activities_type_4_classficion': [
            'AddmissionExaSc.activity_type',
            'flag',
            ()=>param?method.getActivitiesType4Classficion(param):[],
            'name_ar'
          ],
        },   
        //نوع التحضير
        {
          'prepare_choice': [
            'globals.prepare_choice',                           
            'layers',
            ()=>method.getChoices('PrepareChoice')               
          ],
        }, 
        
         //الفترات حسب الفصل
         {
          'SemesterPeriodChoice': [
            'control.period',
            'clock',
            ()=>method.getChoices('SemesterPeriodChoice')               
          ],
        }, 
        //تقييم اللجنة
        {
          'committee_opinion': [
            'globals.opinion_committe',                           
            'account-group-outline',
            ()=>method.getChoices('CommitteeOpinionChoice')               
          ],
        },  
        
      ]
      
      
      return list.find((item) => name in item)
      
    }
