<template> 
  <v-dialog color="indigo" v-model="drawer" width="600">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row>
            <v-expansion-panels model-value="1" v-model="panel">
              <v-expansion-panel value="1" class="border border-primary">
                <template v-slot:default>
                  <v-expansion-panel-content :ripple="false">
                    <v-expansion-panel-title
                      >{{ $t("semster_subject.lectures_subjects_add") }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <v-form ref="form">


                      <v-row
                      >
                        <v-col cols="12" md="4" >
                          <custom-text-field
                            type="number"
                            :label="$t('lectures_subject.lecture_no')"
                            v-model="LecturesList.lecture_no"
                            icon="numeric"
                            :rules="[$required]"
                            >

                            </custom-text-field>
                            </v-col>
                        <v-col cols="12" md="4" >
                            <list-lecture-type
                              v-model="LecturesList.lecture_type"
                              density="compact"
                              :rules="[$required]"
                              col="12"
                              persistent-hint
                            
                            >
                            </list-lecture-type>
                        </v-col>
                        <v-col cols="12" md="4" >
                          
                          <custom-text-field
                            type="number"
                            :label="$t('lectures_subject.number_of_hours_and_minutes')"
                            v-model="LecturesList.number_of_hours_and_minutes"
                            icon="numeric"
                            :rules="[$required]"
                            >
                            </custom-text-field>
                            </v-col>
                      </v-row>
                                            </v-form>
                    </v-expansion-panel-text>
                  </v-expansion-panel-content>
                </template>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-3">
          <custom-btn v-if="!is_update" type="save"  :click="saveData"  />
            <custom-btn v-if="is_update" type="update"  :click="updateData"  />
          <custom-btn
            :label="$t('globals.cancel')"
            color="white"
            text_color="black"
            icon_color="black"
            type="cancel"
            @click="drawer = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  <custom-data-table-2
        :headers="headers"
        :items="items"
        :pagination="pagination"
        :method="getData"
        :delItem="delData"
        :editItem="editData"
        style="z-index: auto"
        :perm="{}"
        :create="()=>opendailog()"
        export
        :actionList="actionList"
    >
    <template v-slot:item="{ item, key }">
          <span v-if="key == 'number_of_hours_and_minutes'">
            {{ timeCal(item[key]) }}
          </span>
        </template>
    </custom-data-table-2>
</template>
<script>

export default {

   

  props: {
    id: {
      type: Number,
    },
  },
  computed:{
    formatedtime(){
      return(n) =>{
        n = n/60
        const hours =Math.floor(n) ;
        const minutes =Math.round((n - hours)*60);
        return 'الساعات' + '  '+ hours +'  '+ 'الدقائق'+ minutes;
      }
    },
  },
    data() {
        return {
        panel:null,
        items: [],
        filteritem:[],
        pagination: undefined,
        dropList:{ },
        is_update: false,
        updated_id: undefined,
        drawer: null,
        LecturesList:{ },
        headers: [
            { title: this.$t('lectures_subject.lecture_no'), key: 'lecture_no' },
            { title: this.$t('lectures_subject.lecture_type'), key: 'lecture_type',sortable:false },
            { title: this.$t('lectures_subject.number_of_hours_and_minutes'), key: 'number_of_hours_and_minutes',sortable:false },
            { title: this.$t('globals.actions'), key: 'actions' },
        ],
        }
    },

    methods: {
      timeCal(time) {
      const hours = Math.floor(time / 60)
      const minutes = Math.floor(time % 60)    
      let str      
      if(hours == 1) str = 'ساعة'
      else if(hours == 2) str = 'ساعتين'
      else if(hours > 10) str = `${hours} ساعة`
      else str = `${hours} ساعات`
      if(hours>0) str +=' و '
      if(minutes == 1) str +='دقيقة'
      else if(minutes == 2) str +='دقيقتين'
      else if(minutes > 10) str +=`${minutes} دقيقة`
      else str +=`${minutes} دقائق`
      return str
    },
          actionList(data){
      return [
        [
          'LecturesDefnition.lectures_defnition_add',
          ()=>this.addDefnition(data)
        ],
      ]

    },
        addDefnition(data){
                this.update_id = data.id
                this.$router.push(`/AssiginLecturesDefnition/${this.update_id}`);  
        },
        opendailog() {
        this.drawer = !this.drawer
        this.is_update = false
        this.resetForm()
        },
        resetForm() {
            this.LecturesList={}
            this.is_update = false
            this.updated_id = undefined
            },
        async editData(data, isPermission = false) {
          if (data.lecture_type !="") {
            await this.$axios
            .get('/choices/choice-data/', {
              params: {
                param: 'SubjectTypeChoice',
              },
            })
            .then((e) => (
              
              this.filteritem = e.data
              
              ))
            }
            for (let index = 0; index < this.filteritem.length; index++) {
              if ( data.lecture_type == this.filteritem[index].name) {
                this.LecturesList.lecture_type= this.filteritem[index].id
                this.LecturesList.lecture_no= data.lecture_no
                this.LecturesList.number_of_hours_and_minutes=data.number_of_hours_and_minutes
              }
              
            }
        this.update_id = data.id
          if (!isPermission) {
            
            this.drawer =true
            this.is_update = true
            
            } else {
            return true
            }
        },
        async updateData() {
          if (this.panel === undefined || this.panel === null) {
          }else{
        const { valid } = await this.$refs.form.validate();
        if (valid) {
            this.LecturesList.data_entry = this.user;
            this.LecturesList.fk_semester_subject=this.id;
        await this.$axios
            .put(
                this.base_url + "system-management/subject-lecture-data/" + this.update_id +'/',
                this.LecturesList,
            
            )
            .then(() => {
              this.$emit("successAlert", this.$t("globals.data_updated"));
                        this.resetForm();

            this.getData();
              this.drawer = !this.drawer
            })
           .catch((e) => {
                    if (e.response.data.non_field_errors[0] == "الحقول fk_semester_subject, lecture_no يجب أن تشكل مجموعة فريدة.") {
                        this.$emit("errorAlert", this.$t("lectures_subject.uniq_lec"));
                        }            
                        else{
                            this.$emit("errorAlert", this.$t("alert.failure.title"));
                        }  
                        });
        }
          }
        },
        async delData(delete_id, isPermission = false) {
        if (!isPermission) {
            if (delete_id != undefined) {
            var status
            await this.$axios
                .delete('system-management/subject-lecture-data/' + delete_id +'/')
                .then(() => {
                status = true
                })
                .catch((error) => {
                status = error
                })
            return status
            }
        } else {
            return true
        }
        },
        async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
        await this.$axios('system-management/subject-lecture-data/filter-paginate/', {
            params: {
            search: txt_search,
            page: page,
            page_size: perPage,
            sort_by: order_data,
            field:"fk_semester_subject",
            value: this.id,
            },
        })
            .then((response) => {
            this.items = response.data.results
            this.pagination = response.data.pagination
            })
            .catch(() => {})
        },
        async saveData() {
          if (this.panel === undefined || this.panel === null) {
          }else{
        const { valid } = await this.$refs.form.validate();
            if (valid) {
            this.LecturesList.data_entry = this.user;
            this.LecturesList.fk_semester_subject=this.id;
            await this.$axios
                .post('system-management/subject-lecture-data/', this.LecturesList)
                .then(() => {
                this.$emit("successAlert", this.$t("globals.data_added"));
                this.resetForm();
                this.getData();
                this.drawer = false;
                })
                .catch((e) => {
                    if (e.response.data.non_field_errors[0] == "الحقول fk_semester_subject, lecture_no يجب أن تشكل مجموعة فريدة.") {
                        this.$emit("errorAlert", this.$t("lectures_subject.uniq_lec"));
                        }            
                        else{
                            this.$emit("errorAlert", this.$t("alert.failure.title"));
                        }  
                        });
            
            }
          }
        },
    },
    }
</script>
