<template>
  <v-expansion-panels model-value="2"  v-if="expanstion">
    <v-expansion-panel value="1" class="border border-primary">
      <template v-slot:default>
        <v-expansion-panel-content :ripple="false">
          <v-row class="d-flex pa-2">
            <v-col cols="6">
              <h2>
                {{ head_title }}
              </h2>
            </v-col>
            <v-col cols="5">
              <h3 style="text-align: left">{{ head_date }}</h3>
            </v-col>
            <div class="w-100">
              <v-divider class="my-0 mr-1 ml-1"></v-divider>
            </div>
          </v-row>
          <v-expansion-panel-title>
            {{ title }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <slot> </slot>
          </v-expansion-panel-text>
        </v-expansion-panel-content>
      </template>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-card v-if="!expanstion" color="cardcolor" :class="cardstyle">
    <v-card-text>
      <v-row class="d-flex pa-2">
        <v-col cols="6">
          <h2>
            {{ head_title }}
          </h2>
        </v-col>
        <v-col cols="5">
          <h3 style="text-align: left">{{ head_date }}</h3>
        </v-col>
      </v-row>

      <slot> </slot>
    </v-card-text>
    <v-card-actions>
      <slot name="actions"> </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CustomCard',
  data() {
    return {}
  },
  props: {
    head_title: {
      type: String,
    },
    head_date: {
      type: String,
    },
    expanstion: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    cardstyle: {
      type: String,
      default:""
    },
  },
}
</script>
