<template>
  <custom-auto-complete
    :items="items"
    item-title="year_h"
    item-value="id"
   :label="$t('globals.academic_year')"
    icon="calendar"
    :col="col"
    :rules="rules"
     />
</template>
<script>

export default {
  props:{
    col:{
      type:Number,
      default(){
        return 3;
      }
    },
    rules:{
      type:Object,
       
    },
    filter:{
      type:Array,
       
    }

  },
  data() {
    return {
      items: [],
 
    }
  },
  async created() {    
    if(!this.filter)
      this.items =[]
  },
   watch:{
      filter(){
        this.items = this.filter
      }
      }

 
}
</script>