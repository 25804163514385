<template>
  <v-list-group
    class="border-bottom"
    :prepend-icon="item.icon ? item.icon : 'mdi-minus'"
    :fluid="false"
    :value="item.name_ar"
  >
    <template v-slot:activator="{ props }">
      <v-list-item
        v-bind="props"
        :prepend-icon="item.icon ? 'mdi-' + item.icon : 'mdi-minus'"
        :title="$i18n.locale == 'ar' ? item.name_ar : item.name_en"
        :value="$i18n.locale == 'ar' ? item.name_ar : item.name_en"
        min-height="30"
      >
      <template v-slot:title>
        <span :class="css_class">{{$i18n.locale == 'ar' ? item.name_ar : item.name_en}}</span>
      </template>
        
      </v-list-item>
    </template>
    <slot name="childItem"></slot>
  </v-list-group>
</template>
<script>

export default {
  name: 'NoChildren',

  props: {
    item: {
      type: Object,
    },
    css_class:{
      type:String
    }
  },
  data() {
    return {}
  },
}
</script>