<template>
  <custom-auto-complete
    :items="items"
    item-title="name"
    item-value="id"
    :label="$t('class.level')"
    icon="stairs-box"

    :rules="rules"
    />
</template>
<script>
import {  getLevelChoice } from '@/plugins/methods/global-methods'

export default {
  props:{
    rules:{
      type:Object,
       
    }

  },
  data() {
    return {
      items: [],
 
    }
  },
  async created() {    
      this.items =await getLevelChoice().catch(()=>{
        return "error"        
      })  
  },
 
}
</script>