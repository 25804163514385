<template>

  <v-row>
    <v-col cols="12" md="4">
      <v-card height="100%" elevation="15">
        <v-card-text class="text-center rou">
          <v-form ref="form" @keydown.enter="loginAction(username, password)">
            <v-row>
              <v-col cols="12" class="mt-10"><h1>تسجيل الدخول</h1></v-col>
              <v-col cols="12" class="mt-3"
                ><h3>قم بادخال اسم المستخدم وكلمة المرور لتسجيل الدخول</h3></v-col
              >
            </v-row>
            <v-row justify="center" class="pa-6">
              <v-col cols="12" md="12" class="ma-1">
                <v-text-field
                  autofocus
                  v-model="username"
                  :label="$t('user.username')"
                  :placeholder="$t('user.username')"
                  density="compact"
                  aria-autocomplete="off"
                  clearable
                  @input="error ? (error = null) : null"
                  hide-details="auto"
                  :error-messages="v$.username.$errors.map((e) => e.$message)"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="indigo">mdi-account</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="ma-1">
                <v-text-field
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  hide-details="auto"
                  clearable
                  name="password"
                  id="password"
                  @input="error ? (error = null) : null"
                  autocomplete="new-password"
                  :placeholder="$t('user.password')"
                  density="compact"
                  :label="$t('user.password')"
                  :error-messages="v$.password.$errors.map((e) => e.$message)"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="indigo" @click="showPassword = !showPassword">{{
                      showPassword ? "mdi-eye-off" : "mdi-eye"
                    }}</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <p v-if="error" class="text-error text-start mt-1" style="font-size: 11px">
                {{ error }}
              </p>
              <v-col cols="12" class="my-2">
                <VBtn
                  width="100%"
                  :loading="loading"
                  class="bg-indigo"
                  size="small"
                  @click.prevent="loginAction(username, password)"
                >
                  <span class="text-white">
                    {{ $t("user.enter") }}
                  </span>
                </VBtn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="d-none d-lg-flex" md="8">
      <v-row class="justify-center mt-8">
        <v-col cols="12">
          <v-img
            :src="require('@/assets/images/login/logo2.png')"
            aspect-ratio="1"
            rounded
            max-height="500px"
          ></v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  data() {
    return {
 
      v$: useValidate(),
      showPassword: false,
      overlay: false,
      username: undefined,
      loading: false,
      password: undefined,
      backgroundImage: require("@/assets/images/login/background.jpg"),
      error: null,
      lang: localStorage.getItem("user-locale")
        ? localStorage.getItem("user-locale")
        : "ar",
    };
  },
  created() {
    document.title ="جامعة العلوم الرقمية"
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage(this.$t("globals.required_field"), required),
        // email: helpers.withMessage(this.$t("errors.email"), email),
      },
      password: {
        required: helpers.withMessage(this.$t("globals.required_field"), required),
      },
    };
  },
  computed: {
    backgroundStyle() {
      return {
        "background-image": `linear-gradient(rgba(52,135, 246,.2), rgba(52,135,246,.8)),url(${this.backgroundImage})`,
        "background-size": "cover",
      };
    },
    ...mapState({
      login_error: (state) => state.login_error,
    }),
  },
  methods: {
    resetForm() {
      this.v$.$reset();
      this.$refs.form.reset();
      this.login_error = undefined;
    },
    async loginAction(username, password) {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          this.loading = true;
          await this.$axios
            .post("users/api/token/", { username, password })
            .then((response) => {
              if (
                response.data.status &&
                (response.data.status == 4000 || response.data.status == 9001)
              ) {
                this.error = this.$t("user.login_error");
              } else {
                this.$store.dispatch("setAuthenticated", response);
              }
              this.loading = false;
            });
        } catch (error) {
          console.log(error)
          this.loading = false;
          if (error.message.includes("Network Error")) {
            this.error = this.$t("alert.failure.connect");
          } else {
            if (error.response && error.response.status == 403) {
              this.error = this.$t("globals.error_in_data");
            }
            if (error.response && error.response.status == 401) {
              this.error = this.$t("user.login_error");
            }
          }

          return 0;
        }
      }
    },
  },
};
</script>
<style scoped>
.main-bg {
  height: 100vh;
}
</style>
