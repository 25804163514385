<template>
  <nav-drawer
    v-show="drawer"
    v-model="drawer"
    :title="is_update ? 'update' : 'create'"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
          <custom-text-field
            v-model="dropList.hall_no"
            icon="town-hall"
            :label="$t('hall.hallname')"
            :rules="[$validateRequired, $max_length(10)]"
          />
        <custom-text-field
            type="number"
            v-model="dropList.number_of_seats"
            icon="chair-rolling"
            :label="$t('hall.numberofseats')"
            :rules="[$max_length(4)]"
          />
        <custom-text-field
            type="number"
            v-model="dropList.floor_no"
            icon="office-building-plus"
            :label="$t('hall.floornumber')"
            :rules="[$max_length(2)]"
          />
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn v-if="!is_update" type="save" @click="saveData()" />
      <custom-btn v-if="is_update" type="update" @click="updateData()" />
      <custom-btn type="cancel" @click.stop="drawer = !drawer" />
    </template>
  </nav-drawer>

  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="true"
    :perm="{}"
    exports
    :create="()=>{drawer = true , this.resetForm()}"
  />
</template>
<script>
export default {
  props: {
    perm: Object,
    id: {
      type: Number,
    },
  },

  data() {
    return {
      items: [],
      pagination: undefined,
      dropList: {},
      is_update: false,
      drawer: null,
      headers: [
        { title: this.$t('hall.hallname'), key: 'hall_no' },
        { title: this.$t('hall.numberofseats'), key: 'number_of_seats' },
        { title: this.$t('hall.floornumber'), key: 'floor_no' },
        { title: this.$t('globals.actions'), key: 'actions' },
      ],
    }
  },

  methods: {
    opendailog() {
      this.drawer = !this.drawer
      this.is_update = false
      this.resetForm()
    },
    resetForm() {
      this.dropList = {}
      this.is_update = false
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.dropList.data_entry = this.user
        await this.$axios
          .put(
            this.base_url +
              'system-management/hall-data/' +
              this.update_id +
              '/',
            this.dropList
          )
          .then(() => {
            this.$emit('successAlert', this.$t('globals.data_updated'))
            this.resetForm()
            this.drawer = false
            this.getData()
          })
          .catch(() => {
            this.$emit('errorAlert', this.$t('globals.updated_failure'))
          })
      }
    },
    editData(data) {
      this.update_id = data.id
      this.drawer = true
      this.dropList = { ...data }
      this.is_update = true
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('system-management/hall-data/' + delete_id + '/')
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
        await this.$axios(`system-management/hall-data/filter-paginate/`, {
        params: {
          value: this.id,
          field: 'fk_building',
          role: this.dropList.role,
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
        },
      })
        .then((response) => {
          this.items = response.data.results
          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.dropList.fk_building = this.id
        await this.$axios
          .post('system-management/hall-data/', this.dropList)
          .then(() => {
            this.$emit('successAlert', this.$t('globals.data_added'))
            this.resetForm()
            this.getData()
            this.drawer = false
          })
          .catch((error) => {
            if (error.response.data.includes('Duplicate entry')) {
              this.$emit('errorAlert', this.$t('globals.error_in_repeted_data'))
            } else {
              this.$emit('errorAlert', this.$t('globals.error_in_data'))
            }
          })
      }
    },
  },
}
</script>
