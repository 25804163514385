<template>
  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <custom-text-field
          v-model="dropList.lecture_no"
          :label="$t('subject_lecture.lecture_no')"
          icon="abjad-arabic"
          readonly
          disabled
        />

        <drop-list v-if="type==0" v-model="dropList.fk_lecture_name" name="lecture_name" :param="lecture_id"/>
        <custom-text-field
          v-model="dropList.number_of_hours"
          :label="$t('globals.number_hours')"
          icon="clock"
          type="number"
          :rules="[$required, $max_value(23)]"
        />
        <custom-text-field
          v-model="dropList.number_of_minutes"
          :label="$t('globals.number_minutes')"
          icon="clock-outline"
          type="number"
          :rules="[$required, $max_value(59)]"
        />
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click.stop="resetForm(), (drawer = !drawer)" />
    </template>
  </nav-drawer>
  <v-row class="pa-5">
    <v-col>
      <h4>{{ $t('globals.theoretical') }}</h4>
      <custom-data-table-2
        :headers="headers"
        :items="items_1"
        :pagination="pagination_1"
        :method="getData_1"
        :editItem="editData"
        :perm="{}"
        :actionList="actionList"
      >
        <template v-slot:item="{ item, key }">
          <span v-if="key == 'number_of_hours_and_minutes'">
            {{ timeCal(item[key]) }}
          </span>
        </template>
      </custom-data-table-2>
    </v-col>
    <v-spacer />
    <v-col>
      <h4>{{ $t('globals.lab') }}</h4>
      <custom-data-table-2
        :headers="headers"
        :items="items_2"
        :pagination="pagination_2"
        :method="getData_2"
        :editItem="editData"
        :perm="{}"
        :actionList="actionList"
      >
        <template v-slot:item="{ item, key }">
          <span v-if="key == 'number_of_hours_and_minutes'">
            {{ timeCal(item[key]) }}
          </span>
        </template>
      </custom-data-table-2>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    id: Number,
    type: Number,
    fk_semester_group_4B:Number
  },
  data() {
    return {
      items_1: [],
      pagination_1: undefined,
      items_2: [],
      pagination_2: undefined,
      dropList: {},
      updated_id: undefined,
      drawer: null,
      perm: {},
      path: undefined,
      lecture_id: undefined,
      headers: [
        {
          title: this.$t('globals.subject_lecture_batch'),
          key: 'fk_semester_subject_4b',
        },
        { title: this.$t('subject_lecture.lecture_no'), key: 'lecture_no' },
        {
          title: this.$t('globals.lecture_time'),
          key: 'number_of_hours_and_minutes',
        },
        { title: this.$t('globals.actions'), key: 'actions', sortable: false },
      ],
    }
  },
  created() {},
  methods: {
    actionList(data) {
      return [
        this.type == 0
          ? data.fk_lecture_name_grouping!=null?[
              'LecturesDefnition.title',
              () =>
                this.$router.replace({
                  name: 'topics-for-lectur',
                  params: { fk_lecture_name_grouping: data.fk_lecture_name_grouping },
                }),
            ]:[]:[
              'globals.lectures_name',
              () =>
                this.$router.replace({
                  name: 'names-for-lectur',
                  params: { fk_subject_lecture_4b: this.id },
                }),
            ],
      ]
    },
    openDialog() {
      this.drawer = true
    },
    //لحساب عدد الساعات والدقائق
    timeCal(time) {
      const hours = Math.floor(time / 60)
      const minutes = Math.floor(time % 60)
      let str = ''
      if (hours == 1) str = 'ساعة'
      else if (hours == 2) str = 'ساعتين'
      else if (hours > 10) str = `${hours} ساعة`
      else if(hours!=0) str = `${hours} ساعات`

      if (hours > 0) str += ' و '

      if (minutes == 1) str += 'دقيقة'
      else if (minutes == 2) str += 'دقيقتين'
      else if (minutes > 10) str += `${minutes} دقيقة`
      else str += `${minutes} دقائق`

      return str
    },
    resetForm() {
      this.$refs.form.reset()
      this.dropList = {}
      this.update_id = undefined
      this.lecture_id = undefined
    },
    async getData_1(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('academic-affairs/subject-lecture4B/data/', {
        params: {
          fk_semester_group_4B:this.fk_semester_group_4B,
          fk_semester_subject_4b: this.id,
          lecture_type: 1,
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
        },
      })
        .then((response) => {
          this.items_1 = response.data.results
          this.pagination_1 = response.data.pagination
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async getData_2(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('academic-affairs/subject-lecture4B/data/', {
        params: {
          fk_semester_group_4B:this.fk_semester_group_4B,
          fk_semester_subject_4b: this.id,
          lecture_type: 2,
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
        },
      })
        .then((response) => {
          this.items_2 = response.data.results
          this.pagination_2 = response.data.pagination
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async editData(data) {

      this.resetForm()
      this.update_id = data.id
      this.dropList = { ...data }
      const hours = Math.floor(data.number_of_hours_and_minutes / 60)
 
      const minutes = Math.floor(data.number_of_hours_and_minutes % 60)
      this.dropList.number_of_hours = String(hours)
      this.dropList.number_of_minutes = minutes
      this.drawer = true
      this.lecture_id = data.id
    },
    async updateData() {      
      const { valid } = await this.$refs.form.validate()
      if (valid) {
       
        await this.$axios
          .patch(`academic-affairs/subject-lecture4B/${this.dropList.id}/`, {
            fk_lecture_name:this.dropList.fk_lecture_name,
            fk_semester_group_4B:this.fk_semester_group_4B,
            number_of_hours_and_minutes:
              Number(this.dropList.number_of_hours) * 60 +
              Number(this.dropList.number_of_minutes),
          })
          .then(() => {
            this.$emit('alert', 'update')
          
            this.drawer = false
            this.lecture_id == 1
              ? this.getData_1()
              : this.getData_2()

            this.resetForm()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
  },
}
</script>