<template>
  <custom-auto-complete
    :items="items"
    item-title="name_ar"
    item-value="id"
    :label="$t('attatchment.title')"
    icon="attachment"
    :col="col"
    :rules="rules"
  />
</template>


<script>


// import { getaccommodationtype } from '@/plugins/methods/global-methods'

export default {
    props:{
    col:{
      type:Number,
      default(){
        return 3;
      }
    },
    rules:{
      type:Object,
       
    }

  },
  
  data() {
    return {
      items: [],
    }
  },

  async created() {
      await this.$axios('accommodation/attachment/')
        .then((response) => {
          this.items = response.data.results

          this.pagination = response.data.pagination
        })
        .catch(() => {})
}
}
</script>