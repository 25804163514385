<template>
  <custom-auto-complete
    :items="items"
    item-title="nationality_name_ar"
    item-value="id"
    :label="$t('user.nationality')"
    icon="flag"
    :col="col"
    :rules="rules"
  />
</template>
<script>
// import { getGanders } from '@/plugins/methods/global-methods'

export default {
  props: {
    col: {
      type: Number,
      default() {
        return 3;
      },
    },
    rules: {
      type: Object,
    },
  },
  name: "ListGanders",
  data() {
    return {
      items: [],
    };
  },
  async created() {
    this.items = await this.$axios
      .get(this.base_url + "common/country-data/")
      .then((e) => e.data.results);
  },
};
</script>
