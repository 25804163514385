<template>
     
      <v-form ref="form">
      <v-card-item>  
        <v-card class="pa-2">
           <v-row>
                <custom-file-input
                  v-model="data.document"
                  col="3"
                  :rules="[$required]"
                />
                <drop-list
                  v-model="data.fk_required_document_4c"
                  name="get_type_docmunetFor_checkDocument"
                  col="3"
                  :param="dropList.colleg"
                  :rules="[$required]"
                />
                <custom-text-field
                  v-model="data.document_no"
                  :label="$t('globals.document_no')"
                  icon="numeric"
                  col="3"
                  :rules="[$required, $max_length(50)]"
                />
 
                
 
              </v-row>
              <v-row>
                   <datetime-picker
                    v-model="data.issue_date"
                    :calendar="'gregorg'"
                    placeholder="YYYY-MM-DD"
                    :label="$t('globals.date_obtained')"
                    :datetime_picker="'fk_archives_issue_date_' + key"
                    col="3"

                  />
                  <datetime-picker
                    v-model="data.expiration_date"
                    :calendar="'gregorg'"
                    placeholder="YYYY-MM-DD"
                    :label="$t('employee.expiration_date')"
                    :datetime_picker="'expiration_date_cetificate' + key"
                    col="3"
                  />
           
                <custom-text-field
                dense
                  v-model="data.issue_place"
                  :label="$t('student_details.release_place')"
                  icon="map-marker-outline"
                  col="3"
                  :rules="[$max_length(100)]"
                />
                <custom-btn type="add" @click="add" class="align-self-center ms-5"/>
              </v-row> 
              </v-card>             
        <v-divider class="my-3"/>
        <v-data-table-virtual :headers="headers" density="compact" :items="items">
          <template #item="{ item,index }">
            <tr>
                <td>{{index +1}}</td>
                <td>{{ item.document }}</td>
                <td>{{ item.document_no }}</td>
                <td>{{ item.fk_required_document_4c }}</td>
                <td>{{ item.issue_date }}</td>
                <td>{{ item.expiration_date }}</td>
                <td>{{ item.issue_place }}</td>
                <td><v-icon color="error" @click="remove(index)">mdi-trash-can-outline</v-icon></td>
            </tr>
          </template>
        </v-data-table-virtual>
      </v-card-item>
      </v-form>    
    
</template>
<script>
export default {
  props: {
    dropList: Object,
  },
  data() {
    return {
      data:{},   
      headers: [
          { title: '#', key: 'index' ,sortable:false}, 
          { title: this.$t('documenttype.documentname'), key: 'document' ,sortable:false}, 
          { title: this.$t('globals.document_no'), key: 'document_no' ,sortable:false}, 
          { title: this.$t('employee.documnet_type'), key: 'document_type' ,sortable:false}, 
          { title: this.$t('globals.date_obtained'), key: 'issue_date' ,sortable:false}, 
          { title: this.$t('employee.expiration_date'), key: 'expiration_date' ,sortable:false}, 
          { title: this.$t('student_details.release_place'), key: 'issue_place' ,sortable:false}, 
          { title: this.$t('globals.actions'), key: 'actions' ,sortable:false}, 
        ]  ,
    
      items:[]
    }
  },
  methods: {
    async add() {
      const { valid } = await this.$refs.form.validate()
      if (valid){
        this.items.push({...this.data,document:this.data.document[0].name})
        this.value?.check_document.push({
          fk_required_document_4c: this.data.fk_required_document_4c,
          fk_archives: {
            document: this.data.document,
            document_no: this.data.document_no,
            document_type: this.data.fk_required_document_4c,
            issue_date: this.data.issue_date,
            expiration_date: this.data.expiration_date,
            issue_place: this.data.issue_place,
          },
          status: true,
          is_for_collage: 1,
        })
        this.data = {}
      }
      
    },
    remove(index) {
       this.value.check_document.splice(index,1)
       this.items.splice(index,1)
    },
    
  },

  computed: {
    value: {
      get() {
        return this.dropList
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
