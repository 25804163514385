<template>

  <custom-auto-complete
    :items="items"
    :placeholder="$t('list.subject_placeholder')"
    item-title="name"
    item-value="subject_id"
    :label="$t('list.subject')"
    icon="book"
    :col="col"
    :rules="rules"
  />
</template>
<script>
export default {
  props: {
    col: {
      type: Number,
      default() {
        return 12
      },
    },
    rules: {
      type: Object,
    },
    semester: {
      type: undefined,
      default: 0,
    },
  },
  data() {
    return {
      items: [],
    }
  },

  watch: {
    semester() {
      if (this.semester) {
        this.$axios
          .get('/academic-affairs/semester-subject4B/filter/', {
            params: {
              field: 'fk_semester_4b',
              value: this.semester,
            },
          })
          .then((e) => {
            console.log(e.data);
            this.items = e.data
          })
      }
    },
  },
}
</script>
