<template>

  <div color="cardcolor">
    <v-list-item class="py-2 first" style="background: rgb(28 34 47); color: white">
      <div class="d-flex justify-space-between align-center">
        <div style="height: 32px" class="d-flex align-center">
          <!-- <span v-show="hideText">{{ $t("name") }}</span> -->
        
          <span v-show="hideText">
            جامعة العلوم الرقمية
                <!-- <v-img
                  :src="require('@/assets/images/login/logo2.png')"
                  aspect-ratio="1"
                  rounded
                  max-height="500px"
                  ></v-img>  -->
          </span>
        </div>
        <v-btn
          @click="changeRail(), (open = [])"
          density="compact"
          variant="text"
          :icon="
            $i18n.locale == 'en'
              ? hideText
                ? 'mdi-chevron-double-left'
                : 'mdi-chevron-double-right'
              : hideText
              ? 'mdi-chevron-double-right'
              : 'mdi-chevron-double-left'
          "
          class="mt-1"
        ></v-btn>
      </div>
    </v-list-item>
    <v-divider></v-divider>
    <v-card class="h-100 overflow-y-auto">
      <v-list
        v-model:opened="open"
        :slim="true"
        @click="rail = !rail"
        density="compact"
        open-strategy="single"
        v-model:selected="selectedList"
       
        @click:select="tempOpenLists = open"
      >
        <item-side-bar :items="items" />
      </v-list>
    </v-card>
  </div>
</template>

<script>
import ItemSideBar from '@/components/sidebar/ItemsSideBar.vue'
import axios from 'axios'

export default {
created(){
        document.title ="جامعة العلوم الرقمية"
    },
  name: 'SideBar',
  components: {
    ItemSideBar,
  },
  data() {
    return {
      selectedList: [],
      open: ['Users'],
      items: [],
      hideText: localStorage.getItem('rail')
        ? !JSON.parse(localStorage.getItem('rail'))
        : true,
    }
  },
  async beforeCreate() {

    await axios.get(`${this.base_url}front-end/sidbar/`).then((response) => {
      this.items = response.data.results
    })
  },
  mounted() {
    var active_elements = document.querySelectorAll('.v-list-item--active')
    if (active_elements.length > 0) {
      const active_list_item = active_elements[
        active_elements.length - 1
      ].querySelector('.v-list-item__content .v-list-item-title span').innerHTML
      this.setBreadcrumb(active_list_item)
    } else {
      this.setBreadcrumb()
    }
  },
  methods: {
    BeforeReload() {
      this.selectedList.forEach((item) => {
        localStorage.setItem('selected_list', item)
      })
    },
    railArrow() {
      localStorage.setItem('rail', 'false')
      this.hideText = true
      this.$emit('changeRail', false)
    },
    changeRail() {
      if (localStorage.getItem('rail') !== null) {
        if (localStorage.getItem('rail') == 'false') {
          localStorage.setItem('rail', 'true')
          this.hideText = false
        } else {
          localStorage.setItem('rail', 'false')
          this.hideText = true
        }
      } else localStorage.setItem('rail', 'false')
      this.$emit('changeRail', JSON.parse(localStorage.getItem('rail')))
    },
    setBreadcrumb(active = null) {
      let items = []
      items = Object.values(this.open).reverse()
      if (active != null) {
        items.push(active)
        this.$store.commit('updateBreadcrumbValue', items)
      } else if (this.selectedList.length > 0) {
        this.selectedList.forEach((item) => {
          items.push(item)
        })
        this.$store.commit('updateBreadcrumbValue', items)
      }
    },
    Expand() {
      this.show = !this.show
      if (this.expand == false) {
        this.tempOpenLists = this.open
        this.open = []
      } else {
        if (this.open[0] != 0) {
          this.open = this.tempOpenLists
        }
      }
      this.expand = !this.expand
    },
    changeDrawer() {
      this.$store.commit('updateDrawer', !this.drawerVal)
    },
  },
  watch: {
    selectedList() {
      this.setBreadcrumb()
    },
  
  },
}
</script>

<style scoped>
.v-list-item-title {
  font-size: 1.11rem;
}
.v-navigation-drawer .v-list-item.first {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
