<template>
  <v-app-bar density="compact" id="header" style="background: linear-gradient(30deg, rgb(94 112 151), rgb(28 34 47));color:white">
    <template v-slot:prepend>
      <VAppBarNavIcon
        id="appIconMenu"
        @click="changeDrawer()"
        class="text-white"
      ></VAppBarNavIcon>
    </template>
    <span
      class="text-grey-lighten-1 mt-1 d-none d-md-block d-lg-block"
      style="font-size: 0.9rem"
    >
      {{ user_profile.role_name }}</span
    >
    <!-- log out dialog  -->
    <v-dialog v-model="exitDialog" width="300" :dir="direction" class="text-center">
      <v-card>
        <v-card-title>
          <h3>
            {{ $t("globals.log-out") }}
          </h3>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-2 py-1">
          <small>{{ $t("user.logout-confirm") }}</small>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-2 justify-space-between">
          <v-btn @click="logoutfromsys" class="w-50">
            <span class="text-error">{{ $t("globals.exit") }}</span>
          </v-btn>
          <v-divider vertical />
          <v-btn @click="exitDialog = false" class="w-50">
            <span class="text-indigo"> {{ $t("globals.cancel") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <VSpacer />
    <v-overlay v-model="overlay"></v-overlay>
    <v-btn icon @click="overlay = !overlay"
      ><v-icon color="error">mdi-power</v-icon></v-btn
    >
    <VSpacer />
    <v-btn icon @click="toggleFullscreen"
      ><v-icon :icon="full_screen_icon"></v-icon
    ></v-btn>
    {{theme}}
    <v-btn :icon="theme=='light'?'mdi-lightbulb':'mdi-lightbulb-outline'" @click="toggleTheme" ></v-btn>

    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" icon>
          <v-img v-if="user_profile.image_user">
            <v-avatar size="20" :image="base_url + user_profile.image_user"></v-avatar>
          </v-img>
          <v-icon v-else size="large">mdi-account</v-icon>
        </v-btn>
      </template>
      <v-card :dir="direction" rounded="lg">
        <v-layout>
          <v-list>
            <v-list-item
              :prepend-avatar="
                user_profile.image_user ? base_url + user_profile.image_user : ''
              "
              :title="user_profile.full_name"
              :subtitle="user_profile.email"
            >
            </v-list-item>
            <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
            <v-list-item
              density="compact"
              prepend-icon="mdi-form-textbox-password"
              :title="$t('user.change-password')"
              :to="{ name: '' }"
            >
            </v-list-item>
            <v-list-item
              density="compact"
              prepend-icon="mdi-account-circle"
              :title="$t('user.profile')"
              :to="{ name: '' }"
            >
            </v-list-item>
            <v-list-item
              density="compact"
              prepend-icon="mdi-logout"
              :title="$t('globals.log-out')"
              @click="exitDialog = true"
            >
            </v-list-item>
          </v-list>
        </v-layout>
      </v-card>
    </v-menu>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" append-icon="mdi-translate" size="small">
          <span class="text-grey-lighten-1 mt-1" style="font-size: 10px">{{
            $i18n.locale == "en" ? "English" : "العربية"
          }}</span>
        </v-btn>
      </template>
      <v-card width="100" rounded="lg" class="mx-2 px-2" elevation="2">
        <v-list v-model="language" density="compact">
          <v-list-item
            min-height="30px"
            density="compact"
            v-for="(item, i) in languages"
            :key="i"
            :title="languageName[item]"
            @click="changeLocale(item)"
          ></v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import i18n from "@/plugins/i18n";
import {mapState, mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    const savedLocale = localStorage.getItem("locale");
    if (savedLocale) this.$i18n.locale = savedLocale;
  },
  data() {
    return {
      newTheme:null,
      user_profile: {
        email: null,
        username: null,
        full_name: null,
        image_user: null,
      },
      create_backup: false,
      exitDialog: false,
      username: undefined,
      overlay: false,
      language: null,
      full_screen_icon: "mdi-fullscreen",
      languages: i18n.global.availableLocales,
      languageName: {
        ar: "العربية",
        en: "English",
      },
    };
  },
  
  methods: {
    toggleTheme(){
      const newTheme = this.theme === 'light' ? 'dark' :'light'
      this.$store.commit('toggleTheme',newTheme)
      localStorage.setItem('themePreference',newTheme)
    },
    getFullscreenElement() {
      return (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullscreenElement ||
        document.msFullscreenElement
      );
    },
    toggleFullscreen() {
      if (this.getFullscreenElement()) {
        document.exitFullscreen();
        this.full_screen_icon = "mdi-fullscreen";
      } else {
        document.documentElement.requestFullscreen().catch(console.log);
        this.full_screen_icon = "mdi-fullscreen-exit";
      }
    },
    async getUserProfile() {
      await this.$axios
        .get(`api/user/profile`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.user_profile = response.data;
        });
    },
    changeDrawer() {
      if (localStorage.getItem("drawer") !== null) {
        if (localStorage.getItem("drawer") == "false")
          localStorage.setItem("drawer", "true");
        else localStorage.setItem("drawer", "false");
      } else localStorage.setItem("drawer", "false");
      this.$emit("changeDrawer", JSON.parse(localStorage.getItem("drawer")));
    },
    
    CreateBackUp() {
      //   this.loading = true;
      this.$axios(`api/export-database?file_name=null$extension=null`)
        .then(() => {})
        .catch(() => {});
    },
    async changeLocale(currentLocal) {
      this.$vuetify.locale.current = currentLocal;
      localStorage.setItem("locale", currentLocal);
      if (this.$i18n.locale != currentLocal) {
        i18n.global.locale = currentLocal;
        localStorage.setItem("user-locale", currentLocal);
        const dir = localStorage.getItem("user-locale") === "en" ? "ltr" : "rtl";
        this.$store.dispatch("updatelanguage", localStorage.getItem("user-locale"));
        this.$store.dispatch("updatedirection", dir);       
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      }
         this.$emit("changeLocal",currentLocal);
    },
    async logoutfromsys() {
      this.logout;
      // window.location.reload();
    },
  },

  async created() {
    this.$store.dispatch("updatelanguage", localStorage.getItem("user-locale"));
    this.username = localStorage.getItem("username");
    const theme = localStorage.getItem("themePreference");
    if (theme === 'dark') {
      this.newTheme = "dark"
      this.$store.commit('toggleTheme',this.newTheme)
      localStorage.setItem('themePreference',this.newTheme)
    }
    this.$vuetify.locale.current = localStorage.getItem("locale");
    // await this.getUserProfile();
  },
  provide() {
    return {};
  },
  computed: {
    // direction() {
    //   return this.$i18n.locale == "en" ? "ltr" : "rtl";
    // },
    ...mapState({
      theme:(state)=>state.theme
    }),
    ...mapGetters(["getlang", "getuser"]),
    ...mapActions(["logout"]),
  },
};
</script>

<style scoped>
.v-appbar{
  color:#0747a6 !important;
}
#search {
  outline: unset;
  color: white;
}
</style>
