<template>

  <custom-auto-complete
    :items="list"
    item-title="name"
    item-value="id"
    :label="$t('housing.fk_service.service_status')"
    icon="room-service"
    col="3"
  />
</template>
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  async created() {
    if (!this.list.length) {
      await this.$axios("choices/choice-data/", {
        params: {
          param: "RequestStatusChoice",
        },
      })
        .then((response) => {
          this.list = response.data;
          console.log(this.list);
        })
        .catch(() => {});
    }
  },
};
</script>
