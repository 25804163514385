<template>
  <v-col col="8">
    <v-row>
      <drop-list
        v-model="value.student_data.dictating"
        name="dictating"
        col="3"
        :rules="[$required]"
      />
      <drop-list
        v-model="value.student_data.quran"
        name="quran"
        col="3"
        :rules="[$required]"
      />
      <drop-list
        v-model="value.student_data.full_time_ratio"
        name="full_time_ratio"
        col="3"
        :rules="[$required]"
      />
        <drop-list
        v-model="value.academic_data.accommodation_type"
        name="accommodation_type"
        col="3"
        :rules="[$required]"
      />
    </v-row>
    <v-row>
      <custom-text-field    
      v-if="value.academic_data.accommodation_type==1"     
        v-model="value.academic_data.reason_4_ask_4_housing"
        :label="$t('globals.reason_4_ask_4_housing')"
        icon="application-edit-outline"
        col="3"
        :rules="[$max_length(250)]"
      />     
      <drop-list
        v-model="value.student_data.addicted"
        name="addicted"
        col="3"
        multiple
      />    
       <drop-list
        v-model="value.talent"
        name="talent"
        col="3"
        multiple
      />
      
    </v-row>
 <h4 class="my-3">{{$t('globals.sending_party')}}</h4>
    
    <v-row>
     <custom-text-field
        v-model="value.fk_entity.entity"
         :label="$t('globals.sending_party')"
        icon="send"
        col="3"
        :rules="[
        value.fk_entity.discrption || value.fk_entity.phone_no ?$required:[],
        $max_length(60)]"  
      />
      <custom-text-field
        v-model="value.fk_entity.discrption"
         :label="$t('globals.description')"
        icon="note-outline"
        col="3"
        :rules="[
        value.fk_entity.entity || value.fk_entity.phone_no ?$required:[],        
        $max_length(250)]"  
      />
      <custom-text-field
        v-model="value.fk_entity.phone_no"
        :label="$t('globals.phone_number')"
        icon="phone"
        type="number"
        col="3"
        :rules="[
        value.fk_entity.entity || value.fk_entity.discrption ?$required:[],
        $max_length(15)]"
      />
   
    </v-row>
    <v-divider/>
     
<h4 class="my-3">{{$t('globals.discussion_committee')}}</h4>
 
  
        <v-row>
            <custom-text-field
        v-model="value.academic_data.discussion_committee"
        :label="$t('globals.discussion_committee')"
        icon="account-group"
        col="4"
       />
   
       <v-col cols="6">
        
        <v-textarea
          v-model="value.academic_data.discussion_committee_proposal"
          :label="$t('globals.discussion_committee_proposal')"
          :rules="[$max_length(250)]"
        />
      </v-col>
        </v-row>
    
 
   
    

  </v-col>
</template>
<script>
export default {
  props: {
    dropList: Object,
  },

  computed: {
    value: {
      get() {
        return this.dropList
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
