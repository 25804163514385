<template>
  <v-card elevation="2" class="v-card-table border border-primary" rounded>
    <v-data-table-server
      id="vDataTable"
      v-model:sort-by="sortBy"
      :headers="visibleHeaders"
      :items="items"
      height="450"
      density="compact"
      :loading="loading"
      @update:options="fetchData()"
      ref="myTable"
    >
      <template v-slot:loading>
        <v-skeleton-loader :type="`table-row-divider@${items?.length || 4}`" />
      </template>
      <template v-slot:top>
        <v-container style="padding-bottom: 0">
          <v-row class="align-center">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" color="white" size="small">
                  <v-icon style="color: #3487f6" class="me-2">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="h in header"
                    :key="h.key"
                    :label="h.title"
                    color="indigo"
                    v-model="h.show"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-text-field
              v-model="txt_search"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('globals.search-here')"
              single-line
              density="compact"
              class="search ma-3"
              variant="solo"
              @input="!txt_search ? fetchData() : ''"
              @keyup.enter="fetchData()"
            />
            <slot name="action"></slot>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td v-for="val in visibleHeaders" :key="val.key">
            <slot name="item" :item="item" :key="val.key">
              <span v-if="val.type == 'img'">
                <button v-if="item[val.key]" @click="showImage(item[val], val)">
                  <v-avatar
                    :image="
                      item[val.key] != null && item[val.key].search(base_url) != -1
                        ? val
                        : base_url + item[val.key]
                    "
                    size="small"
                  />
                </button>
              </span>

              <span v-else-if="val.type == 'check'">
                <v-icon v-if="item[val.key] == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="item[val.key] == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              

              <v-row v-else-if="val.key === 'actions'  && actionList==false">
                <v-menu location="end">
                  <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-dots-vertical" v-bind="props" size="sm"></v-btn>
                  </template>
                  <slot name="item-menu" :item="item" :key="val.key"> </slot>
                  <v-list
                    density="compact"
                    class="py-0 cursor-auto"
                    v-for="(methodName, index) in methodNames"
                    :key="index"
                  >
                    <v-list-item v-ripple @click="$emit(`${methodName}`, item)">
                      {{ $t(`${myClass.methodTitles[methodName]}`) }}
                      <v-icon :color="myClass.methodColors[methodName]">{{
                        myClass.methodIcons[methodName]
                      }}</v-icon>
                    </v-list-item>
                  </v-list>
                  <v-list density="compact" class="py-0 cursor-auto">
                    <v-list-item v-ripple @click="edit(item)">
                      {{ $t("globals.change") }}
                      <v-icon color="warning">mdi-pencil-outline</v-icon></v-list-item
                    >
                  </v-list>
                  <v-list density="compact" class="py-0 cursor-auto">
                    <v-list-item v-ripple @click="deleteItem(item.id)">
                      {{ $t("globals.delete") }}
                      <v-icon color="error">mdi-trash-can-outline</v-icon></v-list-item
                    >
                  </v-list>
                </v-menu>
              </v-row>
                    <v-row v-else-if="val.key === 'actions' && actionList==true"
                style="min-width: 100px !important"
              >
                <v-btn
                  v-if="editBtn"
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="large"
                  @click="edit(item)"
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="delBtn"
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  @click="deleteItem(item.id)"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-row>
              <span v-else class="text-wrap">{{ item[val.key] }}</span>
            </slot>
          </td>
        </tr>
      </template>
      <template #bottom>
        <div id="pagination-bar" class="d-flex">
          <v-pagination
            v-model="page"
            :length="pagination.num_pages"
            density="compact"
            show-first-last-page
          />
          <div class="d-flex">
            <v-select
              v-model="perPage"
              class="pa-0"
              :items="itemsPerPage"
              item-value="value"
              item-title="text"
              density="compact"
              hide-details
              variant="text"
            />
            <span class="mt-2 px-2"
              >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
              {{ pagination.count }} ] {{ $t("globals.item") }}</span
            >
          </div>
        </div>
      </template>
    </v-data-table-server>
  </v-card>

  <VDialog v-model="showImg_dialog" max-width="400px" max-height="400px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">
          {{ dialog_title }}
        </h1>
      </VCardTitle>
      <VCardItem align="center">
        <VImg :src="dialog_image" max-height="300px" />
      </VCardItem>
      <VCardText>
        <tr v-for="(item, index) in dialog_text" :key="index">
          <td class="text-grey-darken-1 font-weight-bold">{{ item.type }} :</td>
          <td>{{ item.contact }}</td>
        </tr>
      </VCardText>
      <VCardActions>
        <VBtn class="bg-info" @click="showImg_dialog = false">
          {{ $t("globals.ok") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <VDialog v-model="del_dialog" scrollable :overlay="false" max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteData"
          :loading="deleteDataLoading"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <AlertMessages
    v-model="show_alert"
    :iconColor="alert_icon_color"
    :icon="alert_icon"
    :title="alert_title"
    :message="alert_message"
  >
  </AlertMessages>
  <v-dialog
    color="indigo"
    :close-on-content-click="false"
    v-model="loadingPage"
    persistentid="loadingPage"
    width="auto"
  >
    <h1 v-if="!loadingImage" class="text-white">l o a d i n g . . .</h1>
    <div style="width: 80px; height: 80px">
      <v-img :src="loadingImage"></v-img>
    </div>
  </v-dialog>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>
<script>
export default {
  name: "CustomDataTable",
  data() {
    return {
      header: [],
      txt_search: undefined,
      sortBy: [],
      loading: false,
      page: 1,
      perPage: 10,
      delBtn: false,
      editBtn: false,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        // { value: null, text: this.$t("globals.all") },
      ],
      del_dialog: false,
      delete_id: undefined,
      deleteDataLoading: false,
      show_alert: false,
      alert_title: "",
      alert_message: "",
      alert_icon_color: "",
      alert_icon: "",
      data_message: [],
      protected_records: false,
      showImg_dialog: false,
      dialog_title: "",
      dialog_image: "",
    };
  },
  async created() {
    if (this.delItem) {
      this.delItem(0, true).then((res) => {
        this.delBtn = res;
      });
    }
    if (this.editItem) {
      try {
        this.editItem(0, true).then((res) => {
          this.editBtn = res;
        });
      } catch {
        this.editBtn = this.editItem(0, true);
      }
    }
    this.header = [];
    this.headers.forEach((e) => {
      let show = e.show == false ? false : true;
      this.header.push({
        title: e.title,
        key: e.key,
        sortable: e.sortable,
        show: show,
        type: e.type,
      });
    });
  },
  props: {
    actionList:{
      type:Boolean,
      default(){
        return false
      }
    },
    method: Function,
    delItem: Function,
    editItem: Function,

    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({
        count: 0,
        current_page: 1,
        num_pages: 0,
      }),
    },
  },
  computed: {
    visibleHeaders() {
      return this.header.filter((header) => header.show);
    },
  },
  watch: {
    sortBy() {
      const order_data = this.order_data();
      this.fetchData(this.page, this.perPage, order_data);
    },
    perPage() {
      this.fetchData(this.page, this.perPage);
    },
    page() {
      this.fetchData(this.page, this.perPage);
    },
  },
  methods: {
    printTable() {},
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },

    deleteItem(data) {
      this.delete_id = data;
      this.del_dialog = true;
    },

    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async fetchData(page = 1, perPage = 10, order_data = "") {
      this.loading = true;
      await this.method(page, perPage, order_data, this.txt_search);
      this.loading = false;
    },
    async deleteData() {
      this.deleteDataLoading = true;

      await this.delItem(this.delete_id).then((e) => {
        if (e == true) {
          this.fetchData();
          this.setSuccessAlert();
        } else if (e == "noPer") {
          this.setWarningAlert(this.$t("permission.no-permission"));
        } else {
          var code418 = false;
          try {
            e.message.includes(418);
            code418 = true;
          } catch {
            code418 = false;
          }
          if (code418) {
            this.protected_records = true;
            let error_messages = [];
            e.response.data.data.forEach((error) => {
              error_messages.push(this.$t(`models.${error.model}`) + ` = ${error.count}`);
            });
            this.data_message = error_messages;
          } else if (e != 406) {
            this.setErrorAlert();
          }
        }
      });
      this.deleteDataLoading = false;
      this.del_dialog = false;
      this.delete_id = undefined;
    },
    setErrorAlert() {
      this.alert_icon_color = "error";
      this.alert_icon = "cancel";
      this.alert_title = this.$t("globals.failure");
      this.alert_message = this.$t("alert.failure.delete");
      this.showAlert();
    },
    setSuccessAlert() {
      this.alert_icon_color = "success";
      this.alert_icon = "check-circle";
      this.alert_title = this.$t("globals.success");
      this.alert_message = this.$t("globals.data_deleted");
      this.showAlert();
    },
    setWarningAlert(message) {
      this.alert_icon_color = "warning";
      this.alert_icon = "alert";
      this.alert_title = this.$t("globals.warning");
      this.alert_message = message;
      this.showAlert();
    },
    showAlert() {
      this.show_alert = true;
      setTimeout(() => {
        this.show_alert = false;
      }, 1500);
    },
    edit(summer) {
      this.editItem(summer);
    },
    showImage(value, key) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1) this.dialog_image = value;
      else this.dialog_image = this.base_url + value;
      this.dialog_title = key;
      this.showImg_dialog = true;
    },
  },
};
</script>
<style scoped>
.v-btn-icon {
  min-width: 22px;
  height: 24px !important;
  padding: 0;
}
.fixed-header-table > thead tr th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
</style>
