<template>
  <custom-auto-complete
    :items="items"
    item-title="name_ar"
    item-value="id"
    :label="$t('branches.college')"
    icon="school"
    col="3"
  />
</template>
<script>
import { getCollege } from "@/plugins/methods/global-methods";

export default {
  props: {
    filter: {
      type: Array,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  async created() {
    if (!this.filter) {
      this.items = await getCollege();
    }
  },
  watch: {
    filter() {
      this.items = this.filter;
    },
  },
};
</script>
