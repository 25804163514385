<template>
    <v-card :title="$t('globals.qualifcations')" prepend-icon="mdi-equalizer">
      <v-card-item>
        <v-form ref="form">
        <v-col v-for="(item, key) in value.academic_qualification" :key="key">
          <h4 class="my-2">
            {{ $t('StudentData.qualifcation') + ' _ ' + (key + 1) }}
          </h4>

          <v-row>
            <drop-list
              v-model="item.fk_qualification.qualification_type"
              name="qualifcation"
              col="3"
              :rules="[$required]"
            />
            <drop-list
              v-model="item.fk_qualification.fk_documnet_type"
              name="documnet_type"
              col="3"
              :rules="[$required]"
              @update:modelValue="item.fk_document.document_type=item.fk_qualification.fk_documnet_type"
            />
            <drop-list
              v-model="item.fk_qualification.educational_level"
              name="EducationalLevelChoice"
              col="3"
              :rules="[$required]"
              @update:model-value="
                ;(item.seat_no = undefined),
                  (item.department = undefined),
                  (item.specialization = undefined)
              "
            />
          </v-row>
          <v-sheet  class="ma-0" v-if="item.fk_qualification.educational_level">
            <v-row>
              <custom-text-field
                v-model="item.fk_qualification.name_ar"
                :label="$t('accommodation.group_name_ar')"
                icon="abjad-arabic"
                col="3"
                :rules="[$required, $ar_letters_only, $max_length(100)]"
              />
              <custom-text-field
                v-model="item.fk_qualification.name_en"
                :label="$t('accommodation.group_name_en')"
                icon="alpha-e"
                col="3"
                :rules="[$en_letters_only, $max_length(100)]"
              />
              <custom-text-field
                v-if="item.fk_qualification.educational_level == 1"
                v-model="item.seat_no"
                :label="$t('globals.seating_no')"
                icon="numeric"
                col="3"
                type="number"
                :rules="[$required, $max_length(50), $numeric]"
              />

              <drop-list
                v-if="item.fk_qualification.educational_level == 1"
                v-model="item.department"
                name="department"
                col="3"
                :rules="[$required]"
              />
                 <custom-text-field
                v-model="item.college_or_school"
                :label="
                  item.fk_qualification.educational_level == 1
                    ? $t('globals.school')
                    : $t('globals.university')
                "
                icon="school-outline"
                col="3"
                :rules="[$required, $max_length(100)]"
              />
              <custom-text-field
                v-if="item.fk_qualification.educational_level != 1"
                v-model="item.specialization"
                :label="$t('branches.specialization')"
                icon="book-outline"
                col="3"
                :rules="[$required, $max_length(100)]"
              />
            </v-row>

            <v-row>
           
              <custom-text-field
                v-model="item.grade_point_average"
                :label="$t('class.avarage')"
                icon="percent-outline"
                col="3"
                type="number"
                :rules="[$required, $max_length(10)]"
              />

              <custom-text-field
                v-model="item.the_year"
                :label="$t('globals.date_obtained')"
                icon="calendar"
                col="3"
                :rules="[$required, $max_length(9)]"
              />

              
            </v-row>
          </v-sheet>
           <h4 class="my-3">
            {{ $t('globals.certificate') + ' _ ' + (key + 1) }}
          </h4>

          <v-row>
            <custom-file-input v-model="item.fk_document.document" col="3" />
            
            <custom-text-field
              v-model="item.fk_document.document_no"
              :label="$t('globals.cetificate_no')"
              icon="numeric"
              col="3"
              :rules="[$required, $max_length(50)]"
            />
          </v-row>
          <v-row>
            <datetime-picker
              v-model="item.fk_document.issue_date"
              :calendar="'gregorg'"
              placeholder="YYYY-MM-DD"
              :label="$t('document.issue_date')"
              :rules="[$required]"
              :datetime_picker="'issue_date_cetificate' + key"
              col="3"
            />

            <datetime-picker
              v-model="item.fk_document.expiration_date"
              :calendar="'gregorg'"
              placeholder="YYYY-MM-DD"
              :label="$t('employee.expiration_date')"
              :datetime_picker="'expiration_date_cetificate_' + key"
              col="3"
            />

            <custom-text-field
              v-model="item.fk_document.issue_place"
              :label="$t('student_details.release_place')"
              icon="map-marker-outline"
              col="3"
              :rules="[$required, $max_length(100)]"
            />
          </v-row>
          <v-divider class="my-2" />
        </v-col>
      </v-form>
      </v-card-item>
      <v-card-actions>
        <v-spacer />

        <v-avatar
          size="small"
          color="info"
          icon="mdi-plus"
          class="ma-1"
          @click="add"
        />
        <v-avatar
          size="small"
          color="red"
          icon="mdi-minus"
          class="ma-1"
          @click="remove"
        />
      </v-card-actions>
    </v-card>
  
</template>
<script>
export default {
  props: {
    dropList: Object,
  },
  methods: {
    async add() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.value.academic_qualification.push({
          fk_qualification: {
            fk_documnet_type: undefined,
            qualification_type: undefined,
            name_ar: undefined,
            name_en: undefined,
            educational_level: undefined,
          },
          fk_document: {
            document: undefined,
            document_no: undefined,
            document_type: undefined,
            issue_date: undefined,
            expiration_date: undefined,
            issue_place: undefined,
          },
          college_or_school: undefined,
          specialization: undefined,
          grade_point_average: undefined,
          department: undefined,
          seat_no: undefined,
          the_year: undefined,
        })
      }
    },
    remove() {
      if (this.value.academic_qualification.length > 1)
        this.value.academic_qualification.pop()
    },
  },

  computed: {
    value: {
      get() {
        return this.dropList
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
