 
 <template>
  <v-row class="px-5   ">
    <v-col cols="6" style="font-size:17px; " >{{title}} </v-col>
    <v-col cols="6" style="text-align: end; font-size:15px"  > {{subtitle}}</v-col>
  </v-row>

</template>

<script>
export default {
    name: 'ScreenHeader',
    data() {
        return {
        loading: false,
        }
    },
    props: {
        title: {
        type: String,

        },
        subtitle: {
        type: String,

        },


    },

}
</script>
