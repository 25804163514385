<template>
  <div>
    <div class="d-lg-flex d-sm-block pa-2">
      <div class="w-lg-25 w-sm-100 ">
        <slot name="header-start">
          <div class="d-flex align-center flex-column justify-center h-100">
            <div class="text-h5">
              الجمهورية العربية اليمنية  
            </div>
            <div class="text-h5">وزارة التربية والتعليم</div>
            <div class="text-subtitle-1 " style="text-align:center">
              جامعة العلوم الرقمية
            </div>
          </div>
        </slot>
      </div>
      <div class="flex mt-2 mt-lg-0 mt-md-0">
        <slot name="header-center">
          <div class="d-flex align-center flex-column">
            <div class="text-h5 d-none d-lg-block d-md-block">{{ $t("globals.in_the_name_of_allah") }}</div>
            <img :src="mainImage" alt="" class="logoW"  />
          </div>
          <slot name="header-title">
          
          </slot>
        </slot>
      </div>
      <div class="p-2 w-lg-25 w-sm-100 mt-2 mt-lg-0 mt-md-0">
        <slot name="header-end">
          <div class="d-flex align-center flex-column justify-center h-100">
            <div class="text-h5">
              Arabic Rebublic Of Yemen
            </div>
            <div class="text-h5">Prime Minister Office</div>
            <div class="text-subtitle-1 " style="text-align:center">
              Hole Quran Acadmic
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainImage: require("@/assets/images/login/logo2.png"),
    };
  },
};
</script>

<style scoped>
.flex {
  flex: 1;
}
</style>
