<template>
  <h1>{{ studentdata.full_name_ar }}</h1>
  <v-card class="pa-5">
    <v-card-title>
      <h2>{{ $t('StudentData.requerqualifcation') }}</h2>
    </v-card-title>
    <v-card-text>
      <v-table fixed-header density="compact">
        <thead>
          <tr>
            <th class="text-start">{{ $t('StudentData.qualifcation') }}</th>
            <th class="text-start">
              <v-checkbox
                v-model="check"
                color="indigo"
                :label="$t('StudentData.approved')"
                density="compact"
                hide-details
                :indeterminate="
                  !check
                    ? requireQualifications.some((item) => item.status == 1)
                    : false
                "
                @change="checkAll(check)"
              >
              </v-checkbox>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(requireQualifications, index) in requireQualifications"
            :key="index"
          >
            <td>
              {{
                requireQualifications?.required_qualification_name ||
                requireQualifications.required_qualification_4c_name
              }}
            </td>
            <td>
              <v-checkbox
                v-model="requireQualifications.status"
                color="indigo"
                density="compact"
                hide-details
                @update:model-value="allChecked()"
              >
              </v-checkbox>
            </td>
          </tr>
          <tr v-if="!requireQualifications.length" class="text-center">
            <td colspan="3">{{ $t('globals.no_data') }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
    <v-card-actions>
      <custom-btn type="save" :click="saveData" />
      <!-- <custom-btn @click="dialog = !dialog" /> -->
    </v-card-actions>
  </v-card>
</template>
<script>
import { getDataStudent } from '@/plugins/methods/global-methods'

export default {
  props: {
    perm: Object,
    id: {
      type: Number,
    },
  },
  data() {
    return {
      studentdata: [],
      requireQualifications: [],
      check: null,
    }
  },
  async created() {
    this.studentdata = !this.studentdata.length
      ? await getDataStudent(this.id)
      : this.studentdata
    await this.applyCondition()
  },
  methods: {
    async getData() {
      await this.$axios('/student-affairs/check-qualification/filter/', {
        params: {
          field: 'fk_student',
          value: this.id,
        },
      })
        .then((response) => {
          this.requireQualifications = response.data
          console.log(this.requireQualifications)
          this.allChecked()
        })
        .catch(() => {})
    },
    async applyCondition() {
      await this.$axios(
        '/student-affairs/check-qualification/save-check-qualification/',
        {
          params: {
            fk_student: this.id,
          },
        },
      )
        .then(() => {
          this.getData()
        })
        .catch(() => 'error')
    },
    async saveData() {
      //   this.dropList.data_entry = this.user;
      await this.$axios
        .put(
          `/student-affairs/check-qualification/edit_check_qualification/`,
          this.requireQualifications,
        )
        .then(() => {
          this.$emit('alert', 'add')
          this.getData()
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    checkAll(all = true) {
      this.requireQualifications.forEach((item) => {
        item.status = all
      })
    },
    allChecked() {
      this.check = this.requireQualifications.every(
        (item) => item.status == true,
      )
    },
  },
}
</script>
