<template>
  <nav-drawer
  title="أضافة مديريات"
    v-show="drawer"
    v-model="drawer"
    temporary>
  <template  v-slot:body>
    <v-form ref="dirform">  
    <v-row class="px-6 pt-5">
      <v-text-field
        v-model="dropList.name_ar"
        prepend-inner-icon="mdi-abjad-arabic"
        :label="$t('directorate.directoratename')"
        density="compact"
        :rules="[$required , $validateArabicText , $max_length(100)]"
      ></v-text-field>
    </v-row>
    <v-row class="px-6">
      <v-text-field
        v-model="dropList.name_en"
        prepend-inner-icon="mdi-alpha-e"
        :label="$t('directorate.directorateEname')"
        density="compact"
        :rules="[$validateEnglishText , $max_length(100)]"
      ></v-text-field>
    </v-row>
    <v-row class="px-6">
    </v-row>
    <v-row class="pa-3 justify-center">
      <custom-btn v-if="!is_update" type="save" col="5" :click="saveData"  />
      <custom-btn v-if="is_update" type="update" col="5" :click="updateData"  />
      <custom-btn
        label="الغاء"
        col="6"
        color="white"
        text_color="black"
        @click.stop="drawer = !drawer"
      />
    </v-row>
    </v-form> 
  </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    style="z-index: auto"
    :perm="{}"
    exports
    :create="()=>opendailog()"
  >
    <template v-slot:item="{item,key}">
        <v-span v-if="key=='regin'" style="display:block; width:150px">
              <v-icon style="color: #3487f6" class="me-2"     @click=" $router.push({
              name: 'RegionsView',
              params: { id: item.id  },
            })">mdi-eye-outline</v-icon>
        </v-span>
  </template>
  <!--  -->
  </custom-data-table-2>
</template>
<script>
export default {
    props:{
    perm:Object,
        id: {
        type: Number,
        },
    },
  data() {
    return {
      items: [],
      pagination: undefined,
      dropList: {},
      is_update: false,
      updated_id: undefined,
      drawer: null,
      update_id: undefined,
      headers: [
        { title: this.$t('directorate.directoratename'), key: 'name_ar' },
        { title: this.$t('directorate.directorateEname'), key: 'name_en' },
        { title: this.$t('regin.regin'), key: 'regin' },
        { title: this.$t('globals.actions'), key: 'actions' },
      ],
    }
  },
  methods: {
  opendailog() {
      this.drawer = true
      this.is_update = false
      this.resetForm()
    },
        resetForm() {
      this.$refs.dirform.reset()    
      this.dropList={}
      this.is_update = false
      this.updated_id = undefined
    },
    async saveData() {
      const { valid } = await this.$refs.dirform.validate();
        if (valid) {
          this.dropList.fk_governorate=this.$route.params.fk_governorate;
          this.dropList.country=this.$route.params.country;
          this.dropList.data_entry = this.user;
          await this.$axios
            .post('common/directorate-data/', this.dropList)
            .then(() => {
              this.$emit("successAlert", this.$t("globals.data_added"));
              this.resetForm();
              this.drawer =!this.drawer
              this.getData();
            })
            .catch(() => {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
            });
        }
      },
  async updateData() {
      const { valid } = await this.$refs.dirform.validate();
      if (valid) {
        this.dropList.data_entry = this.user;
      await this.$axios
          .put(
            this.base_url + "common/directorate-data/" + this.update_id +'/',
            this.dropList,
          )
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.resetForm();
            this.drawer =!this.drawer
        this.getData();
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("alert.failure.title"));
          });
      }
    },
  editData(data, isPermission = false) {
        this.update_id = data.id
        if (!isPermission) {
          this.drawer =true
          this.dropList.country = 1
          this.dropList.fk_governorate = data.fk_governorate
          this.dropList.name_ar = data.name_ar
          this.dropList.name_en = data.name_en
          this.is_update = true
        } else {
          return true
        }
      },
      async delData(delete_id, isPermission = false) {
      if (!isPermission) {
        if (delete_id != undefined) {
          var status
          await this.$axios
            .delete('common/directorate-data/' + delete_id +'/')
            .then(() => {
              status = true
            })
            .catch((error) => {
              status = error
            })
          return status
        }
      } else {
        return true
      }
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      if (this.$route.params.id) {
        await this.$axios('common/directorate-data/filter/', {
          params: {
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
          field:'fk_governorate',
          value: this.$route.params.id,
        },
      })
        .then((response) => {
          this.items = response.data
          this.pagination = response.data.pagination
        })
        .catch(() => {})
      }
    },
  },
}
</script>
<style scoped>
.v-navdra {
  z-index: 1;
  transform: translateX(0%) !important;
  position: fixed !important;
  left: 0px !important;
  height: calc((100% - 0px) - 0px) !important;
  top: 0px !important;
  bottom: 0px !important;
  box-shadow: 0 10px 6px rgba(0, 0, 0, 0.16) !important;
  width: 270px !important;
}
</style>