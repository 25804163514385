<template>
  <template v-for="(item, index) in getParent(0)" :key="index">

    <no-children v-if="getParent(item.id).length == 0" :item="item" :css_class="'sidebar-title'" />
    <template v-else>
      <with-children :item="item" :css_class="'sidebar-title'">
        <template v-slot:childItem>
          <template
            v-for="(child, childIndex) in getParent(item.id)"
            :key="childIndex"
          >
            <no-children v-if="getParent(child.id).length == 0" :item="child" :css_class="'sidebar-subtitle'" />
            <with-children v-else :item="child" :css_class="'sidebar-subtitle'" >
              <template v-slot:childItem>
                <template
                  v-for="(subChild, subShildIndex) in getParent(child.id)"
                  :key="subShildIndex"
                >
                  <no-children
                    :css_class="'sidebar-mini-subtitle'"
                    v-if="getParent(subChild.id).length == 0"
                    :item="subChild"/>
                  <with-children v-else :item="subChild"  :css_class="'sidebar-mini-subtitle'">
                    <template v-slot:childItem>
                      <template
                        v-for="(subSubChild, subSubShildIndex) in getParent(
                          subChild.id
                        )"
                        :key="subSubShildIndex"
                      >
                        <no-children :item="subSubChild" />
                      </template>
                    </template>
                  </with-children>
                </template>
              </template>
            </with-children>
          </template>
        </template>
      </with-children>
    </template>
  </template>
</template>
 <script>
import NoChildren from './NoChildren.vue'
import withChildren from './withChildren.vue'

export default {
  name: 'ItemsSideBar',
  components: { NoChildren, withChildren },

  props: {
    items: {
      type: Array,
    },
  },
  created() {},
  methods: {
    getParent(id) {
      return this.items?.filter((item) => {
        return item.parent === id
      })??[]
    },
  },
}
</script>
 