<template>
  <h1>{{ student_data.full_name_ar }}</h1>
  <v-card class="pa-5">
    <v-card-title class="d-flex">
      <h2>{{ $t('StudentData.requercondetion') }}</h2>
      <v-spacer></v-spacer>
      <custom-btn type="save" :click="saveData" :disabled="disabled" />
    </v-card-title>
    <v-card-text>
      <v-table fixed-header density="compact">
        <thead>
          <tr>
            <th class="text-start">{{ $t('StudentData.condition') }}</th>
            <th class="text-start">
              <v-checkbox
                v-model="check"
                color="indigo"
                :label="$t('StudentData.select')"
                density="compact"
                hide-details
                :indeterminate="
                  !check
                    ? requireConditions.some((item) => item.status == 1)
                    : false
                "
                @change="checkAll(check)"
              >
              </v-checkbox>
            </th>
            <th>{{ $t('StudentData.state') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(requireCondition, index) in requireConditions"
            :key="index"
          >
            <td style="max-width: 300px">
              {{
                requireCondition?.required_condition_name ||
                requireCondition.required_condition_4c_name
              }}
            </td>
            <td>
              <v-checkbox
                v-model="requireCondition.checked"
                color="indigo"
                density="compact"
                hide-details
                @update:model-value="
                  requireCondition.checked
                    ? (requireCondition.status = 1)
                    : (requireCondition.status = 2),
                    allChecked(),
                    (disabled = false)
                "
              >
              </v-checkbox>
            </td>
            <td>
              <!-- :disabled="requireCondition.checked" -->
              <list-check-condition-status
                v-model="requireCondition.status"
                @update:model-value="
                  requireCondition.status == 1
                    ? (requireCondition.checked = true)
                    : (requireCondition.checked = false)
                "
                style="width: 250px"
                :icon="
                  requireCondition.status == 1
                    ? 'text-box-check-outline'
                    : requireCondition.status == 2
                      ? 'text-box-remove-outline'
                      : requireCondition.status == 3
                        ? 'text-box-search-outline'
                        : ''
                "
              />
            </td>
          </tr>
          <tr v-if="!requireConditions.length" class="text-center">
            <td colspan="3">{{ $t('globals.no_data') }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { getDataStudent } from '@/plugins/methods/global-methods'

export default {
  props: {
    perm: Object,
    id: {
      type: Number,
    },
  },
  data() {
    return {
      student_data: [],
      requireConditions: [],
      conditionStatus: [],
      check: null,
      disabled: true,
    }
  },
  async created() {
    this.student_data = !this.student_data.length
      ? await getDataStudent(this.id)
      : this.student_data
    await this.applyCondition()
  },
  methods: {
    async getData() {
      await this.$axios('/student-affairs/check-condition/filter/', {
        params: {
          field: 'fk_student',
          value: this.id,
        },
      })
        .then((response) => {
          this.requireConditions = response.data.map((item) => ({
            ...item,
            checked: item.status == 1 ? true : false,
          }))

          this.allChecked()
        })
        .catch(() => {})
    },
    async applyCondition() {
      await this.$axios(
        '/student-affairs/check-condition/save-check-condition/',
        {
          params: {
            fk_student: this.id,
          },
        },
      )
        .then(() => {
          this.getData()
        })
        .catch(() => 'error')
    },
    async saveData() {
      //   this.dropList.data_entry = this.user;
      await this.$axios
        .put(
          `/student-affairs/check-condition/edit_check_condition/`,
          this.requireConditions,
        )
        .then(() => {
          this.$emit('alert', 'add')
          this.getData()
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    checkAll(all = true) {
      this.requireConditions.forEach((item) => {
        ;(item.status = all ? 1 : 2), (item.checked = all)
      })
    },
    allChecked() {
      this.check = this.requireConditions.every((item) => item.status == 1)
    },
  },
}
</script>
