<template>
  <custom-auto-complete
    :items="item"
    :placeholder="$t('List.sections_placeholder')"
    item-title="name_ar"
    item-value="id"
    :label="$t('list.sections')"
    icon="chart-pie"
    :col="col"
    :rules="rules"
  />
</template>
<script>

export default {
  props: {
    col: {
      type: Number,
      default() {
        return 12
      },
    },
    rules: {
      type: Object,
    },
  },
  name: 'ListSection',
  data() {
    return {
      item: [],
    }
  },
  async created() {
       await this.$axios.get('/system-management/sections/all/').then((e) => this.item = e.data,)
  },
}
</script>
