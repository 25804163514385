<template>
  <custom-auto-complete
    :items="item"
    :placeholder="$t('List.lecture_type_placeholder')"
    item-title="name"
    item-value="id"
    :label="$t('list.lecture_type')"
    icon="file-document"
    :col="col"
    :rules="rules"
  />
</template>
<script>
export default {
  props: {
    col: {
      type: Number,
      default() {
        return 12
      },
    },
    rules: {
      type: Object,
    },
  },
  name: 'ListSemester',
  data() {
    return {
      item: [],
    }
  },
  async created() {
    await this.$axios
      .get('/choices/choice-data/', {
        params: {
          param: 'SubjectTypeChoice',
        },
      })
      .then((e) => (this.item = e.data))
      console.log(this.item);
  }
}
</script>
