<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel title="kdufghgfdkjfhg">
        <v-expansion-panel-text>
          <v-Checkbox
            v-for="item in items"
            :key="item"
              
            :label="item?.name || item.name_ar"
            :value="item.id"
            hide-details
            density="compact"
          ></v-Checkbox>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  name: 'CustomDropList',
  props: {
    modelValue: { type: [Number, String], default: null },
    items: { type: Array },
    label: { type: String },
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
