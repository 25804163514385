const ValuePermissionPlugin = {
    install(Vue) {
        // const role_list=['sys_admin','gen_admin','gov_admin','dir_admin','summer_admin','user']

        // Required field validation
        /* eslint-disable */
        Vue.config.globalProperties.$checkPermission=(perm,role=[])=>{

            // perm + role;
        //     if((role.includes(role_list[localStorage.getItem("role")]) || role.length == 0) &&
        //     localStorage.getItem('userinfo').includes(perm))
        //     return true
        //   else
      return true
         };
    }
}
export default ValuePermissionPlugin;