<template>
  <custom-auto-complete
    :items="list"
    item-title="name"
    item-value="id"
    :label="$t('globals.gender')"
    icon="gender-male-female"
    :col="col"
    :rules="rules"
    density="compact"
  />
</template>
<script>
export default {
  props: {
    col: {
      type: Number,
      default() {
        return 3;
      },
    },
    rules: {
      type: Object,
    },
  },
  name: "ListGanders",
  data() {
    return {
      list: [],
    };
  },
  async created() {
    await this.$axios("choices/choice-data/", {
      params: {
        param: "GenderChoice",
      },
    })
      .then((response) => {
        this.list = response.data;
      })
      .catch(() => {});
  },
};
</script>
