<template>
   <v-list-item
    :prepend-icon="item.icon ? 'mdi-' + item.icon : 'mdi-minus'"
    :title="$i18n.locale == 'ar' ? item.name_ar : item.name_en"
    :value="$i18n.locale == 'ar' ? item.name_ar : item.name_en"
    min-height="30"
    density="compact"
    :to="item.path? { name: item.path}:''"    
    >
    <template v-slot:title>
      <span :class="css_class">{{$i18n.locale == 'ar' ? item.name_ar : item.name_en}}</span>
    </template>
   </v-list-item>
 </template>
    <!-- v-if="(item.role.length != 0 ? CheckGetPermission('',item.role) : false)" -->
<script>
export default {
  props: {
    item: {
      type: Object,
    },
    css_class:{
      type:String
    }
  },
  methods:{

  },
  
  computed: {
      CheckGetPermission() {
      return (prem,role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
};
</script>


