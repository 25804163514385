<template>
  <custom-auto-complete
    :items="getGov"
    item-title="name_ar"
    item-value="id"
    :label="$t('governorate.governoratename')"
    icon="city"
    col="3"
  />
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
    },
    country:{
      type:undefined,
      
    },
  },
  data() {
    return {
      governorate: [],
    }
  },
  created() {
    this.$axios('common/governorate-data/all/').then((e) => {
      this.governorate = e.data
    })
  },
  methods: {},
  computed: {
    getGov() {
      return this.governorate.filter((item) => item.fk_country == this.country)
    },
  },
}
</script>