<template>
  <v-card color="cardcolor">
    <v-card-text>
      <v-form ref="form">
        <v-tabs v-model="tab" class="mb-4">
          <v-tab value="one">{{ $t("employee.personal_data") }} </v-tab>
          <v-tab value="two">{{ $t("employee.address_data") }} </v-tab>
          <v-tab value="three">{{ $t("employee.ssn") }} </v-tab>
          <v-tab value="four">{{ $t("employee.employee_data") }} </v-tab>
          <v-tab value="five">{{ $t("user_management.user_data") }} </v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item value="one">
            <v-row>
              <v-col cols="12" md="9">
                <v-row>
                  <custom-text-field
                    v-model="user_info.first_name"
                    icon="abjad-arabic"
                    :label="$t('user.fristname')"
                    :rules="[
                      $required,
                      $max_length(30),
                      $min_length(3),
                      $ar_letters_only,
                    ]"
                    :col="4"
                    clearable
                  />
                  <custom-text-field
                    v-model="user_info.last_name"
                    icon="abjad-arabic"
                    :label="$t('user.secondname')"
                    :rules="[
                      $required,
                      $max_length(30),
                      $min_length(3),
                      $ar_letters_only,
                    ]"
                    :col="4"
                    clearable
                  />

                  <DatetimePicker
                    col="4"
                    v-model="user_info.date_of_birth"
                    :label="$t('user.dataofbirth')"
                    datetime_picker="date_of_birth"
                    :rules="[$required]"
                    clearable
                    density="compact"
                  ></DatetimePicker>

                  <custom-text-field
                    v-model="user_info.mobile_no"
                    icon="cellphone-text"
                    :label="$t('user.mobilenumber')"
                    :rules="[$required, $max_length(15), $min_length(9), $numeric]"
                    :col="6"
                    clearable
                  />
                  <custom-text-field
                    v-model="user_info.phone_no"
                    icon="phone"
                    :label="$t('user.phonenumber')"
                    :rules="[$max_length(15), $min_length(9), $numeric]"
                    :col="6"
                    clearable
                  />
                  <drop-list
                    v-model="user_info.gender"
                    name="gendar"
                    :rules="[$required]"
                    :col="4"
                    clearable
                  />
                  <drop-list
                    v-model="user_info.fk_nationality"
                    name="nationalty"
                    :rules="[$required]"
                    :col="4"
                    clearable
                  />
                  <drop-list
                    name="country"
                    v-model="fk_place_of_brith.country"
                    @update:modelValue="
                      (fk_place_of_brith.fk_governorate = undefined),
                        (fk_place_of_brith.fk_directorate = undefined)
                    "
                    col="4"
                    :rules="[$required]"
                  />
                  <drop-list
                    name="gov_for_country"
                    v-model="fk_place_of_brith.fk_governorate"
                    :param="fk_place_of_brith.country"
                    @update:modelValue="fk_place_of_brith.fk_directorate = undefined"
                    col="4"
                    :rules="[$required]"
                  />
                  <drop-list
                    name="dir_for_gov"
                    v-model="fk_place_of_brith.fk_directorate"
                    :param="fk_place_of_brith.fk_governorate"
                    col="4"
                    :rules="[$required]"
                  />
                  <drop-list
                    v-model="fk_place_of_brith.fk_region"
                    col="4"
                    name="region_by_dir"
                    :param="fk_place_of_brith.fk_directorate"
                    :rules="[$required]"
                  ></drop-list>
                  <!-- <custom-text-field
                    v-model="fk_place_of_brith.name_ar"
                    icon="map-marker-path"
                    :label="$t('employee.region_name_ar')"
                    :rules="[$required, $ar_letters_only, $max_length(100)]"
                    :col="4"
                    clearable
                  /> -->

                  <!-- <custom-text-field
                    v-model="fk_place_of_brith.name_en"
                    icon="map-marker-path"
                    :label="$t('employee.region_name_en')"
                    :rules="[$en_letters_only, $max_length(100)]"
                    :col="4"
                    clearable
                  /> -->
                  <custom-text-field
                    v-model="fk_place_of_brith.street"
                    icon="traffic-light-outline"
                    :label="$t('buildings.street')"
                    :rules="[$required, $ar_letters_only, $max_length(100)]"
                    :col="4"
                    clearable
                  />
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-label class="text-center pa-2">
                  {{ this.$t("employee.image") }}
                </v-label>
                <ImageInput
                  :reset="reset_image_user"
                  :image="user_info.image_user"
                  :text="$t('employee.image')"
                  @ImageSelected="saveUserImage"
                  @resetRequest="imageResetUserImage"
                ></ImageInput>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item value="two">
            <v-row class="mt-0">
              <v-col cols="12" md="10">
                <v-row>
                  <drop-list
                    name="country"
                    v-model="address_info.country"
                    @update:modelValue="
                      (address_info.fk_governorate = undefined),
                        (address_info.fk_directorate = undefined)
                    "
                    col="4"
                    :rules="[$required]"
                  />
                  <drop-list
                    name="gov_for_country"
                    v-model="address_info.fk_governorate"
                    :param="address_info.country"
                    @update:modelValue="address_info.fk_directorate = undefined"
                    col="4"
                    :rules="[$required]"
                  />
                  <drop-list
                    name="dir_for_gov"
                    v-model="address_info.fk_directorate"
                    :param="address_info.fk_governorate"
                    col="4"
                    :rules="[$required]"
                  />
                  <drop-list
                    v-model="address_info.fk_region"
                    col="4"
                    name="region_by_dir"
                    :param="address_info.fk_directorate"
                    :rules="[$required]"
                  ></drop-list>
                  <!-- <custom-text-field
                    v-model="address_info.name_ar"
                    icon="map-marker-path"
                    :label="$t('employee.region_name_ar')"
                    :rules="[$required, $ar_letters_only, $max_length(100)]"
                    :col="4"
                    clearable
                  />
                  <custom-text-field
                    v-model="address_info.name_en"
                    icon="map-marker-path"
                    :label="$t('employee.region_name_en')"
                    :rules="[$en_letters_only, $max_length(100)]"
                    :col="4"
                    clearable
                  /> -->
                  <custom-text-field
                    v-model="address_info.street"
                    icon="traffic-light-outline"
                    :label="$t('buildings.street')"
                    :rules="[$required, $ar_letters_only, $max_length(100)]"
                    :col="4"
                    clearable
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item value="three">
            <v-row>
              <v-col cols="12" md="9">
                <v-row>
                  <custom-text-field
                    v-model="ssn.document_no"
                    icon="numeric"
                    :label="$t('employee.document_no')"
                    :rules="[$required, $max_length(20), $numeric]"
                    :col="4"
                    clearable
                  />
                  <custom-text-field
                    v-model="ssn.issue_place"
                    icon="map"
                    :label="$t('employee.issue_place')"
                    :rules="[$required, $max_length(20), $ar_letters_only]"
                    :col="4"
                    clearable
                  />
                  <drop-list
                    name="document_type"
                    v-model="ssn.document_type"
                    :rules="[$required, $max_length(50), $numeric]"
                    col="4"
                  />

                  <DatetimePicker
                    col="6"
                    v-model="ssn.issue_date"
                    :label="$t('employee.issue_date')"
                    datetime_picker="issue_date"
                    clearable
                    :rules="[$required]"
                  ></DatetimePicker>

                  <DatetimePicker
                    v-model="ssn.expiration_date"
                    :label="$t('employee.expiration_date')"
                    datetime_picker="expiration_date"
                    clearable
                    :rules="[$required]"
                    col="6"
                  ></DatetimePicker>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-label class="text-center pa-2">
                  {{ this.$t("employee.document") }}
                </v-label>
                <ImageInput
                  :reset="reset_document"
                  :image="ssn.document"
                  :text="$t('employee.document')"
                  @ImageSelected="saveDocument"
                  @resetRequest="imageResetDocument"
                ></ImageInput>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item value="four">
            <v-row>
              <v-col cols="12" md="10">
                <v-row>
                  <drop-list
                    v-model="employee_info.fk_branch"
                    name="branches"
                    :rules="[$required]"
                    :col="4"
                    clearable
                  />
                  <drop-list
                    v-model="employee_info.fk_college"
                    name="collage_for_branch"
                    :param="employee_info.fk_branch"
                    :rules="[$required]"
                    :col="4"
                    clearable
                  />

                  <custom-text-field
                    v-model="employee_info.Employment_contract_no"
                    icon="numeric"
                    :label="$t('employee.employment_contract_no')"
                    :rules="[$required, $max_length(10), $numeric]"
                    :col="4"
                    clearable
                  />
                  <custom-text-field
                    v-model="employee_info.jop_place"
                    icon="map"
                    :label="$t('employee.jop_place')"
                    :rules="[
                      $required,
                      $max_length(150),
                      $min_length(2),
                      $ar_letters_only,
                    ]"
                    :col="4"
                    clearable
                  />
                  <custom-text-field
                    v-model="employee_info.type"
                    icon="format-list-bulleted-type"
                    :label="$t('employee.type')"
                    :rules="[
                      $required,
                      $max_length(150),
                      $min_length(2),
                      $ar_letters_only,
                    ]"
                    :col="4"
                    clearable
                  />

                  <DatetimePicker
                    col="4"
                    v-model="employee_info.from_date"
                    :label="$t('employee.from_date')"
                    datetime_picker="from_date"
                    clearable
                    :rules="[$required]"
                  ></DatetimePicker>

                  <DatetimePicker
                    col="4"
                    v-model="employee_info.to_date"
                    :label="$t('employee.to_date')"
                    datetime_picker="to_date"
                    clearable
                    :rules="[$required]"
                  ></DatetimePicker>

                  <drop-list
                    v-model="employee_info.jop_system"
                    name="jop_system"
                    :rules="[$required]"
                    :col="4"
                    clearable
                  />
                  <drop-list
                    v-model="employee_info.status"
                    name="employee_status"
                    :rules="[$required]"
                    :col="4"
                    clearable
                  />
                  <!-- <v-col cols="12" md="4">
                    <v-autocomplete
                      v-model="user_info.user_type"
                      :items="userTypeList"
                      item-title="name"
                      item-value="id"
                      :label="$t('user.user_type')"
                      hide-details="auto"
                      clearable
                      density="compact"
                      prepend-inner-icon="mdi-account"
                      :rules="[$required]"
                    />
                  </v-col> -->
                  <v-col cols="4">
                    <v-autocomplete
                      multiple
                      v-model="employee_info.subjects"
                      :items="listSubjects"
                      item-title="name_ar"
                      item-value="id"
                      hide-details="auto"
                      clearable
                      :label="$t('employee.subjects')"
                      density="compact"
                      prepend-inner-icon="mdi-bookshelf"
                      :rules="[$required]"
                      :loading="subject_list_loading"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item value="five">
            <v-row>
              <v-col cols="12" md="10">
                <v-row>
                  <custom-text-field
                    v-model="user_info.username"
                    icon="alpha-e"
                    :label="$t('user.username')"
                    :rules="[$required, $max_length(40), $min_length(2)]"
                    :col="6"
                    clearable
                  />
                  <custom-text-field
                    v-model="user_info.email"
                    icon="email"
                    :label="$t('user.email')"
                    :rules="[$max_length(150), $email]"
                    :col="6"
                    clearable
                  />
                  <custom-text-field
                    v-model="user_info.password"
                    icon="lock"
                    :label="$t('user.password')"
                    type="password"
                    :rules="[
                      is_update ? null : $required,
                      $max_length(16),
                      $min_length(8),
                    ]"
                    :col="4"
                    clearable
                  />
                  <custom-text-field
                    v-model="user_info.confirm_password"
                    icon="lock"
                    :label="$t('user.confirm_password')"
                    type="password"
                    :rules="[
                      match,
                      is_update ? null : $required,
                      $max_length(16),
                      $min_length(8),
                    ]"
                    :col="4"
                    clearable
                  />

                  <v-col cols="4">
                    <v-autocomplete
                      v-model="user_info.system_type"
                      :items="listSystems"
                      item-title="name"
                      item-value="id"
                      hide-details="auto"
                      clearable
                      :label="$t('user.system_type')"
                      density="compact"
                      prepend-inner-icon="mdi-list-status"
                      multiple
                      :rules="[$required]"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      multiple
                      v-model="user_info.groups"
                      :items="listGroups"
                      item-title="name"
                      item-value="pk"
                      hide-details="auto"
                      clearable
                      :label="$t('user.group')"
                      density="compact"
                      prepend-inner-icon="mdi-account-group"
                      :rules="[$required]"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox
                      v-model="user_info.is_active"
                      color="primary"
                      :label="$t('user.active')"
                      density="compact"
                      hide-details
                    ></v-checkbox
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <custom-btn
        :type="is_update ? 'update' : 'save'"
        class="ml-2"
        @click="is_update ? updateObject() : saveObject()"
      ></custom-btn>
      <custom-btn
        color="white"
        text_color="black"
        icon_color="black"
        type="cancel"
        @click="resetForm"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
import { getSubjects, getSystemTypeChoice } from "@/plugins/methods/global-methods";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      reset_document: 0,
      reset_image_user: 0,
      userTypeList: [
        { id: 2, name: this.$t("employee.employee") },
        { id: 3, name: this.$t("employee.teacher") },
      ],
      listSubjects: [],
      subject_list_loading: false,
      is_update: false,
      tab: null,
      user_info: {},
      fk_place_of_brith: {},
      employee_info: {},
      address_info: {},
      ssn: {},
      documents: [{}],
      listSystems: [],
      listGroups: [],
      match: (value) =>
        value == this.user_info.password ||
        this.$t("globals.confirm_password_match_error"),
    };
  },
  async created() {
    this.listSubjects = await getSubjects();
    this.listSystems = await getSystemTypeChoice();
    if (this.id) {
      this.is_update = true;
      await this.getEmployeeDataById(this.id);
    }
    await this.$axios("users/groups/")
      .then((response) => {
        this.listGroups = response.data;
      })
      .catch(() => {});
  },
  methods: {
    saveUserImage(event) {
      this.user_info.image_user = event;
    },
    imageResetUserImage(event) {
      this.reset_image_user = event;
    },
    saveDocument(event) {
      this.ssn.document = event;
    },
    imageResetDocument(event) {
      this.reset_document = event;
    },
    async getEmployeeDataById(id) {
      await this.$axios(`employee-affairs/employee-data/${id}/`)
        .then((response) => {
          this.employee_info = response.data;
          this.user_info = {
            ...response.data?.user,
            image_user: response.data?.user.image_user
              ? this.base_url + response.data?.user.image_user
              : null,
          };
          this.fk_place_of_brith = {
            ...response.data?.user?.fk_place_of_brith,
            country: response.data?.user?.fk_place_of_brith.fk_country,
            name_ar: response.data?.user?.fk_place_of_brith.region_name,
            name_en: response.data?.user?.fk_place_of_brith.region_name_en,
          };
          this.address_info = {
            ...response.data?.user?.fk_address,
            country: response.data?.user?.fk_address.fk_country,
            // name_ar: response.data?.user?.fk_address.region_name,
            // name_en: response.data?.user?.fk_address.region_name_en,
          };
          this.ssn = {
            ...response.data?.user?.fk_ssn_archive,
            document: response.data.user?.fk_ssn_archive.document
              ? this.base_url + response.data.user?.fk_ssn_archive.document
              : null,
          };
          if (response.data.user.system_type)
            this.user_info.system_type = response.data.user.system_type.map((number) =>
              parseInt(number)
            );
        })
        .catch(() => {
          this.$emit("warningAlert", this.$t("alert.failure.not_found"));
        });
    },
    resetForm() {
      this.is_update = false;
      this.$refs.form.reset();
      this.user_info = {};
      this.fk_place_of_brith = {};
      this.employee_info = {};
      this.address_info = {};
      this.ssn = {};
      this.reset_document = true;
      this.reset_image_user = true;
      this.user_info.is_active = false;
      if (this.id != undefined) this.$router.push({ name: "Employee" });
    },
    async saveObject() {
      const currentURL = window.location.href;
      let type_object;
      if (currentURL.includes("create-employee")) {
        type_object = { user_type_in_form: true };
      } else {
        type_object = { user_type_is: true };
      }
      
      const { valid } = await this.$refs.form.validate();
      if (
        valid &&
        this.user_info.first_name &&
        this.fk_place_of_brith.country &&
        this.employee_info.fk_branch &&
        this.address_info.country &&
        this.ssn.document_no &&
        this.user_info.username
      ) {
        this.$axios
          .post(
            `employee-affairs/employee-data/`,
            {
              user_info: {
                ...this.user_info,
                fk_place_of_brith: this.fk_place_of_brith,
                ...type_object
              },
              employee_info: {
                ...this.employee_info,
                ssn: this.ssn,
              },
              address_info: this.address_info,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
          })
          .catch((error) => {
            try {
              if (error.response.data.email != undefined)
                this.$emit("errorAlert", this.$t("globals.user_already_exists"));
              else if (error.response.data.username != undefined)
                this.$emit("errorAlert", this.$t("user.username-unique"));
              else if (error.response.data.document)
                this.$emit("errorAlert", this.$t("employee.document_error"));
              else if (
                error.response.data.non_field_errors &&
                error.response.data.non_field_errors[0].includes("document_no")
              )
                this.$emit("errorAlert", this.$t("employee.document_no_error"));
              else if (
                error.response.data.Employment_contract_no &&
                error.response.data.Employment_contract_no[0].includes(
                  "Employment contract no"
                )
              )
                this.$emit(
                  "errorAlert",
                  this.$t("employee.employment_contract_no_error")
                );
              else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } catch {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
      } else {
        if (valid && !this.user_info.first_name) {
          this.tab = 0;
        } else if (valid && !this.address_info.country) {
          this.tab = 1;
        } else if (valid && !this.ssn.document_no) {
          this.tab = 2;
        } else if (valid && !this.employee_info.fk_branch) {
          this.tab = 3;
        } else if (valid && !this.user_info.username) {
          this.tab = 4;
        }
      }
    },
    async updateObject() {
      const currentURL = window.location.href;
      let type_object = {};
      if (currentURL.includes("edit-employee")) {
        type_object = { user_type_in_form: true };
      } else {
        type_object = { user_type_is: true };
      }
      const { valid } = await this.$refs.form.validate();
      if (
        valid &&
        this.id != undefined &&
        this.user_info.first_name &&
        this.fk_place_of_brith.country &&
        this.employee_info.fk_branch &&
        this.address_info.country &&
        this.ssn.document_no &&
        this.user_info.username
      ) {
        await this.$axios
          .put(
            `employee-affairs/employee-data/${this.id}/`,
            {
              user_info: {
                ...this.user_info,
                fk_place_of_brith: this.fk_place_of_brith,
                ...type_object
              },
              employee_info: {
                ...this.employee_info,
                ssn: this.ssn,
              },
              address_info: this.address_info,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            setTimeout(() => {
              this.$router.push({ name: "Employee" });
            }, 1500);
          })
          .catch((error) => {
            try {
              if (error.response.data.email != undefined)
                this.$emit("errorAlert", this.$t("globals.user_already_exists"));
              else if (error.response.data.username != undefined)
                this.$emit("errorAlert", this.$t("user.username-unique"));
              else if (error.response.data.document)
                this.$emit("errorAlert", this.$t("employee.document_error"));
              else if (
                error.response.data.non_field_errors &&
                error.response.data.non_field_errors[0].includes("document_no")
              )
                this.$emit("errorAlert", this.$t("employee.document_no_error"));
              else if (
                error.response.data.Employment_contract_no &&
                error.response.data.Employment_contract_no[0].includes(
                  "Employment contract no"
                )
              )
                this.$emit(
                  "errorAlert",
                  this.$t("employee.employment_contract_no_error")
                );
              else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } catch {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
      } else {
        if (valid && !this.user_info.first_name) {
          this.tab = 0;
        } else if (valid && !this.address_info.country) {
          this.tab = 1;
        } else if (valid && !this.ssn.document_no) {
          this.tab = 2;
        } else if (valid && !this.employee_info.fk_branch) {
          this.tab = 3;
        } else if (valid && !this.user_info.username) {
          this.tab = 4;
        }
      }
    },
  },
};
</script>

<style scoped>
.costombtn {
  background-color: #2196f3;
  color: #fff;
}
.img {
  object-fit: cover !important;
}
</style>
