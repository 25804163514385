<template>
  <custom-auto-complete
    :items="item"
    item-title="name"
    item-value="id"
    :label="$t('summer.supervision-type')"
    icon="text"
    :col="col"
     />
</template>
<script>
export default {
  props:{
    col:{
      type:Number,
      default(){
        return 3;
      }
    }

  },
  data() {
    return {
      item: [],
 
    }
  },
  created() {
      this.$axios('api/summer/choices/super-visor').then((e)=>{
        this.item =e.data;
    })
  },
 

}
</script>