<template>
  <custom-auto-complete
    :items="condtions"
    item-title="name_ar"
    item-value="id"
    :label="$t('list.condations')"
    :col="col"
    :rules="rules"
     />
</template>
<script>
export default {
  props:{
    col:{
      type:Number,
      default(){
        return 3;
      }
    },
    rules:{
      type:Object,
       
    },
  },

  data() {
    return {
      condtions: [],
 
    }
  },
  created() {
      this.$axios('system-management/condition-data/all/').then((e)=>{
        this.condtions =e.data;
    })
  },
 

}
</script>