<template>
<nav-drawer 
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <v-row class="px-3 pt-5">
          <drop-list name="SemesterPeriodChoice" v-model="dropList.type_of_period"/>
           <datetime-picker
                    v-model="dropList.start_date"
                    :calendar="'gregorg'"
                    placeholder="YYYY-MM-DD"
                    :label="$t('globals.start_date')"
                    datetime_picker="start-data"

                  />
           <datetime-picker
                    v-model="dropList.end_date"
                    :calendar="'gregorg'"
                    placeholder="YYYY-MM-DD"
                    :label="$t('globals.end_date')"
                    datetime_picker="end-date"

                  />
        </v-row>
      </v-form>
    </template>
     <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click="resetForm, (drawer = !drawer)" />
    </template>
  </nav-drawer>

  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :perm="{}"
    :create="()=>resetForm()"
    exports
  />
 
</template>
<script>
export default {
  props: {
    perm: Object,
    fk_semester:Number,
  },
  data() {
    return {
      dropList:{},
      items: [],
      update_id:undefined,
      pagination: undefined,
      college_data: {},
      drawer: false,
      headers: [
        {title: this.$t('globals.type_of_period'), key: 'type_of_period',},
        {title: this.$t('globals.type_of_period_name'), key: 'type_of_period_name',},
        { title: this.$t('globals.start_date'), key: 'start_date' },
        { title: this.$t('globals.end_date'), key: 'end_date' },
        { title: this.$t('globals.actions'), key: 'actions', sortable: false },
      ],
    }
  },

  methods: {
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('/academic-affairs/semester-period/filter-paginate/', {
        params: {
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
          value: this.fk_semester,
          field: 'fk_semester_4b',
        },
      }).then((response) => {
          this.items = response.data.results

          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.dropList.fk_semester_4b = this.fk_semester
        
        await this.$axios
          .post('/academic-affairs/semester-period/', this.dropList)
          .then(() => {
            this.$emit('successAlert', this.$t('globals.data_added'))
            this.resetForm()
            this.getData()
          })
          .catch((error) => {
              this.$emit('errorAlert', this.$t('globals.error_in_data'))
            
          })
      }
    },
    editData(data) {
      this.resetForm()
      this.update_id = data.id
      this.dropList = { ...data }
      this.drawer = true
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .put(
            this.base_url +
              '/academic-affairs/semester-period/' +
              this.dropList.id +
              '/',
            this.dropList,
          )
          .then(() => {
            this.$emit(
              'successAlert',
              this.$t('summer_center.change_activitytype'),
            )
            this.resetForm()

            this.getData()
          })
          .catch(() => {
            this.$emit('errorAlert', this.$t('alert.failure.title'))
          })
      }
    },
      async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('/academic-affairs/semester-period/' + delete_id + '/')
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    resetForm() {
      this.dropList = {}
      this.drawer = !this.drawer 
      this.$refs.form.reset()
      this.dropList = {}
      this.update_id = undefined
    },
  },
}
</script>

