import Axios from 'axios';
import {base_url} from '@/main'

const AxiosPlugin = {
    install(app) {
        Axios.defaults.baseURL = base_url
        var token = localStorage.getItem("token")!=null?  "Bearer " +localStorage.getItem("token"):"";


         Axios.defaults.headers.common['Authorization'] =token;
        Axios.defaults.headers.post['Content-Type'] = 'application/json';
        Axios.interceptors.request.use(
            config =>{
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        app.config.globalProperties.$axios = Axios;
 

    }
};
export default AxiosPlugin;