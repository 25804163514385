import axios from "axios";
import host from '@/main'
export default ({
    namespaced:true,
    state:{
        countries:[],
        governorates:[],
        directorate:[],
        school_data:undefined,
        building:[],
        hall:[],
        committee:[],
        current_page:1,
        items_count:5,
        branch:[],
        contact:[],
        subjects:[],
        drivers:[],
        exam_period:[],
        classes:[],
        semesters:[],
        divisions:[],
        teachers:[],
        nationalities:[],
        discounts:[],
        assigned_teachers:[],
        academic_years:[],
        current_year:undefined,
        fees:[],
        fee_types:[],
        yearsOfStudy:[],
        classDocuments:[],
        documents:[],
        activities:[],
        lectures_attendance:[],
        days_choices:[],
        schedules:[],
        homeworks:[],
        teacher_class:[],
        months:[],
        class_assign_hall:[],
        estimates:[],
        buses:[],
        groups:[]
    },
    getters:{
        getGovernoratesByCountry:(state)=>(country)=>{
            let gov_list=[]
            state.governorates.forEach(element => {
                if(element.fk_country==country)
                gov_list.push(element)
            });
            return gov_list;
        },
        getDirectorateByGovernorates:(state)=>(gov)=>{
            let dir_list=[]
            state.directorate.forEach(element => {
                if(element.fk_governorate==gov)
                dir_list.push(element)
            });
            return dir_list;
        },
        getMonthBySemester:(state)=>(semester)=>{
            let month_list=[]
            state.months.forEach(element => {
                if(element.fk_semester!=undefined && element.fk_semester==semester)
                month_list.push(element)
            });
            return month_list;
        },
        getSubjectName:(state)=>(id)=>{
            let subject=state.subjects.find(item=>item.id==id)
            if(subject){
                return subject.name_ar
            }
        },
        
        getTeacherName:(state)=>(id)=>{
            let teacher=state.teachers.find(item=>item.id==id)
            if(teacher){
                return teacher.name_ar
            }
        },
        getClassName:(state)=>(id)=>{
            let class_data=state.classes.find(item=>item.id==id)
            if(class_data){
                return class_data.name_ar
            }
        },
        getSemesterName:(state)=>(id)=>{
            let semester=state.semesters.find(item=>item.id==id)
            if(semester){
                return semester.name_ar
            }
        },
        getMonthName:(state)=>(id)=>{
            let month=state.months.find(item=>item.id==id)
            if(month){
                return month.name
            }
        },
        getDivisionName:(state)=>(id)=>{
            let division=state.divisions.find(item=>item.id==id)
            if(division){
                return division.name
            }
        },
        getYearName:(state)=>(id)=>{
            let year=state.academic_years.find(item=>item.id==id)
            if(year){
                return `${year.year} - ${year.year_m}`
            }
        },
        getClassDivisions:(state,getters)=>(fk_class)=>{
            if(state.class_assign_hall.results){
                let divisions=state.class_assign_hall.results.filter(item=>item.fk_class==fk_class)
                if(divisions){
                    let divisions_data=[]
                    divisions.forEach(element=>{
                        divisions_data.push({id:element.fk_division,name:getters.getDivisionName(element.fk_division)})
                    })
                    return divisions_data
                }
            }
        },    
        getBuildingsHalls:(state)=>(fk_building)=>{
            let list=state.hall.filter(item=>item.fk_building==fk_building)
            return list
        },    
        current_month:(state,actions)=>{
            actions.getMonths
            let current_month=state.months.find(item=>item.current_month==true)
            if(current_month){
                return current_month.id
            }
            return state.months[0].id
        },     
    }, 
    actions:{
        async getMonths({commit}){
            let months=(await axios.get(host()+'api/month/',
            {
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')},
            })).data
            return commit('getMonths',months)
        },
        async getClassAssignHall({commit}){
            let class_assign_hall=(await axios.get(host()+'/assign-hall/',
            {
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')},
                params:{
                    page_size:1000
                }
            })).data
            return commit('getClassAssignHall',class_assign_hall)
        },
        async getTeacherClass({commit}){
            let teacher_class=(await axios.get(host()+'/assign-teacher/',
            {
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')},
                params:{
                    page_size:1000
                }
            })).data
            return commit('getTeacherClass',teacher_class)
        },
        async getLecturesAttendance({commit}){
            let lectures_attendance=(await axios.get(host()+'api/lecture-attendance/',{
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }})).data
            return commit('getLecturesAttendance',lectures_attendance)
        },
        async getHomeworks({state,commit}){
            let homeworks=(await axios.get(state.shehab+'api/homework/',
            {
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')},
                params:{
                    page_size:1000
                }
            })).data
            return commit('getHomeworks',homeworks)
        },
        async getExamPeriods({commit}){
            let exam_period=(await axios.get(host()+'examsPeriod/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getExamPeriods',exam_period)
        },
        async getAcademicYears({commit}){
            let academic_years=(await axios.get(host()+'api/academic-years/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getAcademicYears',academic_years)
        },
        async getDivisions({commit},current_class=0){
            let divisions=(await axios.get(host()+'api/divisions/?class='+current_class,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getDivisions',divisions)
        },
        // async getClasses({commit}){
        //     let classes=(await axios.get(host()+'api/classes/',
        //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
        //     return commit('getClasses',classes)
        // },
        async getSubjects({commit},fk_class=0){
            let subjects=(await axios.get(host()+'api/subjects/?class='+fk_class,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getSubjects',subjects)
        },
        // async getSubjects({commit}){
        //     let subjects=(await axios.get(host()+'api/subjects/',{
        //         headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
        //     return commit('getSubjects',subjects)
        // },
        async getAllMonths({commit}){
            let months=(await axios.get(host()+'months/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            
            return commit('getAllMonths',months)
        },
        /////this is repated
        // async getSubjects({commit}){
        //     let subjects=(await axios.get(host()+'api/subjects/',
        //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
        // },
        
        async getSchedule({state,commit}){
            let schedule=(await axios.get(state.abod+'/schedule/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getSchedule',schedule)
        },
        async getDaysChoices({commit}){
            let days=(await axios.get(host()+'days-choices/',{headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }})).data
            return commit('getDaysChoices',days)
        },
        async getAllTeachers({commit}){
            let teachers=(await axios.get(host()+'api/teachers/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')},params: {
                page:1,
                page_size:1000
            }})).data.results 
            return commit('getAllTeachers',teachers)
        },
        
        async getAllDrivers({commit}){
            let drivers=(await axios.get(host()+'/driver/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')},params: {
                page:1,
                page_size:1000
            }})).data.results 
            return commit('getAllDrivers',drivers)
        },
        async getActivities({commit}){
            let activities=(await axios.get(host()+'api/activities/',{headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }})).data
            return commit('getActivities',activities)
        },
        
        async getClassDocuments({commit}){
            let classDocuments=(await axios.get(host()+'api/class-documents/',{headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }})).data
            return commit('getClassDocuments',classDocuments)
        },
        async getDocuments({commit}){
            let documents=(await axios.get(host()+'api/documents/',{headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }})).data
            return commit('getDocuments',documents)
        },
        async getYearsOfStudy({commit}){
            let yearsOfStudy=(await axios.get(host()+'api/academic-years/',{headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }})).data
            return commit('getYearsOfStudy',yearsOfStudy)
        },
        async getSemesters({commit}){
            let semesters=(await axios.get(host()+'api/semesters/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getSemesters',semesters)
        },
        async getClasses({commit}){
            let classes=(await axios.get(host()+'api/classes/',{headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }})).data
            return commit('getClasses',classes)
        },
        async getCountries({commit}){
            let countries=(await axios.get(host()+'/country/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getCountries',countries)
        },
        async getGovernorates({commit},country=0){
            let governorates=(await axios.get(host()+'/governorate/?country='+country,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getGovernorates',governorates)
        },
        async getDirectorate({commit},governorate=0){
            let directorate=(await axios.get(host()+'/directorate/?gov='+governorate,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getDirectorate',directorate)
        },
        async getSchoolData({commit}){
            let school_data=(await axios.get(host()+'/school-data/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getSchoolData',school_data)
        },
        async getBrenches({commit},is_main=0){
            let branch=(await axios.get(host()+'/branches/?is_main='+is_main,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getBrenches',branch)
        },
        async getContact({commit},branch=0){
            let contact=(await axios.get(host()+'/contacts/?branch='+branch,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            
            return commit('getContact',contact)
        },
        async getBuilding({commit},branch=0){
            let building=(await axios.get(host()+'/building/?branch='+branch,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            
            return commit('getBuilding',building)
        },
        async getAllHalls({commit},building=0){
            let hall=(await axios.get(host()+'/hall/?building='+building,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')},params: {
                page:1,
                page_size:1000
            }})).data.results
            return commit('getAllHalls',hall)
        },
        async getAllCommittees({commit},hall=0){
            let committee=(await axios.get(host()+'/committees/?hall='+hall,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')},params: {
                page:1,
                page_size:1000
            }})).data.results
            return commit('getAllCommittees',committee)
        },
        async getCommittee({commit},hall=0){
            let committee=(await axios.get(host()+'/committees/?hall='+hall,
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getCommittee',committee)
        },
        changeCurrentPage({commit},payload){
            return commit('changeCurrentPage',payload)
        },

        async getNationalities({commit}){
            let nationalities=(await axios.get(host()+'/nationalities/')).data
            return commit('getNationalities',nationalities)
        },
        async getDiscount({commit}){
            let discounts=(await axios.get(host()+'/discount/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getDiscount',discounts)
        },
        async getAssignedTeachers({commit}){
            let assigned_teachers=(await axios.get(host()+'assign-teacher/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getAssignedTeachers',assigned_teachers)
        },
        async getFees({commit}){
            let fees=(await axios.get(host()+'fees/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getFees',fees)
        },
        async getFeeTypes({commit}){
            let fee_types=(await axios.get(host()+'fee-types/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getFeeTypes',fee_types)
            
        },
        
        async getAllEstimations({commit}){
            let estimates=(await axios.get(host()+'estimate/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getAllEstimations',estimates)   
        },

        async getAllDocuments({commit}){
            let documents=(await axios.get(host()+'document/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getAllDocuments',documents)
            
        },
        async getAllBuses({commit}){
            let buses=(await axios.get(host()+'/bus/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')},params: {
                page:1,
                page_size:1000
            }})).data.results 
            return commit('getAllBuses',buses)
        },
        async getAllGroups({commit}){
            let groups=(await axios.get(host()+'groups/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getAllGroups',groups)
        },
    },
    mutations:{
        getClassAssignHall(state,class_assign_hall){
            state.class_assign_hall=class_assign_hall
        },
        getMonths(state,months){
            state.months=months
        },
        getLecturesAttendance(state,lectures_attendance){
            state.lectures_attendance=lectures_attendance
        },
        getTeacherClass(state,teacher_class){
            state.teacher_class=teacher_class
        },
        getHomeworks(state,homeworks){
            state.homeworks=homeworks.results
        },
        getAcademicYears(state,academic_years){
            state.academic_years=academic_years
            let year=academic_years.find(item=>item.curentyear==true)
            if(year!=undefined)
                state.current_year=year.id
        },
        getDaysChoices(state,days){
            state.days_choices=days
        },
        getAllDrivers(state,drivers){
            state.drivers=drivers
        },
        getActivities(state,activities){
            state.activities=activities
        },
        
        getClassDocuments(state,classDocuments){
            state.classDocuments=classDocuments
        },
        getDocuments(state,documents){
            state.documents=documents
        },
        getYearsOfStudy(state,yearsOfStudy){
            state.yearsOfStudy=yearsOfStudy
        },
        getDivisions(state,divisions){
            state.divisions=divisions
        },
        getSemesters(state,semesters){
            state.semesters=semesters
        },
        getClasses(state,classes){
            state.classes=classes
        },
        getSubjects(state,subjects){
            state.subjects=subjects
        },
        getCountries(state,countries){
            state.countries=countries
        },
        getGovernorates(state,governorates){
            state.governorates=governorates
        },
        getDirectorate(state,directorate){
            state.directorate=directorate
        },
        getSchoolData(state,school_data){
            state.school_data=school_data
        },
        getBrenches(state,branch){
            state.branch=branch
        },
        getContact(state,contact){
            state.contact=contact
        },    
        getBuilding(state,building){
            state.building=building
        },    
        getAllHalls(state,hall){
            state.hall=hall
        },
        getAllCommittees(state,committee){
            state.committee=committee
        },
        getCommittee(state,committee){
            state.committee=committee
        },
        changeCurrentPage(state,payload){
            state.current_page=payload.page
            state.items_count=payload.count
        },
        getSchedule(state,schedule){
            state.schedules=schedule
        },
        getAllTeachers(state,teachers){
            state.teachers=teachers
        },
        getNationalities(state,nationalities){
            state.nationalities=nationalities
        },
        getDiscount(state,discounts){
            state.discounts=discounts
        },
        getAssignedTeachers(state,assigned_teachers){
            state.assigned_teachers=assigned_teachers
        },
        getFees(state,fees){
            state.fees=fees
        },
        getFeeTypes(state,fee_types){
            state.fee_types=fee_types
        },
        getExamPeriods(state,exam_period){
            state.exam_period=exam_period
        },
        getAllMonths(state,months){
            state.months=months
        
        },
        getAllEstimations(state,estimates){
            state.estimates=estimates
        },
        getAllDocuments(state,documents){
            state.documents=documents
        },
        getAllBuses(state,buses){
            state.buses=buses
        },
        getAllGroups(state,groups){
            state.groups=groups
        },
    }

})
