<template>
   <custom-auto-complete
    clearable
    :items="data"
    item-title="name"
    item-value="id"
    :label="$t('division.name')"
    icon="shape-outline"
    :col="col"
    :rules="rules"
    density="compact"
    editable="true"
  />
</template>
<script>
// import {
//   getDivisions,
//   getLevelDivisions,
// } from '@/plugins/methods/global-methods'


export default {
  props: {
    rules: {
      type: Object,
    },
    level: {
      type: undefined,
    },
    col: {
      type: Number,
      default() {
        return 3
      },
    },
  },
  data() {
    return {
      data: [],
      divisions: [],
      levelDeivision: [],
    }
  },

  
    async beforeCreate()  {
      
    // this.divisions = await getDivisions()
    // this.levelDeivision = await getLevelDivisions()
    // if(this.level)
    // this.getDivisions()

   },
  methods: {
    async getDivisions() {
      this.data = []
      if (this.level ) {
        
        let res = this.levelDeivision.find((f) => f.id == this.level)
        if (res && res.divisions) {
          
          res.divisions.map((e) => {
            let div = this.divisions.filter((f) => f.id == e)
            div.map((i) => {
              this.data.push(i)
            })
        })
        }
      } 
    },
  },
  watch: {
    level() {
      this.getDivisions()
    },
  },
}
</script>