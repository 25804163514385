<template>
  <custom-auto-complete
    :items="items"
    item-title="week_name"
    item-value="id"
    :label="$t('summer.calendar.week-name')"
    icon="calendar-week"
    col="3"
     />
</template>
<script>
// import {getWeeks} from'@/plugins/methods/global-methods'
export default {
  name:'ListWeek',
  data() {
    return {
      items: [] 
    }
  },
  async created() {
    // this.items = await getWeeks()
  },

}
</script>