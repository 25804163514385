import { createI18n } from "vue-i18n";
import arMessages from "@/locales/ar.json";
import enMessages from "@/locales/en.json";

const i18n = createI18n({
    locale: 'ar', // Default locale
    fallbackLocale: 'en', // Fallback locale
    messages: {
        ar: arMessages, // Use the messages from ar.js for Arabic
        en: enMessages, // Use the messages from en.js for English
    },
});

export default i18n;