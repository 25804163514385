<template>
  <v-col>
    <v-row>
      <v-col cols="8">
        <v-row>
          <custom-text-field
            v-model="value.user.first_name"
            :label="$t('user.fname')"
            icon="abjad-arabic"
            col="4"
            :rules="[$required, $ar_letters_only, $max_length(100)]"
          />
          <custom-text-field
            v-model="value.user.last_name"
            :label="$t('user.lname')"
            icon="abjad-arabic"
            col="4"
            :rules="[$required, $ar_letters_only, $max_length(100)]"
          />
          <custom-text-field
            v-model="value.student_data.name_en"
            :label="$t('globals.student_name_en')"
            icon="alpha-e"
            col="4"
            :rules="[$en_letters_only, $max_length(100)]"
          />
        </v-row>
        <v-row>
          <drop-list
            v-model="value.user.gender"
            name="gendar"
            col="4"
            :rules="[$required]"
          />
        </v-row>
      </v-col>
      <v-col col="4">
        <image-input
           v-model="value.user.image_user"
          :reset="image_reset_logo"
          :image="value.user.image_user"
          :text="$t('summer.summer_logo')"
          height="100"
        />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import DatetimePicker from '@/components/Globals/DatetimePicker.vue'
export default {
  components: { DatetimePicker },
  name: 'FirstWindow',
  props: {
    dropList: Object,
  },
  data() {
    return {
      image_reset_logo: undefined,
    }
  },
  computed: {
    value: {
      get() {
        return this.dropList
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
