<template>
  <v-col>
    <v-row>
      <custom-text-field
        v-model="value.fk_guardian.name"
        :label="$t('globals.name')"
        icon="abjad-arabic"
        col="3"
        :rules="[$required, $max_length(60)]"
      />

      <custom-text-field
        v-model="value.fk_guardian.representative"
        :label="$t('globals.relative_relation')"
        icon="account-group"
        col="3"
        :rules="[$required, $max_length(50)]"
      />

      <drop-list
        name="healthy_condition"
        v-model="value.fk_guardian.healthy_condition"
        col="3"
        :rules="[$required]"
      />

      <custom-text-field
        v-model="value.fk_guardian.phone_no"
        :label="$t('user.mobilenumber')"
        icon="phone"
        type="number"
        col="3"
        :rules="[$required]"
      />
    </v-row>
    <v-row>
      <custom-text-field
        v-model="value.fk_guardian.job"
        :label="$t('work_on_masarah.work')"
        icon="account-outline"
        col="3"
        :rules="[$max_length(50)]"
      />
      <custom-text-field
        v-model="value.fk_guardian.job_entity"
        :label="$t('globals.employer')"
        icon="account-box-outline"
        col="3"
        :rules="[$max_length(50)]"
      />

      <drop-list
        name="financial_condition"
        v-model="value.fk_guardian.financial_condition"
        col="3"
        :rules="[$required]"
      />
    </v-row>
  </v-col>
</template>
<script>
export default {
  props: {
    dropList: Object,
  },
  computed: {
    value: {
      get() {
        return this.dropList
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
