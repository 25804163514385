import { createRouter, createWebHistory } from 'vue-router';
import { fetchDynamicRoutes } from './routes'
import store from "@/store/index.js"
import { jwtDecode } from 'jwt-decode';

const router =  createRouter({
    history: createWebHistory(),
    routes: await fetchDynamicRoutes()
});
 
router.beforeEach((to, from, next) => {
    const check = localStorage.getItem("token");
    const checkPerm = localStorage.getItem("userinfo");
    const username = localStorage.getItem("username");
    if (!check || !username) {
      if (to.path !== "/login") {
        next("/login");
      } else {
        next();
      }
    } else if (check && to.path === "/login") {
      next("/");
    } else {
      const decode = jwtDecode(check);
      if (decode.exp < Date.now() / 1000) {
        window.location.reload();
        store.dispatch("logout");
        this.$axios(`api/delete/session/`, {
          params: {
            user: username,
          },
        });
      }
      store.dispatch("updateAuthenticated", check);
      store.dispatch("updatePermission", checkPerm);
      next();
    }
  });

export default router;