import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
// const customLightTheme = {
//     dark: false,
//     colors:{
//       background: '#d7d7d7',
//       primary: '#2196F3',
//       'primary-darken-1': '#00ffff',
//       secondary: '#00ffff',
//       'secondary-darken-1': '#00ffff',
//       error: '#EB4E3d',
//       info: '#2196F3',
//       success: '#5DC466',
//       warnning: '#F19A37',
//       indigo:'#3487F6',
//       'golden':'#d0bc39',
//       'ccc':'#f4f5f7',
//       'icon-color':'#8892a4'
//     }
//   }
//   const customDarkTheme = {
    
//     colors:{
//       background: '#1c222f',
//       primary: '#1c222f',
//       'primary-darken-1': '#00ffff',
//       secondary: '#00ffff',
//       'secondary-darken-1': '#00ffff',
//       error: '#EB4E3d',
//       info: '#2196F3',
//       success: '#5DC466',
//       warnning: '#F19A37',
//       indigo:'#3487F6',
//       'golden':'#d0bc39',
//       'ccc':'#f4f5f7',
//       'icon-color':'#8892a4'
//     }
//   }
  
export default function (app) {
    app.use(
        createVuetify({
            components,
            directives,
            // theme:{
            //     letterSpacing:{
            //       default:'normal'
            //     },
            //     defaultTheme: 'customLightTheme',
            //     variations:{
            //       lighten:1,
            //       darken: 2,
            //     },
            //     themes:{
            //       customLightTheme,
            //       customDarkTheme,
            //     }
            //   }
            theme:{
                dark: false,
                themes:{
                  dark:{
                    colors:{
                      background: '#1c222f',
                      primary: '#2196F3',
                      'primary-darken-1': '#00ffff',
                      secondary: '#00ffff',
                      'secondary-darken-1': '#00ffff',
                      error: '#EB4E3d',
                      info: '#2196F3',
                      success: '#5DC466',
                      warnning: '#F19A37',
                      indigo:'#3487F6',
                      'golden':'#d0bc39',
                      'cardcolor':'#283144',
                      'surface':'#283144',
                      'ccc':'#f4f5f7',
                      'icon-color':'#8892a4'
                  }},
                  light:{
                    colors:{
                    background: '#D7D7D7',
                    primary: '#2196F3',
                    'primary-darken-1': '#00ffff',
                    secondary: '#00ffff',
                    'secondary-darken-1': '#00ffff',
                    error: '#EB4E3d',
                    info: '#2196F3',
                    success: '#5DC466',
                    warnning: '#F19A37',
                    indigo:'#3487F6',
                    'golden':'#d0bc39',
                    'cardcolor':'#ffffff',
                    'ccc':'#f4f5f7',
                    'icon-color':'#8892a4'
                  }},
                }
              }
        })
    )
}