<template>
  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <drop-list
          name="subject_for_semester"
          v-model="dropList.fk_semeter_subject_id"
          :param="fk_semester"
          :extraData="extraData"
          :rules="[$required]"
        />
        <drop-list
          name="system_degree"
          v-model="dropList.fk_grading_system_id"
          :rules="[$required]"
        />
        <drop-list
          name="subject_section"
          v-model="dropList.subject_section_id"
          :rules="[$required]"
        />
         

        <custom-text-field
          v-model="dropList.number_of_hours"
          icon="clock-time-four-outline"
          :rules="[$required, $max_value(65535)]"
          :label="$t('globals.number_hours')"
          type="number"
        />

        <custom-text-field
          v-model="dropList.number_of_units"
          icon="package-variant-closed"
          :rules="[$required, $max_value(65535)]"
          :label="$t('globals.number_units_subject')"
          type="number"
        />
      
          <custom-text-field
            v-model="dropList.number_of_theoretical_lectures"
            icon="numeric"
            :rules="[$required, $max_value(65535)]"
            :label="$t('globals.number_of_theoretical_lecture')"
            type="number"
          />

        <custom-text-field
          v-model="dropList.min_grade_for_passing"
          icon="trending-up"
          :rules="[$required, $max_value(65535)]"
          :label="$t('globals.lowest_degree_success')"
          type="number"
        />
        <v-col>
          <v-checkbox
            v-model="dropList.does_have_lab"
            :label="$t('globals.is_have_lab')"
            density="true"
          />
          <custom-text-field
          v-if="dropList.does_have_lab"
          v-model="dropList.number_of_lab_lectures"
          icon="numeric"
          :rules="[$required, $max_value(65535)]"
          :label="$t('globals.number_of_lab_lectures')"
          type="number"
        />
          <v-checkbox
            v-model="dropList.adding_activity_grades"
            :label="$t('globals.add_activity_score')"
            density="true"
          />
        </v-col>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click.stop="resetForm(), (drawer = false)" />
    </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="isGroups?'':delData"
    :editItem="isGroups?'':editData"
    :actionList="actionList"
    :perm="{}"
    exports
    :create="isGroups?false: () => openDialog()"
  />
</template>
<script>
export default {
  created() {    
    if(this.$route.name=='subject-for-groups')
      this.isGroups = true

    this.id = this.$route.params?.id
    this.fk_semester = this.$route.params?.fk
  },

  data() {
    return {
      items: [],
      pagination: undefined,
      dropList: {},
      extraData: undefined,
      updated_id: undefined,
      drawer: null,
      perm: {},
      fk_semester: undefined,
      isGroups:false,
      id: undefined,
      headers: [
        { title: this.$t('subject.subject_n'), key: 'fk_semeter_subject' },
        { title: this.$t('globals.grading_system'), key: 'fk_grading_system' },
        { title: this.$t('subject.subject_section'), key: 'subject_section' },
        { title: this.$t('globals.number_hours'), key: 'number_of_hours' },
        {
          title: this.$t('globals.number_units_subject'),
          key: 'number_of_units',
        },
        {
          title: this.$t('globals.lowest_degree_success'),
          key: 'min_grade_for_passing',
        },
        {
          title: this.$t('globals.add_activity_score'),
          key: 'adding_activity_grades',
          type: 'check',
        },
        {
          title: this.$t('globals.is_have_lab'),
          key: 'does_have_lab',
          type: 'check',
        },

        { title: this.$t('globals.actions'), key: 'actions', sortable: false },
      ],
    }
  },
  methods: {
    actionList(data) {
      return [
        [
          'globals.subject_lectures',
          () =>
            this.$router.push({
              name: 'lecture-for-subject',
              params: { id: data?.id ,fk_semester_group_4B:this.fk_semester,  type: this.isGroups?0:1},              
            }),
        ],
      ]
    },
    openDialog() {
      this.resetForm()
      this.fk_semester = this.$route.params?.fk
      this.drawer = true
    },

    resetForm() {
      this.$refs.form.reset()
      this.extraData = undefined
      this.dropList = {}
      this.update_id = undefined
      this.fk_semester = undefined
    },

    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('academic-affairs/semester-subject4B/filter-paginate/', {
        params: {
          field:'fk_semester_4b',
          value:this.id,
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,          
        },
      })
        .then((response) => {

          this.items = response.data.results



          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    editData(data) {
      this.resetForm()
      this.update_id = data.id
      this.dropList = { ...data }
      this.extraData = []
       this.fk_semester = this.$route.params?.fk

      this.extraData.push({
        id: data.fk_semeter_subject_id,
        name: data.fk_semeter_subject,
        name_en: data.fk_semeter_subject_en,
      })
            this.extraData = this.extraData.slice().sort((a,b)=>a.id - b.id);

      this.drawer = true
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('academic-affairs/semester-subject4B/' + delete_id + '/')
          .then(() => {
            this.resetForm()
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .post('academic-affairs/semester-subject4B/', {
            ...this.dropList,
            fk_semester_4b: this.id,
            fk_grading_system: this.dropList.fk_grading_system_id,
            fk_semeter_subject: this.dropList.fk_semeter_subject_id,
            subject_section: this.dropList.subject_section_id,
            subject_type: this.dropList.subject_type_id,
            does_have_lab: this.dropList.does_have_lab
              ? this.dropList.does_have_lab
              : false,
            adding_activity_grades: this.dropList.adding_activity_grades
              ? this.dropList.adding_activity_grades
              : false,
          })
          .then(() => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.drawer = false
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .put(`academic-affairs/semester-subject4B/${this.dropList.id}/`, {
            ...this.dropList,
            fk_grading_system: this.dropList.fk_grading_system_id,
            fk_semeter_subject: this.dropList.fk_semeter_subject_id,
            subject_section: this.dropList.subject_section_id,
            subject_type: this.dropList.subject_type_id,
            fk_semester_4b: this.id,
          })
          .then(() => {
            this.$emit('alert', 'update')
            this.resetForm()
            this.drawer = false
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
  },
}
</script>
