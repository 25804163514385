<template>
  <custom-auto-complete
    :items="item"
    item-title="name_ar"
    item-value="id"
    :label="$t('class.specialization')"
    icon="certificate"
    :col="col"
     />
</template>
<script>
export default {
 
  props: {
     filter:{
      type:Array,       
    },
    rules: {
      type: Object,
    },
    col: {
      type: Number,
      default() {
        return 3;
      },
    },
  },

  data() {
    return {
      item: [],
 
    }
  },
  created() {

    this.$axios("system-management/specialization/",{
 
}).then((e)=>{
  
  this.item= e.data.results;

});

  },
 

}
</script>