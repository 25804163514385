<template>
 
  <v-card-item>
    <v-form ref="form">
         <v-col>
  <h3>بيانات ولي الامر</h3>
  <v-row class="mt-2">
     <custom-text-field
        v-model="value.name"
        :label="$t('globals.name')"
        icon="abjad-arabic"
        col="3"
        :rules="[$required, $max_length(60)]"
      />
        <custom-text-field
        v-model="value.representative"
        :label="$t('globals.relative_relation')"
        icon="account-group"
        col="3"
        :rules="[$required, $max_length(50)]"
      />
      
      <custom-text-field
        v-model="value.phone_no"
        :label="$t('user.mobilenumber')"
        icon="phone"
        type="number"
        col="3"
        :rules="[$required]"
      />
     

  </v-row>
  <v-row>
      <custom-text-field
        v-model="value.job"
        :label="$t('work_on_masarah.work')"
        icon="account-outline"
        col="3"
        :rules="[$max_length(50)]"
      />
      <custom-text-field
        v-model="value.job_entity"
        :label="$t('globals.employer')"
        icon="account-box-outline"
        col="3"
        :rules="[$max_length(50)]"
      />
        <drop-list
        name="healthy_condition"
        v-model="value.healthy_condition"
        col="3"
        :rules="[$required]"
      />
       <drop-list
        name="financial_condition"
        v-model="value.financial_condition"
        col="3"
        :rules="[$required]"
      />
  </v-row>
         
</v-col>
    </v-form>
  
  </v-card-item>
  <v-card-actions>
    <custom-btn type="update" :click="emit"/>
  </v-card-actions>
  
</template>
<script>
export default {
  props:{
    data: Object,
  },
  data() {
    return {
      value:{}
    }
  },
  created() {
    this.value = {...this.data}
  },
   methods: {
     async emit(){      
      const { valid } = await this.$refs.form.validate()
      if (valid)       
        this.$emit('updateEvent',this.updateData())
    },
   async updateData(){
     
      return await this.$axios
        .put(`student-affairs/guardian/${this.value.id}/`, this.value)
    }
  },
 
}
</script>