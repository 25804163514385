<template>
  <v-dialog color="indigo" v-model="drawer" width="600">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row>
            <v-expansion-panels model-value="1" v-model="panel">
              <v-expansion-panel value="1" class="border border-primary" >
                <template v-slot:default>
                  <v-expansion-panel-content  :ripple="false">
                    <v-expansion-panel-title
                      >{{ $t("semster_subject.semester_subjects_add") }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <v-form ref="form">
                      <v-row
                      >
                        <v-col cols="12" md="4" >
                        <drop-list name="subjects"  v-model="subjectsList.fk_subject"
                              density="compact"
                              :rules="[$required]"
                              props="true"
                              persistent-hint
                              class="pa-0" ></drop-list>
                        
                        </v-col>
                    
                            <v-col cols="12" md="4" >
                            <drop-list name="subject_type"
                              v-model="subjectsList.subject_type"
                              density="compact"
                              :rules="[$required]"
                            
                              persistent-hint
                              class="pa-0"
                            ></drop-list>
                      
                            </v-col>
                        <v-col cols="12" md="4" >
                          
                          <drop-list name="system_degree"  v-model="subjectsList.fk_grading_system"
                              density="compact"
                              :rules="[$required]"
                              persistent-hint
                              class="pa-0"></drop-list>
                          
                            </v-col>
                          <v-col cols="12" md="4" >
                          
                          <v-text-field
                            type="number"
                            :label="$t('semster_subject.number_of_hours')"
                            v-model="subjectsList.number_of_hours"
                            :rules="[$required]"
                            icon="clock"
                            density="compact"
                            >
                            </v-text-field>
                          
                            </v-col>
                                <v-col cols="12" md="4" >

                            <v-text-field
                            type="number"
                            :label="$t('semster_subject.number_of_units')"
                            v-model="subjectsList.number_of_units"
                            :rules="[$required]"
                            icon="numeric"
                            density="compact"
                            >
                            </v-text-field>
                            </v-col>
                                <VCol cols="12" md="4" >
                          <v-text-field
                            type="number"
                            :label="$t('semster_subject.min_grade_for_passing')"
                            v-model="subjectsList.min_grade_for_passing"
                            :rules="[$required]"
                            icon="close-circle"
                            density="compact"
                            >
                            </v-text-field>
                            </VCol>
                            <v-checkbox
                            type="check"
                            :label="$t('semster_subject.adding_activity_grades')"
                            v-model="subjectsList.adding_activity_grades"
                            
                            icon="numeric"
                            >
                            </v-checkbox>
                          <v-checkbox
                            type="check"
                            :label="$t('semster_subject.does_have_lab')"
                            v-model="subjectsList.does_have_lab"
                            icon="numeric"
                            >
                            </v-checkbox>
                      </v-row>
                      </v-form>
                    </v-expansion-panel-text>
                  </v-expansion-panel-content>
                </template>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-3">
          <custom-btn v-if="!is_update" type="save"  :click="saveData" />
            <custom-btn v-if="is_update" type="update"  :click="updateData"  />
          <custom-btn
            :label="$t('globals.cancel')"
            color="white"
            text_color="black"
            icon_color="black"
            type="cancel"
            @click="drawer = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <custom-data-table-2
        :headers="headers"
        :items="items"
        :pagination="pagination"
        :method="getData"
        :delItem="delData"
        :editItem="editData"
        style="z-index: auto"
        :perm="{}"
        export
        :create="()=>opendailog()"
        :actionList="actionList"
    />
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
    data() {
        return {
        panel:[0,1],
        items: [],
        pagination: undefined,
        dropList:{ },
        is_update: false,
        updated_id: undefined,
        drawer: null,
        subjectsList:{does_have_lab:false, adding_activity_grades:false },
        headers: [
            { title: this.$t('semster_subject.subject'), key: 'subject_name' ,sortable: false},
            { title: this.$t('semster_subject.subject_type'), key: 'subject_type_name' ,sortable: false},
            { title: this.$t('semster_subject.grading_system'), key: 'grading_system_name' ,sortable: false},
            { title: this.$t('semster_subject.number_of_hours'), key: 'number_of_hours' },
            { title: this.$t('semster_subject.number_of_units'), key: 'number_of_units' },
            { title: this.$t('semster_subject.min_grade_for_passing'), key: 'min_grade_for_passing' },
            { title: this.$t('semster_subject.adding_activity_grades'), key: 'adding_activity_grades', type: "check",sortable: false },
            { title: this.$t('semster_subject.does_have_lab'), key:'does_have_lab', type: "check",sortable: false},
            { title: this.$t('globals.actions'), key: 'actions' },
        ],
        }
    },
    methods: {
          actionList(data){
          return [
            [
              'semster_subject.lectures_subjects_add',
              ()=>this.addsubjects(data)
            ],
          ]
        },
        addsubjects(data){
                this.update_id = data.id
                this.$router.push(`/lectures_subjects/${this.update_id}`);  
        },
        opendailog() {
        this.drawer = !this.drawer
        this.is_update = false
        this.resetForm()
        },
        resetForm() {
            
            this.subjectsList={}
            this.is_update = false
            this.updated_id = undefined
            },
        editData(data, isPermission = false) {
            this.update_id = data.id
            if (!isPermission) {
            this.drawer =true
            this.subjectsList= {...data}
            this.is_update = true
            } else {
            return true
            }
        },
        async updateData() {
          if (this.panel === undefined || this.panel === null) {
          }else{
        const { valid } = await this.$refs.form.validate();
        if (valid) {
            this.subjectsList.data_entry = this.user;
            this.subjectsList.fk_semester=this.id;
        await this.$axios
            .put(
                this.base_url + "system-management/semester-subject-data/" + this.update_id +'/',
                this.subjectsList,
            
            )
            .then(() => {
              this.$emit("successAlert", this.$t("globals.data_updated"));
                        this.resetForm();

            this.getData();
              this.drawer = !this.drawer
            })
            .catch((e) => {
              
              if (e.response.data.non_field_errors[0] == "الحقول fk_semester, fk_subject يجب أن تشكل مجموعة فريدة.") {
                    this.$emit("errorAlert", this.$t("semster_subject.unique_subject_and_subject_type"));
                  }
                else{

                  this.$emit("errorAlert", this.$t("alert.failure.title"));
                }
            });
        }
          }
        },
        async delData(delete_id, isPermission = false) {
        if (!isPermission) {
            if (delete_id != undefined) {
            var status
            await this.$axios
                .delete('system-management/semester-subject-data/' + delete_id +'/')
                .then(() => {
                status = true
                })
                .catch((error) => {
                status = error
                })
            return status
            }
        } else {
            return true
        }
        },
        async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
        await this.$axios('system-management/semester-subject-data/filter-paginate/', {
            params: {
            search: txt_search,
            page: page,
            page_size: perPage,
            sort_by: order_data,
            field:"fk_semester",
            value: this.id,
            },
        })
            .then((response) => {
            this.items = response.data.results
            this.pagination = response.data.pagination
            })
            .catch(() => {})
        },
        async saveData() {
          if (this.panel === undefined || this.panel === null) {
          }else{
            const { valid } = await this.$refs.form.validate();
            if (valid) {
            if( this.subjectsList.does_have_lab == undefined){
              this.subjectsList.does_have_lab= false;
            }
            if( this.subjectsList.adding_activity_grades == undefined){
              this.subjectsList.adding_activity_grades= false;
            }
            this.subjectsList.data_entry = this.user;
            this.subjectsList.fk_semester=this.id;
            await this.$axios
                .post('system-management/semester-subject-data/', this.subjectsList)
                .then(() => {
                this.$emit("successAlert", this.$t("globals.data_added"));
                this.resetForm();
                this.getData();
                this.drawer = false
                })
                .catch((e) => {
                  if (e.response.data.non_field_errors[0] == "الحقول fk_semester, fk_subject يجب أن تشكل مجموعة فريدة.") {
                    this.$emit("errorAlert", this.$t("semster_subject.unique_subject_and_subject_type"));
                  }else{
                    this.$emit("errorAlert", this.$t("globals.error_in_data"));
                  }
                });
            }
          }
        },
    },
    }
</script>
