<template>
  <custom-auto-complete
    :items="items"
    item-title="name"
    item-value="id"
    :label="$t('summer.place-type')"
    icon="format-list-bulleted-type"
    col="4"
  />
</template>
<script>
// import { getEvaluationList } from '@/plugins/methods/global-methods'

export default {
  name: 'ListEvaluation',
  data() {
    return {
      items: [],
    }
  },
  async created() {
    // this.items = await getEvaluationList()
  },
}
</script>