<template>
    <p>بيانات المستخدم</p>
         <v-card class="rounded-lg pa-4">
        <v-row>
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" class="text-center">
                <h1>علي سعد العاضي</h1>
                <h3 class="mt-2"></h3>
              </v-col>
              <v-divider></v-divider>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <custom-text-field
              icon="abjad-arabic"
              :label="$t('globals.adjective')"
              readonly
              :clearable="false"
            />
          </v-col>
          <v-col cols="3">
            <custom-text-field
              icon="abjad-arabic"
              :label="$t('user.mobilenumber')"
              readonly
              :clearable="false"
            />
          </v-col>
          <v-col cols="3">
            <custom-text-field
              icon="abjad-arabic"
              :label="$t('globals.father_health_condition')"
              readonly
              :clearable="false"
            />
          </v-col>
          <v-col cols="3">
            <custom-text-field
              icon="abjad-arabic"
              :label="$t('globals.financial_condition')"
              readonly
              :clearable="false"
            />
          </v-col>
          <v-col cols="3">
            <custom-text-field
              icon="abjad-arabic"
              :label="$t('work_on_masarah.work')"
              readonly
              :clearable="false"
            />
          </v-col>
          <v-col cols="3">
            <custom-text-field
              icon="abjad-arabic"
              :label="$t('globals.employer')"
              readonly
              :clearable="false"
            />
          </v-col>
          <v-divider></v-divider>
        </v-row>

      </v-card>
</template>