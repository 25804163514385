import { createApp } from 'vue'
import App from './App.vue'
import vuetifyPlugin from './plugins/vuetify.js'
import axiosPlugin from './plugins/axios-plugin.js'
import jwtDecodePlugin from './plugins/jwt-decode.js'
import store from './store/index.js'
import router from './router/router.js'
import routes from './router/routes.js'
import i18n from './plugins/i18n.js'
import validation from './plugins/validation-plugin'
import permission from './plugins/permission'
import GlobalTools from './plugins/globalTools'
import VueDatetimeJs from 'vue3-datetime-js'

import ItemsSideBar from './components/ComponentGlobal/ItemsSideBar.vue';
import CustomDataTable from '@/components/ComponentGlobal/CustomDataTable';
import CustomDataTable2 from '@/components/ComponentGlobal/CustomDataTable2';
import CustomDataTableWithListActions from '@/components/ComponentGlobal/CustomDataTableWithListActions';
import CustomTextField from '@/components/ComponentGlobal/CustomTextField';
import CustomCard from '@/components/ComponentGlobal/CustomCard';
import CustomCardIcon from '@/components/ComponentGlobal/CustomCardIcon';
import CustomAutoComplete from '@/components/ComponentGlobal/CustomAutoComplete'
import CustomTextNote from '@/components/ComponentGlobal/CustomTextNote'
import CustomBtn from '@/components/ComponentGlobal/CustomBtn'
import BarChart from '@/components/ComponentGlobal/BarChart'
import ChartPie from '@/components/ComponentGlobal/ChartPie'

import CustomFileInput from '@/components/ComponentGlobal/CustomFileInput'

// import DeleteConfirmation from '@/components/ComponentGlobal/DeleteConfirmation';
import BtnClear from '@/components/ComponentGlobal/CustomBtnClear';
import CustomRightWrong from '@/components/ComponentGlobal/CustomRightWrong';
import ListUser from '@/components/AutoCompete/ListUser';
import ListBatch from '@/components/AutoCompete/ListBatch';
import ListAttachments from '@/components/AutoCompete/ListAttachments';
import ListGradeSystem from '@/components/AutoCompete/‏‏‏‏ListGradeSystem';
import ListSubjectsType from './components/AutoCompete/‏‏ListSubjectsType.vue';
import ListCondations from '@/components/AutoCompete/‏‏ListCondations';
import ListWeek from '@/components/AutoCompete/ListWeek'
import ListSystemType from '@/components/AutoCompete/ListSystemType'
import ListNationality from '@/components/AutoCompete/‏‏ListNationality'
import ListDocmentType from '@/components/AutoCompete/‏‏ListDocmentType.vue'
import ListCommities from '@/components/AutoCompete/ListCommities'
import ListCurrency from '@/components/AutoCompete/‏‏ListCurrency.vue'
import ListSubjects from '@/components/AutoCompete/ListSubjects'
import ListTypeActivity from '@/components/AutoCompete/ListTypeActivity'
import ListActivityIterations from '@/components/AutoCompete/ListActivityIterations.vue'
import "@/assets/css/style.css";
import ListCountry from '@/components/AutoCompete/ListCountry';
import ListGovernorates from '@/components/AutoCompete/ListGovernorates';
import ListDir from '@/components/AutoCompete/ListDir';
import ListSupervisionType from '@/components/AutoCompete/ListSupervisionType';
import ListLevel from '@/components/AutoCompete/ListLevel';
import ListGander from '@/components/AutoCompete/ListGander';
import ListPlaceTypes from '@/components/AutoCompete/ListPlaceTypes';
import ListEvaluation from '@/components/AutoCompete/ListEvaluation';
import ListDays from '@/components/AutoCompete/ListDays';
import ListCommitestype from '@/components/AutoCompete/ListCommitestype';
import ListDivision from '@/components/AutoCompete/ListDivision';
import ListDirVisitor from '@/components/AutoCompete/ListDirVisitor';
import ListActivity from '@/components/AutoCompete/ListActivity';
import ListSupplies from '@/components/AutoCompete/‏‏ListSupplies';
import ListEmployee from '@/components/AutoCompete/ListEmployee';
import ListMetrailstate from '@/components/AutoCompete/ListMetrailstate';
import ListWorktype from '@/components/AutoCompete/‏‏ListWorktype';
import ListWorkperiod from '@/components/AutoCompete/‏‏‏‏ListWorkperiod';
import ListQualifications from '@/components/AutoCompete/ListQualifications';
import ListAccommodationType from '@/components/AutoCompete/ListAccommodationType';
import ListGroups from '@/components/AutoCompete/ListGroups';
import ListSummerCenters from '@/components/AutoCompete/ListSummerCenters';
import ListStudentType from '@/components/AutoCompete/ListStudentType.vue';
import ListBranchTypeChoice from '@/components/AutoCompete/ListBranchTypeChoice.vue';
import ListCollege from '@/components/AutoCompete/ListCollege';
import ListBranch from '@/components/AutoCompete/ListBranch';
import ListBuildings from '@/components/AutoCompete/ListBuildings';
import ListُُEducationLevel from '@/components/AutoCompete/‏‏ListُُEducationLevel';
import ListQualificationType from '@/components/AutoCompete/ListQualificationType';
import ListSpecialization from '@/components/AutoCompete/ListSpecialization.vue';
import ListNumberingBumper from '@/components/AutoCompete/ListNumberingBumper';
import ListDegreeType from '@/components/AutoCompete/ListDegreeType';
import ListStudent from '@/components/AutoCompete/‏‏ListStudent';
import ReportHeader from '@/components/SummerGlobals/ReportHeader.vue';

// import ListStudents from '@/components/AutoCompete/ListStudents';
// import ListJopSystemChoice from '@/components/AutoCompete/ListJopSystemChoice.vue';
// import ListEmployeeStatusChoice from '@/components/AutoCompete/ListEmployeeStatusChoice.vue';

import AlertMessages from '@/components/Globals/Alert.vue'
import AlertCascadeRecords from '@/components/Globals/AlertCascadeRecords.vue'
import AlertProtectedRecords from '@/components/Globals/AlertProtectedRecords.vue'
import ImageInput from '@/components/Globals/ImageInput.vue'
import DatetimePicker from '@/components/Globals/DatetimePicker.vue'
import ScreenHeader from '@/components/Globals/ScreenHeader.vue'
import NavDrawer from '@/components/Globals/NavDrawer.vue'

import ListSection from '@/components/AutoCompete/ListSection.vue'
import ListDegree from '@/components/AutoCompete/ListDegree.vue'
import CustomExpansionPanelCheckBox from '@/components/ComponentGlobal/CustomExpansionPanelCheckBox.vue'

import ListSubjectSection from '@/components/AutoCompete/ListSubjectSection'
import ListSemester from '@/components/AutoCompete/ListSemester'
import ListSemesterSubjects from '@/components/AutoCompete/ListSemesterSubjects.vue'

import ListLectureType from '@/components/AutoCompete/ListLectureType.vue'
import ListNumberingSystem from '@/components/AutoCompete/ListNumberingSystem'
import ListYear from '@/components/DropList/ListYear'
import ListSystemDegree from '@/components/DropList/ListSystemDegree'
import ListSubject from '@/components/DropList/ListSubject'
import ListDocumentStatus from '@/components/AutoCompete/ListDocumentStatus'
import ListSemesterPeriod from '@/components/AutoCompete/ListSemesterPeriod.vue'
import ListSemesterClass from '@/components/AutoCompete/ListSemesterClass.vue'

import ListBatchSemester from '@/components/AutoCompete/ListBatchSemester'
import ListActivitiesClass from '@/components/AutoCompete/ListActivitiesClass'
import ListActivitiesType from '@/components/AutoCompete/ListActivitiesType'
import ListGroupPeriod from '@/components/AutoCompete/ListGroupPeriod'
import ListAlgorithm from '@/components/AutoCompete/ListAlgorithm'
import ListAssiginAccomdation from '@/components/AutoCompete/ListAssiginAccomdation'
import ListServiceStatus from '@/components/AutoCompete/ListServiceStatus'
import ListActivitiesGroups from '@/components/AutoCompete/ListActivitiesGroups'
import ListActivitiesGroupsFilter from '@/components/AutoCompete/ListActivitiesGroupsFilter'

import DropList from '@/components/DropList/DropList'

import ListCheckConditionStatus from './components/AutoCompete/ListCheckConditionStatus.vue'

import CustomDeleteDialog from '@/components/ComponentGlobal/CustomDeleteDialog'

import {url} from '@/plugins/methods/global-methods'
import echarts from "vue-echarts"

export const base_url = url()



const app = createApp(App)
app.config.globalProperties.base_urls = url()
app.config.globalProperties.toEnglishString = function (string) {
  const arabic_numbers = {
    '\u0660': 0,
    '\u0661': 1,
    '\u0662': 2,
    '\u0663': 3,
    '\u0664': 4,
    '\u0665': 5,
    '\u0666': 6,
    '\u0667': 7,
    '\u0668': 8,
    '\u0669': 9,
    '/': '-',
  }

  var english_string = ''
  if (typeof string == 'string') {
    for (let index = 0; index < string.length; index++) {
      if (Object.keys(arabic_numbers).includes(string[index])) {
        english_string += arabic_numbers[string[index]]
      } else {
        english_string += string[index]
      }
    }
    return english_string
  } else {
    return string
  }
}
app.config.globalProperties.base_url = url()
app.use(routes)
app.use(vuetifyPlugin)
// Register the axios plugin
app.use(axiosPlugin)
// Register the jwt-decode plugin
app.use(jwtDecodePlugin)
// Register the store plugin
app.use(store)
// Register the router
app.use(router)
// Register the i18n plugin
app.use(i18n)
// Register the validation plugin
app.use(validation)
app.use(permission)
app.use(GlobalTools)

app.component('date-picker', VueDatetimeJs)
app.component('chart', echarts)
app.component('items-side-bar', ItemsSideBar)
app.component('ListSubjectsType', ListSubjectsType)
app.component('custom-data-table-2', CustomDataTable2)
app.component('custom-data-table', CustomDataTable)
app.component('custom-auto-complete', CustomAutoComplete)
app.component('custom-card', CustomCard)
app.component('custom-card-icon', CustomCardIcon)
app.component('custom-btn-clear', BtnClear)
app.component('custom-btn', CustomBtn)
// app.component('delete-confirmation', DeleteConfirmation)
app.component('custom-right-wrong', CustomRightWrong)
app.component('CustomTextField', CustomTextField)
app.component('CustomTextNote', CustomTextNote)
app.component('ImageInput', ImageInput)
app.component('list-country', ListCountry)
app.component('list-assigin-accomdation', ListAssiginAccomdation)
app.component('list-governorates', ListGovernorates)
app.component('list-dir', ListDir)
app.component('list-week', ListWeek)
app.component('ListServiceStatus', ListServiceStatus)
app.component('list-system-type', ListSystemType)
app.component('ListUser', ListUser)
app.component('ListBatch', ListBatch)
app.component('ListAttachments', ListAttachments)
app.component('ListGradeSystem', ListGradeSystem)
app.component('ListCondations', ListCondations)
app.component('ListSemesterClass', ListSemesterClass)

app.component('‏‏ListNationality', ListNationality)
app.component('ListNationality', ListNationality)
app.component('ListDocmentType', ListDocmentType)
app.component('ListCommities', ListCommities)
app.component('ListSubjects', ListSubjects)

app.component('list-supervision-type', ListSupervisionType)
app.component('ListTypeActivity', ListTypeActivity)
app.component('ListActivityIterations', ListActivityIterations)
app.component('ListLevel', ListLevel)
app.component('ListGander', ListGander)
app.component('ListPlaceTypes', ListPlaceTypes)
app.component('ListEvaluation', ListEvaluation)
app.component('ListDays', ListDays)
app.component('ListCommitestype', ListCommitestype)
app.component('ListDivision', ListDivision)
app.component('ListDirVisitor', ListDirVisitor)
app.component('ListActivity', ListActivity)
app.component('ListSupplies', ListSupplies)
app.component('ListEmployee', ListEmployee)
app.component('ListMetrailstate', ListMetrailstate)
app.component('ListWorktype', ListWorktype)
app.component('ListWorkperiod', ListWorkperiod)
app.component('ListQualifications', ListQualifications)
app.component('ListAccommodationType', ListAccommodationType)
app.component('ListGroups', ListGroups)
app.component('ListSummerCenters', ListSummerCenters)
app.component('ListStudentType', ListStudentType)
app.component('ListBranchTypeChoice', ListBranchTypeChoice)
app.component('ListCollege', ListCollege)
app.component('ListBranch', ListBranch)
app.component('ListBuildings', ListBuildings)
app.component('ListQualificationType', ListQualificationType)
app.component('ListُُEducationLevel', ListُُEducationLevel)
app.component('ListSpecialization', ListSpecialization)
app.component('ListNumberingBumper', ListNumberingBumper)
app.component('ListDegreeType', ListDegreeType)
app.component('ListStudent', ListStudent)
app.component('ReportHeader', ReportHeader)

app.component('AlertMessages', AlertMessages)
app.component('AlertProtectedRecords', AlertProtectedRecords)
app.component('AlertCascadeRecords', AlertCascadeRecords)
app.component('DatetimePicker', DatetimePicker)
app.component('ScreenHeader', ScreenHeader)
app.component('ReportHeader', ReportHeader)
app.component('ListCurrency', ListCurrency)
app.component('NavDrawer', NavDrawer)
app.component('ListSection', ListSection)
app.component('ListDegree', ListDegree)
app.component('EPC', CustomExpansionPanelCheckBox)
app.component('CustomDataTableWithListActions', CustomDataTableWithListActions)

app.component('ListSubjectSection', ListSubjectSection)
app.component('ListSemester', ListSemester)
app.component('ListSemesterSubjects', ListSemesterSubjects)
app.component('ListLectureType', ListLectureType)
app.component('ListYear', ListYear)
app.component('ListSystemDegree', ListSystemDegree)
app.component('ListSubject', ListSubject)
app.component('ListDocumentStatus', ListDocumentStatus)

app.component('ListNumberingSystem', ListNumberingSystem)

app.component('ListSemesterPeriod', ListSemesterPeriod)

app.component('ListSemesterClass', ListSemesterClass)

app.component('CustomFileInput', CustomFileInput)
app.component('ListBatch', ListBatch)
app.component('ListBatchSemester', ListBatchSemester)
app.component('ListActivitiesClass', ListActivitiesClass)
app.component('ListActivitiesType', ListActivitiesType)
app.component('ListGroupPeriod', ListGroupPeriod)
app.component('ListAlgorithm', ListAlgorithm)
app.component('ListActivitiesGroups', ListActivitiesGroups)
app.component('ListActivitiesGroupsFilter', ListActivitiesGroupsFilter)
app.component('DropList', DropList)
app.component("BarChart", BarChart)
app.component("ChartPie", ChartPie)

app.component('ListCheckConditionStatus', ListCheckConditionStatus)
app.component('CustomDeleteDialog', CustomDeleteDialog)

app.mount('#app')

