<template>
<v-app :theme="theme" with-background>
 
  <v-theme-provider >
  <router-view></router-view>
  </v-theme-provider>
</v-app>
</template>

<script>
import {mapState}  from  'vuex';
export default {
  name: 'App',
  components: {},
  computed:{
    ...mapState({
      theme:(state) => state.theme,
    })
  },

}
</script>
