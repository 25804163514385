<template>
  <custom-auto-complete
    :items="items"
    item-title="id"
    item-value="id"
    :label="$t('complaint.fk_assigning_accommodation')"
    icon="home"
    :col="col"
    :rules="rules"
    density="compact"
    />
</template>
<script>
export default {
  name:'ListAssiginAccomdation',
  
props:{
  rules:{
    type:Object
  },
  col:{
    type:Number,
    default(){
      return 3
    }
  }
},
  data() {
    return {
      items: [] ,
      committeeType: [
        {
          name: "اجتماعي",
          id: 1,
        },
        {
          name: "ثقافي",
          id: 2,
        },
      ],
    }
  },
  created() {

    this.$axios('accommodation/assigning-accommodation/').then((e)=>{
        this.items =e.data.results;
    })
  
  },

}
</script>