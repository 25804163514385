import i18n from './i18n'
const ValueValidationPlugin = {
  install(Vue) {
    // Required field validation
    Vue.config.globalProperties.$validateRequired = (value) => {
      if (value) return true
      else if (value == true || value == false) return true
      return i18n.global.t('globals.required_field')
    }
    // Length validation
    Vue.config.globalProperties.$validateLength = (
      value,
      minLength = 3,
      maxLength = 15,
    ) => {
      if (value && value.length) {
        if (minLength && value.length < minLength)
          return `${i18n.global.t('globals.lowest_number_of_character')} ${minLength}`
        if (maxLength && value.length > maxLength)
          return `${i18n.global.t('globals.biggest_number_of_character')} ${maxLength}`
      }
      return true
    }
    Vue.config.globalProperties.$validateMaxNumber = (
      value,
      minValue,
      maxValue,
    ) => {
      if (value) {
        if (value > maxValue)
          return `${i18n.global.t('globals.max_numbers_value')} ${maxValue}`
        if (value < minValue)
          return `${i18n.global.t('globals.min_numbers_value')} ${minValue}`
      }
      return true
    }
    // Field length validation
    Vue.config.globalProperties.$validateFieldLength = (
      value,
      filedLength,
      filedHint,
    ) => {
      if (value) {
        if (value && value.length > filedLength)
          return `${i18n.global.t('globals.biggest_number_of_character')} ${filedHint}`
      }
      return true
    }
    // Arabic text validation
    Vue.config.globalProperties.$validateArabicText = (value) => {
      const arabicTextRegex = /^[\u0621-\u064A ]+$/
      if (value) {
        if (typeof value === 'string' && arabicTextRegex.test(value))
          return true
        return i18n.global.t('globals.must_be_letters')
      }
      return true
    }
    // English text validation
    Vue.config.globalProperties.$validateEnglishText = (value) => {
      const englishTextRegex = /^[A-Za-z- ]+$/
      if (value) {
        if (typeof value === 'string' && englishTextRegex.test(value))
          return true
        return i18n.global.t('globals.must_be_letters_en')
      }
      return true
    }
    // Number validation
    Vue.config.globalProperties.$validateNumber = (value) => {
      const NumRegex = /^[0-9]+$/
      if (value === null || value === undefined) return true
      if (
        typeof value === 'number' ||
        (typeof value === 'string' && NumRegex.test(value))
      )
        return true
      return i18n.global.t('globals.must_be_numbers')
    }
    // Boolean length validation
    Vue.config.globalProperties.$validateBoolean = (value) => {
      if (value !== undefined || value !== null) {
        if (typeof value === 'boolean') return true
      }
      return i18n.global.t('globals.required_field')
    }
    // Email validation
    Vue.config.globalProperties.$validateEmail = (value) => {
      const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (value === undefined || value === null) return true
      if (typeof value === 'string' && EmailRegex.test(value)) return true
      return i18n.global.t('globals.email')
    }
    Vue.config.globalProperties.$validateMaxDate = (value, max) => {
      if (value) if (value > max) return i18n.global.t('globals.max_date') + max

      return true
    }
    //*********** new validation added by fares ***************
    // required validation
    Vue.config.globalProperties.$required = (value) =>
      !!value ||
      typeof value === 'boolean' ||
      value === 0 ||
      i18n.global.t('globals.required_field')
    // numeric validation
    Vue.config.globalProperties.$numeric = (value) =>
      !value ||
      /^[0-9+]+$/.test(value) ||
      i18n.global.t('globals.must_be_numbers')
    // max_length validation
    Vue.config.globalProperties.$max_length =
      (length = 100) =>
      (value) => {
        if (value) {
          if (value && value.length > length)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? 'globals.biggest_number_of_character'
                  : 'globals.max_numbers_value',
              ) +
              ' ' +
              length
            }`
        }
        return true
      }
    // min_length validation
    Vue.config.globalProperties.$min_length =
      (length = 10) =>
      (value) =>
        !value ||
        (value && value.length >= length) ||
        `${
          i18n.global.t('globals.lowest_number_of_character') +
          ' ' +
          length
        }`

    // ar_letters_only validation
    Vue.config.globalProperties.$ar_letters_only = (value) =>
      !value ||
      /^[\u0621-\u064A ]+$/.test(value) ||
      i18n.global.t('globals.must_be_letters')

    // en_letters_only validation
    Vue.config.globalProperties.$en_letters_only = (value) =>
      !value ||
      /^[a-zA-Z' ]+$/.test(value) ||
      i18n.global.t('globals.must_be_letters_en')

    // ar_with_numbers validation
    Vue.config.globalProperties.$ar_with_numbers = (value) =>
      !value ||
      /^[\u0621-\u064A0-9 ]+$/.test(value) ||
      i18n.global.t('globals.must_be_letters_with_number')

    // en_with_numbers validation
    Vue.config.globalProperties.$en_with_numbers = (value) =>
      !value ||
      /^[a-zA-Z'0-9 ]+$/.test(value) ||
      i18n.global.t('globals.must_be_letters_en_with_number')

    // max_date validation
    Vue.config.globalProperties.$max_date = (year) => (value) =>
      !value ||
      !year ||
      year >= value ||
      `${i18n.global.t('globals.max_date') + ' ' + year}`

    // max_date validation
    Vue.config.globalProperties.$min_date = (year) => (value) =>
      !value ||
      year < value ||
      `${i18n.global.t('globals.deadline_date') + ' ' + year}`

    // max_value validation
    Vue.config.globalProperties.$max_value =
      (length = 10) =>
      (value) => {
        if (value) {
          if (value && value > length)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? 'validation.max_characters'
                  : 'validation.max_numbers_value',
              ) +
              ' ' +
              length
            }`
        }
        return true
      }
    // min_value validation
    Vue.config.globalProperties.$min_value =
      (length = 10) =>
      (value) => {
        if (value) {
          if (value && value < length)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? 'validation.min_characters'
                  : 'validation.min_numbers_value',
              ) +
              ' ' +
              length
            }`
        }
        return true
      }

    // email validation
    Vue.config.globalProperties.$email = (value) =>
      !value ||
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ||
      i18n.global.t('globals.email')

    // more than
    Vue.config.globalProperties.$more_than = (value2) => (value) =>
      !value ||
      !value2 ||
      parseFloat(value) > parseFloat(value2) ||
      i18n.global.t('validation.more_than') + value2

    // less than
    Vue.config.globalProperties.$less_than = (value2) => (value) =>
      !value ||
      !value2 ||
      parseFloat(value) < parseFloat(value2) ||
      i18n.global.t('validation.less_than') + value2

    // less than
    
    Vue.config.globalProperties.$isTime =    
      (value) => {        
         const pattern = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/
         if(pattern.test(value))return true
         else return i18n.global.t('validation.less_than')        
      }      
    Vue.config.globalProperties.$time_min_max =    
    (min,max) => {
      if(min && max){
        const [hour1,minute1] = min.split(":")
        const [hour2,minute2] = max.split(":")
        const date1 = new Date()
        date1.setHours(parseInt(hour1,10))
        date1.setMinutes(parseInt(minute1,10))
        const date2 = new Date()
        date2.setHours(parseInt(hour2,10))
        date2.setMinutes(parseInt(minute2,10))
  
        if(min>max)
        return 'وقت النهاية اصغر من البداية'
  
        else return true
      }     
    }

    // هذا للتحقق لابد ان يتم ادخال قيمتان مع بعض
    Vue.config.globalProperties.$notOneFieldFilled=
    (field1,field2)=>{
     
      if (field1 == null && field2 == null || field1 == undefined && field2 == undefined)
        return true
        return 'لا بد ان يتم ادخال قيمه في الحقلين'

    },
    Vue.config.globalProperties.$validUniqe =
    (value) => {
      if (value) return  'موجود مسبقاََ'      
      return true
    }
  },

}

export default ValueValidationPlugin
