

<template>
  <custom-auto-complete
    :items="activitiesgroupe"
    item-title="filter_name"
    item-value="id"
    :label="$t('ActivitiesGroupsFilter.ActiGroFilt')"
    icon="account"
    col="3"
     />
</template>
<script>
export default {
 
  

  data() {
    return {
      activitiesgroupe: [],
 
    }
  },
  created() {
      this.$axios('activities/activity-filter-group-data/all/').then((e)=>{
        this.activitiesgroupe =e.data;
    })
  },
 

}
</script>