<template>
  <custom-auto-complete
    :items="list"
    item-title="name"
    item-value="id"
    :label="$t('branches.branch_type')"
    icon="office-building"
    col="3"
  />
</template>
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  async created() {
    await this.$axios("choices/choice-data/", {
      params: {
        param: "BranchTypeChoice",
      },
    })
      .then((response) => {
        this.list = response.data;
      })
      .catch(() => {});
  },
};
</script>
