<template>
  <custom-auto-complete
    :items="items"
    item-title="name"
    item-value="id"
    :label="$t('globals.marital-status')"
    icon="ring"
    :col="col"
    :rules="rules"
  />
</template>
<script>


// import { getMaritalStatus } from '@/plugins/methods/global-methods'

export default {
    props:{
    col:{
      type:Number,
      default(){
        return 3;
      }
    },
    rules:{
      type:Object,
       
    }

  },
  name: 'ListMetrailstate',
  data() {
    return {
      items: [],
    }
  },
  async created() {
    // this.items = await getMaritalStatus()
  },
}
</script>