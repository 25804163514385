<template>
  <custom-auto-complete
    :items="list"
    :multiple="multiple"
    item-title="name"
    item-value="pk"
    :label="$t('user.group')"
    icon="account-group"
    :col="col"
    :rules="rules"
  />
</template>

<script>
export default {
  props: {
    col: {
      type: Number,
      default() {
        return 3;
      },
    },
    rules: {
      type: Object,
    },
    semster: {
      type: undefined,
      default: 0,
    },
  
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      list: [],
    };
  },

  async created() {
    await this.$axios("users/groups/")
      .then((response) => {
        this.list = response.data;
      })
      .catch(() => {});
  },
};
</script>
