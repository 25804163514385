<template>
  <template v-for="(item, index) in items" :key="index">
    <v-list-item
      v-if="!item.children && item.permission"
      :prepend-icon="item.icon ? item.icon : 'mdi-minus'"
      :title="item.label"
      :to="item.path"
      link
    />
    <template v-else>
      <v-list-group
        class="border-bottom"
        :prepend-icon="item.icon ? item.icon : 'mdi-minus'"
        :fluid="false"
      >
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            :prepend-icon="item.icon ? item.icon : 'mdi-minus'"
            :title="item.label"
            v-if="item.permission == false ? false : true"
            link
          />
        </template>

      </v-list-group>
    </template>
  </template>
</template>
<script>
export default {
  name: 'ItemsSideBar',

  props: {
    items: {
      type: Array,
    },
  },
}
</script>
