<template>

  <custom-auto-complete
    clearable
    :items="items"
    item-title="name"
    item-value="id"
    :label="$t('semster_subject.subject_type')"
    icon="bookshelf"
    :rules="rules"
    density="compact"
     />
</template>
<script>
// import {getSubjects} from '@/plugins/methods/global-methods'

export default {
  props: {
    rules: {
      type: Object,
    },
  },
  name: 'SubjectTypeChoice',
  data() {
    return {
      items: [],
    }
  },
  async created() {

  await this.$axios("choices/choice-data/",{
  params: {
    param:'SubjectTypeChoice'
    
}
}).then((e)=>   this.items = e.data);
}
}
</script>