<template>
  <custom-auto-complete
    :items="countries"
    item-title="name_ar"
    item-value="id"
    :label="$t('globals.country')"
    icon="flag"
    col="3"
  />
</template>
<script>
export default {
  data() {
    return {
      countries: [],
    };
  },
  created() {
    this.$axios("common/country-data/").then((e) => {
      this.countries = e.data.results;
    });
  },
};
</script>
