<template>
  <custom-auto-complete
    :items="item"
    :placeholder="$t('List.semester_placeholder')"
    item-title="name"
    item-value="id"
    :label="$t('list.semester')"
    icon="chart-pie"
    :rules="rules"
  />
</template>
<script>
export default {
  props: {
     filter:{
      type:Array,
    },
    rules: {
      type: Object,
    },
  },
  name: 'ListSemester',
  data() {
    return {
      item: [],    
    }
  },
  async created() {
     if(!this.filter)
    await this.$axios
      .get('/choices/choice-data/', {
        params: {
          param: 'SemesterChoice',
        },
      })
      .then((e) => (this.item = e.data))
      .catch(() => {
        this.item = 'error'
      })
  },
     watch:{
      filter(){
        this.items = this.filter
      }
      }

 
}
</script>
