<template>
<!-- <pre>{{items}}</pre> -->

  <nav-drawer v-show="drawer" v-model="drawer" temporary title="طلب سحب ملف  ">
    <template v-slot:body>
      <v-form ref="form">
        <v-row class="px-3 pt-5">
          <!-- <drop-list name="student"  v-model="send_items.fk_service.fk_student" col="12" :rules="[$validateRequired]"></drop-list> -->
          <drop-list name="currency" v-model="send_items.fk_service.fk_currency" col="12" :rules="[$validateRequired]"></drop-list>
            <custom-text-field
                v-model="send_items.reason"
                icon="file-outline"
                :label="$t('Drawal.reason')"
                :rules="[$validateRequired , $validateArabicText , $max_length(100)]"
              ></custom-text-field>
              <!-- <v-checkbox
                v-model="send_items.is_fulfill_condition"
                :label="$t('Drawal.is_fulfill_condition')"
                :rules="[$validateRequired ]"
              ></v-checkbox> -->


      <fieldset class="w-100" style="border:1px solid"> 
      <legend >
          {{$t('housing.document.document_data')}}
      </legend>

          <drop-list name="documnet_type" v-model="send_items.fk_service.fk_document.document_type" col="12" :rules="[$validateRequired]"></drop-list>
              <custom-text-field
                v-model="send_items.fk_service.fk_document.issue_place"
                icon="map-marker"
                :label="$t('housing.document.issue_place')"
                :rules="[$validateRequired , $validateArabicText , $max_length(100)]"
              ></custom-text-field>
              <custom-text-field
                v-model="send_items.fk_service.fk_document.issue_date"
                :label="$t('housing.document.issue_date')"
                type="date"
                icon=""
                :rules="[$validateRequired]"
              ></custom-text-field>
           
          <custom-text-field
            v-model="send_items.fk_service.fk_document.document_no"
            :label="$t('housing.document.document_no')"
            :rules="[$required, $max_value(65535)]"
            icon="numeric"
          >
          </custom-text-field>
          <custom-file-input
            v-model="file"
            type="file"
            :label="$t('test_form.file')"
            @change="
              file ? (send_items.fk_service.fk_document.document = file) : (send_items.fk_service.fk_document.document = null)
            "
            :herf="!file?.length ? data?.file : false"
            :rules="[!is_update ? $required : true]"
          ></custom-file-input>
      </fieldset>

        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="is_update ? 'update' : 'save'"
        @click="is_update ? updateData() : saveData()"
      />
      <custom-btn @click.stop="drawer = !drawer" />
    </template>
  </nav-drawer>
  <custom-card
    expanstion="true"
    :title="$t('globals.select-criteria')"
    :head_title="$t('Drawal.filedrawalmanage')"
    head_date="27يناير"
    class="cardcolor"
  >
    <template v-slot>
      <v-row class="align-center">
          <drop-list
          v-model="send_items.fk_filter" 
          @update:modelValue="filterSearch"  
          col="12"
          name="service_status">
          </drop-list>
      </v-row> </template
  ></custom-card>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :editItem="editData2"
    :delItem="delData"
    :perm="{}"
    export
    :create="()=>resetForm()"
  > 
    <template v-slot:item="{item,key}">
        <v-span v-if="key=='service_status'" style="display:block; width:150px">
                <drop-list v-model="item[key]" @update:modelValue="editData(item)"  col="12" name="service_status"></drop-list>
        </v-span>
        <v-span v-if="key=='is_paid'" style="display:block; width:150px">
                <v-checkbox v-model="item[key]" @update:modelValue="editData(item)" ></v-checkbox>
                
        </v-span>

          <v-span v-if="key=='is_fulfill_condition'" style="display:block; width:150px">
                <v-checkbox v-model="item[key]"  @update:modelValue="editData(item)" ></v-checkbox>
                
        </v-span>
  </template>
  </custom-data-table-2>
</template>
<script>
export default {
  props: {
    perm: Object,
  },
  data() {
    return {
      url: "student-services/file-with-drawal-service/",
      items: [],
      send_items: {
      fk_specialization: undefined ,
      fk_service:{
        fk_document:{}
      }},
      pagination: undefined,
      data: {},
      is_update: false,
      drawer: false,
      file: null,
      headers: [
        { title: this.$t('housing.id'), key: 'id' },
        { title: this.$t('housing.student_name'), key: 'student_name',sortable: false },
        { title: this.$t('housing.document.expiration_date'), key: 'expiration_date',sortable: false , show: false},
        { title: this.$t('housing.document.document'), key: 'document',type:"document",sortable: false, show: false },
        { title: this.$t('housing.document.document_no'), key: 'document_no',sortable: false , show: false},
        { title: this.$t('housing.document.issue_date'), key: 'issue_date' ,sortable: false, show: false},
        { title: this.$t('housing.document.document_type'), key: 'document_type_name',sortable: false },
        { title: this.$t('housing.document.issue_place'), key: 'issue_place',sortable: false },
        { title: this.$t('housing.fk_service.request_date'), key: 'request_date' ,sortable: false, show: false},
        { title: this.$t('housing.fk_service.request_no'), key: 'request_no',sortable: false, show: false },
        { title: this.$t('housing.fk_service.service_status'), key: 'service_status' ,sortable: false },
        { title: this.$t('housing.fk_service.service_fees'), key: 'service_fees',sortable: false },
        { title: this.$t('housing.fk_service.is_paid'), key: 'is_paid',sortable: false  },
        { title: this.$t('StopRegistration.is_fulfill_condition'), key: 'is_fulfill_condition',sortable: false   },
        { title: this.$t('globals.actions'), key: 'actions' },
      ],
    }
  },
  computed: {
    updateheaders() {
      
        if (this.items) {
          
          this.headers[1].key = this.items.id
        }
        return this.headers[1].key;
      },
  },
  methods: {
    filterSearch(data) {
      if (data) {
        this.url = `student-services/file-with-drawal-service/filter-by-status/?service_status=${data}`;
        
        this.getData();
      } else {
        this.url = "student-services/file-with-drawal-service/";
        this.getData();
      }
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios(this.url, {
        params: {
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
        },
      })
        .then((response) => {
            let data  = this.url.includes('filter')?response.data:response.data.results;
            this.items=[];
            data.map((item)=>{
              this.items.push({
              ...item,
              expiration_date:item.fk_service.fk_document.expiration_date,
              service_fees:item.fk_service.service_fees,
              is_paid:item.fk_service.is_paid,
              request_no:item.fk_service.request_no,
              request_date:item.fk_service.request_date,
              service_status:item.fk_service.service_status,
              document_no:item.fk_service.fk_document.document_no,
              document:item.fk_service.fk_document.document,
              document_type_name:item.fk_service.fk_document.document_type_name,
              issue_place:item.fk_service.fk_document.issue_place,
              issue_date:item.fk_service.fk_document.issue_date,
            })
          })
          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const formData = new FormData()
        for (const [key, value] of Object.entries(this.send_items.fk_service.fk_document)) {
          if (key == 'document') {
            formData.append('file',value[0])
          } 
        }
        formData.append('data',JSON.stringify(this.send_items))
        await this.$axios
          .post('student-services/file-with-drawal-service/create-file-with-drawal-service/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.$emit('successAlert', this.$t('globals.data_added'))
            this.resetForm()
            this.getData()
          })
              .catch((error) => {
            console.log(error,"errorrr");
            if (error.response.status == 400) {
              if (error.response.data.error == "يرجى اعداد الخدمة من شاشة الاعدادات") {
                console.log("111111111");
              this.$emit(
                'warningAlert',
                this.$t('globals.error_in_settings'),
              )   
              }
              else if(error.response.data.fk_service.fk_document.non_field_errors[0] =="الحقول document_no, document_type يجب أن تشكل مجموعة فريدة."){
                this.$emit(
                'warningAlert',
                " يجب ان تكون رقم الوثيقة غير موجود بالوثائق"
              )   
              }
              
              else{

              this.$emit(
                'warningAlert',
                this.$t('globals.error_in_repeted_data'),
              )
                }
            
            } else {
              this.$emit('errorAlert', this.$t('globals.error_in_data'))
            }
          })
      }
    },
    editData2(data){
      // console.log(data,"aaaaaaaaaaaaaaaaaa");
      this.update_id = data.id
        this.drawer = true
        this.send_items = {...data}
        // this.file = data.fk_service.fk_document.document
        this.is_update = true
      

    },
    editData(data,) {
      this.update_id = data.id

        this.data.service_status = data.service_status 
        this.data.is_paid = data.is_paid 
        this.is_update = true
        this.updateData()

    },
  async updateData() {
        this.data.data_entry = this.user;
      await this.$axios
          .put(
            this.base_url + "student-services/file-with-drawal-service/update-data/?id=" + this.update_id ,
              this.data
          )
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
                    this.resetForm();
        this.drawer =!this.drawer;
        // this.getData();
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("alert.failure.title"));
          });
    },
    async delData(delete_id, isPermission = false) {
      if (!isPermission) {
        if (delete_id != undefined) {
          var status
          await this.$axios
            .delete('student-services/file-with-drawal-service/' + delete_id + '/')
            .then(() => {
              status = true
            })
            .catch((error) => {
              status = error
            })
          return status
        }
      } else {
        return true
      }
    },
    resetForm() {
      this.$refs.form.reset()
      this.data = {}
      this.send_items.fk_service.fk_document={}
      this.send_items.fk_service={}
      this.send_items.reason=undefined
      this.send_items.is_fulfill_condition=undefined
      this.send_items.fk_service.fk_document={}
      this.is_update = false
      this.drawer = this.drawer ? !this.drawer : !this.drawer
      this.file = null
    },
  },
}
</script>
<style scoped>
fieldset{
  border: 1px #5e6981 solid;
  border-radius: .3rem;
}
legend{
  padding-inline: 8px;
    margin-right: 7px;
    font-size: smaller;
  
}
</style>