<template>
  <v-col v-if="field" cols="12" :md="col" :sm="col" :class="col_class">
    <v-text-field
      v-model="date"
      :label="label"
      :placeholder="placeholder"
      :clearable="clearable"
      :rules="rules"
      @keypress="valid"
      @keyup="valid"
      @keydown="valid"
      :maxlength="maxLength"
      :density="density"
      :readonly="readOnly"
      hide-details="auto"
      :disabled="disabled"
    >
      <template v-slot:prepend-inner>
        <v-icon :id="id_click" @click="handelIconDate">{{ icon }}</v-icon>
      </template>
    </v-text-field>
  </v-col>
  <v-col v-else cols="12" :md="col" :sm="col" :class="col_class">
        <v-icon :id="id_click" @click="handelIconDate">{{ icon }}</v-icon>
  </v-col>
  <Datetime
    :type="type"
    :forma="format"
    :color="color"
    @input="(e) => getDate(e)"
    :locale="locale"
    :element="datetime_picker"
    :calendar="calendar"
    :class="theme"
  ></Datetime>
</template>

<script>
import Datetime from 'vue3-datetime-js'
import { mapState } from 'vuex'

export default {
  props: {
    input: Function,
    modelValue: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    datetime_picker: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'date',
    },
    format: {
      type: String,
      default: null,
    },
    maxLength: {
      type: Number,
      default: 10,
    },
    locale: {
      type: String,
      default: 'ar-sa',
    },
    color: {
      type: String,
      default: 'teal',
    },
    label: {
      type: String,
      default: '',
    },
    calendar: {
      type: String,
      default: 'hijri',
    },
    density: {
      type: String,
      default: 'compact',
    },
    icon: {
      type: String,
      default: 'mdi-calendar-outline',
    },
    placeholder: {
      type: String,
      default(param) {
        if (param.type == 'date') {
          return 'YYYY-MM-DD'
        } else {
          return 'HH:MM'
        }
      },
    },
    col: {
      type: String,
      default: '12',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    col_class: {
      type: String,
      default: '',
    },
      field: {
        type: Boolean,
        default: true,
      },
  },
  components: {
    Datetime,
  },
  created() {
    if (this.calendar == 'gregorg') {
      this.maxMonthDay = '31'
    }
    if (this.type == 'time') {
      this.valid = this.validateTime
    } else {
      this.valid = this.validateDate
    }
  },
  data() {
    return {
      id_click: null,
      valid: null,
      dateRule: [
        (value) =>
          !value ||
          /^(\d{4})-(\d{2})-(\d{2})$/.test(value) ||
          /^(\d{4})\/(\d{2})\/(\d{2})$/.test(value) ||
          this.errorMessage,
      ],
      maxMonthDay: '30',
    }
  },
  methods: {
    validateDate(e) {
      const len = this.date?.length
      if (e.keyCode !== 8) {
        if (len === 4 || len === 7) {
          this.date += '-'
        }
        if (e.keyCode < 47 || e.keyCode > 57) {
          e.preventDefault()
        }
        if (len === 7) {
          const month = this.date.substring(5, 7)
          if (month > 12) {
            this.date = this.date.substring(0, 5) + '12'
          }
          if (month == '00') {
            this.date = this.date.substring(0, 5) + '01'
          }
        }
        if (len === 10) {
          const day = this.date.substring(8, 10)
          if (day > 31) {
            this.date = this.date.substring(0, 8) + '31'
          }
          if (day == '00') {
            this.date = this.date.substring(0, 8) + '01'
          }
        }
      }
    },

    validateTime(e) {
      const len = this.date?.length
      if (e.keyCode == 9) {
        return
      }
      if (e.keyCode !== 8) {
        if (len == 2) {
          if (parseInt(this.date) > 23) {
            this.date = '23:'
          } else if (parseInt(this.date) == 0) {
            this.date = '01:'
          } else {
            this.date = this.date + ':'
          }
        }
        if (len == 5) {
          let min = this.date.substring(3, 5)
          if (parseInt(min) > 59) {
            this.date = this.date.substring(0, 3) + '59'
          }
        }
        if (len >= 5 || !/[0-9]/.test(e.key)) {
          e.preventDefault()
          return
        }
      }
    },
    getDate(e) {
      let date = e
      if (this.$i18n.locale == 'ar' || this.$i18n.locale == 'en') {
        date = this.toEnglishString(e)
      }
      this.date = date

      if (this.input) this.input()
    },
    handelIconDate() {
      this.id_click = this.datetime_picker
    },
    handelBlur(event) {
      this.$emit('blur', event)
    },
    handelInput(event) {
      this.$emit('input', event)
    },
  },
  computed: {
    date: {
      get() {
        return this.modelValue
      },
      set(date) {
        this.$emit('update:modelValue', date)
      },
    },
    ...mapState({
      theme: (state) => state.theme,
    }),
  },
}
</script>
<style>
.dark .vpd-content {
  background-color: #283144;
  color: inherit;
}
.dark .vpd-time {
  background-color: #283144;
  color: inherit;
}
.counter-item span {
  font-size: 30px !important;
}
.vpd-main .vpd-wrapper {
  position: fixed !important;
}
</style>
