<template>
  <v-col>
    <v-row>
      <custom-text-field
        v-model="value.student_data.full_name_ar"
        :label="$t('student_details.name')"
        icon="abjad-arabic"
        col="3"
        :rules="[$required, $ar_letters_only, $max_length(100)]"
      />

      <datetime-picker
        v-model="value.user.date_of_birth"
        :calendar="'gregorg'"
        placeholder="YYYY-MM-DD"
        :label="$t('globals.birth_date')"
        datetime_picker="date_of_birth"
        :rules="[$required]"
        col="3"
      />
    
      <custom-file-input
        v-model="value.user_fk_ssn_archive.document"
        col="3"
        :rules="[$required]"
      />
        <drop-list
        v-model="value.user_fk_ssn_archive.document_type"
        name="documnet_type"
        col="3"
        :rules="[$required]"
      />
    </v-row>
    <v-row>
      <custom-text-field
        v-model="value.user_fk_ssn_archive.document_no"
        :label="$t('document.document_no')"
        icon="numeric"
        col="3"
        :rules="[$required,$max_length(50)]"
        :error_messages="value.uniqe_doc_no_type?$t('already_exists'):''"
        
        @update:modelValue="value.uniqe_doc_no_type=false"
      />
      <custom-text-field
        v-model="value.user_fk_ssn_archive.issue_place"
        :label="$t('employee.issue_place')"
        icon="compass-outline"
        col="3"
        :rules="[$required, $max_length(100)]"
      />
        <datetime-picker
          v-model="value.user_fk_ssn_archive.issue_date"
          :calendar="'gregorg'"
          placeholder="YYYY-MM-DD"
          :label="$t('employee.issue_date')"
          datetime_picker="user_fk_ssn_archive_date_of_issue_date"
          :rules="[$required]"
          col="3"
        />
        <datetime-picker
          v-model="value.user_fk_ssn_archive.expiration_date"
          :calendar="'gregorg'"
          placeholder="YYYY-MM-DD"
          :label="$t('employee.expiration_date')"
          datetime_picker="user_fk_ssn_archive_date_of_expiration_date"
          col="3"
        />
    </v-row>
 

    <v-row>
      <drop-list
        name="country"
        v-model="value.country"
        @update:modelValue="
          ;(value.gov = undefined),
            (value.dir = undefined),
            (value.fk_region = undefined)
        "
        col="3"
        :rules="[$required]"
      />
      <drop-list
        name="gov_for_country"
        v-model="value.gov"
        :param="value.country"
        @update:modelValue="
          ;(value.dir = undefined), (value.fk_region = undefined)
        "
        col="3"
        :rules="[$required]"
      />
      <drop-list
        name="dir_for_gov"
        v-model="value.dir"
        :param="value.gov"
        col="3"
        :rules="[$required]"
        @update:modelValue="value.fk_region = undefined"
      />
      <drop-list
        name="region_by_dir"
        v-model="value.user_fk_place_of_brith.fk_region"
        :param="value.dir"
        col="3"
        :rules="[$required]"
      />
    </v-row>
    <v-row>
      <custom-text-field
        v-model="value.user_fk_place_of_brith.street"
        :label="$t('globals.street')"
        icon="traffic-light-outline"
        col="3"
        :rules="[$max_length(100)]"
      />

      <drop-list
        v-model="value.user.blood_type"
        name="blood_type"
        col="3"
        :rules="[$required]"
      />
    </v-row>
  </v-col>
</template>
<script>
export default {
  name: 'FirstWindow',
  props: {
    dropList: Object,
  },
  computed: {
    value: {
      get() {
        return this.dropList
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
