<template>
  <v-col cols="12" :md="col" >
    <v-text-field
      v-model="value"  
      :clearable="clearable"
      :label="label"
      density="compact"
      :prepend-inner-icon ="'mdi-' + icon"
      :counter="counter"
      :placeholder="placeholder"
      :rules="rules"
      :type="type"
      :maxLength="maxLength"
      :readonly="readonly"
      :disabled="disabled"
      hide-details="auto"
      :error-messages="error_messages"
    />
  </v-col>
</template>

<script>
export default {
  name: 'CustomAutoComplete',
  props: {
    modelValue: {
      type: [Number, String],
      default: null,
    },
    
    clearable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error_messages: {
      type: String,
      default: '',
    },
    col: {
      type: Number,
      default: 12,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
   
    icon: {
      type: String,
      default: 'home',
    },
  
      rules:{
      type:Object,
       
    },
      counter:{
      type:Number,
       
    },
      maxLength:{
      type:Number,
       
    },
      type:{
      type:String,
      
       
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    updateDate(newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },
}
</script>
